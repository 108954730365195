import React, { useEffect, useState } from "react";
import API from "../components/api2";
import InputForm from "../components/InputForm";
import { addBusinessScheme, getBusinessScheme, allotedServices } from "../urls";
import Button from "../components/Button";
import { circles } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

const NewBusinessPlan = () => {
  const [pageData, setPageData] = useState({
    rd_commission: "",
    ld_commission: "",
    ad_commission: "",
    ag_commission: "",
    brand_commission: "",
    errors: {},
  });

  const [availServicesList, setAvailServicesList] = useState([]);
  const [assignedSchemes, setAssignedSchemes] = useState([]);
  const [searchTermAvailable, setSearchTermAvailable] = useState("");
  const [searchTermAssigned, setSearchTermAssigned] = useState("");
  const [selectedScheme, setSelectedScheme] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { employee } = location.state || {};

  // get services based on service type
  useEffect(() => {
    const getBusinessPlan = () => {
      const params = {
        service_type: pageData.service_type,
      };

      API({
        ...getBusinessScheme,
        params: params,
        onSuccess: (res) => {
          setAvailServicesList(res.data.data);
        },
        onError: (error) => {
          console.log("error");
        },
      });
    };

    getBusinessPlan();
    setAssignedSchemes([]);
  }, [pageData.service_type]);

  // get alloted services for a scheme id
  const getAllotedServices = () => {
    API({
      ...allotedServices,
      params: {},
      onSuccess: (res) => {},

      onError: (error) => {
        console.log("No data available");
      },
    });
  };
  useEffect(() => {
    getAllotedServices();
  }, []);

  // page data func
  function pageDataFunc(event) {
    const { id, value } = event.target;
    // const regex = /^(?:\d{1,2}(?:\.\d{0,2})?)?$/;

    // if (
    //   id === "rd_commission" ||
    //   id === "ld_commission" ||
    //   id === "ad_commission" ||
    //   id === "ag_commission"
    // ) {
    //   if (
    //     regex.test(value) &&
    //     parseFloat(value) <= 99.99 &&
    //     parseFloat(value) >= 0
    //   ) {
    //     setPageData((prev) => ({
    //       ...prev,
    //       [id]: value,
    //       errors: { ...prev.errors, [id]: "" }, // Clear error
    //     }));
    //   } else if (value === "") {
    //     setPageData((prev) => ({
    //       ...prev,
    //       [id]: "",
    //       errors: { ...prev.errors, [id]: "" }, // Clear error
    //     }));
    //   } else {
    //     setPageData((prev) => ({
    //       ...prev,
    //       errors: { ...prev.errors, [id]: "Invalid input, must be 0-99.99" }, // Set error
    //     }));
    //   }
    // }
    //  else {
    setPageData((prev) => ({ ...prev, [id]: value }));
    // }
  }

  // on submit
  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const { service_type, errors, ...allData } = pageData;
    const assignedServiceIds = assignedSchemes.map((s) => s.service_id);
    API({
      ...addBusinessScheme,
      body: { ...allData, api_service_id: assignedServiceIds },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          setTimeout(() => {
            changePageFunc();
          }, 1000);
        });
      },

      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allBusinessScheme",
      })
    );

    navigate("/");
  };

  // sending scheme from avail to assign and vice versa
  const handleAssignScheme = (scheme) => {
    const schemeExists = assignedSchemes.some(
      (assignedScheme) =>
        assignedScheme.utility_service_name === scheme.utility_service_name
    );

    if (!schemeExists) {
      // Remove the scheme from available schemes
      const updatedAvailableSchemes = availServicesList.filter(
        (s) => s.utility_service_name !== scheme.utility_service_name
      );
      // Add the scheme to assigned schemes
      const updatedAssignedSchemes = [...assignedSchemes, scheme];
      setAvailServicesList(updatedAvailableSchemes);
      setAssignedSchemes(updatedAssignedSchemes);
      setSelectedScheme(scheme);
    } else {
      // Clear the selected scheme
      setSelectedScheme(null);

      // Check if the scheme is already present in available schemes
      const schemeAlreadyAvailable = availServicesList.some(
        (availableScheme) =>
          availableScheme.utility_service_name === scheme.utility_service_name
      );

      if (!schemeAlreadyAvailable) {
        // Add the scheme back to available schemes only if it's not already there
        const updatedAvailableSchemes = [...availServicesList, scheme];
        setAvailServicesList(updatedAvailableSchemes);
      }

      // Remove the scheme from assigned schemes
      const updatedAssignedSchemes = assignedSchemes.filter(
        (assignedScheme) =>
          assignedScheme.utility_service_name !== scheme.utility_service_name
      );
      setAssignedSchemes(updatedAssignedSchemes);
      setSelectedScheme(scheme);
    }
  };

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto  py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-4 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Create Business Scheme
        </h2>

        <div className="px-5 container-fluid">
          <div className="row mt-3">
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="service_type "
                className="form-label"
              >
                Service Provider
              </label>
              <Select
                placeholder="Select Service Type"
                id="service_type"
                name="service_type"
                valueState={pageData["service_type"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    service_type: selectedOption.value,
                  })
                }
                options={[
                  { value: "API", label: "API" },
                  { value: "PORTAL", label: "PORTAL" },
                ]}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "none",
                    "&:focus": {
                      outline: "none",
                    },
                  }),
                }}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Scheme Name"}
                id={"scheme_name"}
                name="scheme_name"
                valueState={pageData}
                onChange={pageDataFunc}
              />
            </div>
          </div>
          <div className="mt-4">
            <InputForm
              type={"text"}
              placeholder={"Scheme Description"}
              id={"scheme_description"}
              name="scheme_description"
              valueState={pageData}
              onChange={pageDataFunc}
            />
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                  marginTop: "16px",
                }}
              >
                Super Distributor Commission
              </p>
              <input
                type="number"
                name="rd_commission"
                id="rd_commission"
                className="p-2 w-100 mt-1"
                value={pageData.rd_commission || ""}
                onChange={pageDataFunc}
                onBlur={pageDataFunc}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
              {pageData.errors.rd_commission && (
                <p
                  className="mb-0 position-absolute"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {pageData.errors.rd_commission}
                </p>
              )}
            </div>
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                  marginTop: "16px",
                }}
              >
                Master Distributor Commission
              </p>
              <input
                type="number"
                name="ad_commission"
                id="ad_commission"
                className="p-2 w-100 mt-1"
                value={pageData.ad_commission || ""}
                onChange={pageDataFunc}
                onBlur={pageDataFunc}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
              {pageData.errors.ad_commission && (
                <p
                  className="mb-0 position-absolute"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {pageData.errors.ad_commission}
                </p>
              )}
            </div>
          </div>
          <div className="row my-2">
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                  marginTop: "16px",
                }}
              >
                Distributor Commission
              </p>
              <input
                type="number"
                name="ld_commission"
                id="ld_commission"
                className="p-2 w-100 mt-1"
                value={pageData.ld_commission || ""}
                onChange={pageDataFunc}
                onBlur={pageDataFunc}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
              {pageData.errors.ld_commission && (
                <p
                  className="mb-0 position-absolute"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {pageData.errors.ld_commission}
                </p>
              )}
            </div>
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                  marginTop: "16px",
                }}
              >
                Retailer Commission
              </p>
              <input
                type="number"
                name="ag_commission"
                id="ag_commission"
                className="p-2 w-100 mt-1"
                value={pageData.ag_commission || ""}
                onChange={pageDataFunc}
                onBlur={pageDataFunc}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />
              {pageData.errors.ag_commission && (
                <p
                  className="mb-0 position-absolute"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {pageData.errors.ag_commission}
                </p>
              )}
            </div>
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                  marginTop: "16px",
                }}
              >
                Brand Commission
              </p>
              <input
                type="number"
                name="brand_commission"
                id="brand_commission"
                className="p-2 w-100 mt-1"
                value={pageData.brand_commission || ""}
                onChange={pageDataFunc}
                onBlur={pageDataFunc}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
              />{" "}
              {pageData.errors.brand_commission && (
                <p
                  className="mb-0 position-absolute"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  {pageData.errors.brand_commission}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* assign activity  */}
        <div className="px-3">
          <div
            style={{ backgroundColor: "#D9D9D9", borderRadius: "10px" }}
            className="d-flex py-1 px-3 mt-3 align-items-center justify-content-between mt-4"
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
              className="mb-0 "
            >
              Assigned Activity
            </p>
            <div className="px-3 py-1"></div>
          </div>
          <div className="row d-flex justify-content-between mx-2 mt-4">
            <div className="col-5 h-100">
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(25, 66, 128, 1)",
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    className="mb-0 mt-1"
                  >
                    Available Services
                  </p>
                </div>
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(255, 246, 216, 1)",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <input
                    type="text"
                    value={searchTermAvailable}
                    onChange={(e) => setSearchTermAvailable(e.target.value)}
                    placeholder="Search Available Services"
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(255, 246, 216, 1)",
                    }}
                    className="text-center"
                  />
                </div>
                <div
                  style={{ width: "100%", overflowY: "auto", height: "225px" }}
                >
                  {(() => {
                    const filteredSchemes = availServicesList
                      .filter((scheme) => {
                        return !assignedSchemes.some(
                          (assignedScheme) =>
                            assignedScheme.utility_service_name ===
                            scheme.utility_service_name
                        );
                      })
                      .filter((scheme) => {
                        const searchTermWords = searchTermAvailable
                          .toLowerCase()
                          .split(" ");
                        return searchTermWords.every((word) =>
                          scheme.utility_service_name
                            .toLowerCase()
                            .includes(word)
                        );
                      });

                    if (filteredSchemes.length === 0) {
                      return (
                        <div className="my-3 px-3" style={{ color: "red" }}>
                          No available services
                        </div>
                      );
                    }

                    return filteredSchemes.map((scheme, index) => (
                      <div
                        key={index}
                        onClick={() => handleAssignScheme(scheme)}
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          borderBottom:
                            index !== filteredSchemes.length - 1
                              ? "1px solid #D9D9D9"
                              : "none",
                        }}
                      >
                        <p
                          className="mb-0 mt-2"
                          style={{
                            padding: "4px 8px",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                            color: "rgba(105, 102, 102, 1)",
                          }}
                        >
                          {scheme.utility_service_name
                            .split("_")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </p>
                      </div>
                    ));
                  })()}
                </div>
              </div>
            </div>
            <div className="col-5 h-100">
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(25, 66, 128, 1)",
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    className="mb-0 mt-1"
                  >
                    Assigned Services
                  </p>
                </div>
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(255, 246, 216, 1)",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <input
                    type="text"
                    value={searchTermAssigned}
                    onChange={(e) => setSearchTermAssigned(e.target.value)}
                    placeholder="Search Assigned Services"
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(255, 246, 216, 1)",
                    }}
                    className="text-center"
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    overflowY: "scroll",
                    height: "225px",
                  }}
                >
                  <div>
                    {assignedSchemes.length > 0 ? (
                      assignedSchemes
                        .filter((scheme) => {
                          const searchTermWords = searchTermAssigned
                            .toLowerCase()
                            .split(" ");
                          return searchTermWords.every((word) =>
                            scheme.utility_service_name
                              .toLowerCase()
                              .includes(word)
                          );
                        })
                        .map((scheme, index) => (
                          <div
                            key={index}
                            onClick={() => handleAssignScheme(scheme)}
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              borderBottom:
                                index !== assignedSchemes.length - 1
                                  ? "1px solid #D9D9D9"
                                  : "none",
                            }}
                          >
                            <p
                              className="mb-0 mt-2"
                              style={{
                                padding: "4px 8px",
                                fontFamily: "Montserrat",
                                fontWeight: "500",
                                fontSize: "13px",
                                color: "rgba(105, 102, 102, 1)",
                              }}
                            >
                              {scheme.utility_service_name
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </p>
                          </div>
                        ))
                    ) : (
                      <div className="my-3 px-3" style={{ color: "red" }}>
                        No assigned services
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-5 px-5">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button
            style={{
              opacity: loading ? 0.6 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewBusinessPlan;

import React, { useEffect, useState } from "react";
import API from "../components/api2";
import {
  merchantDedup,
  merchantOtp,
  merchantVerifyOtp,
  merchantMailVerify,
  merchantVerifyOtpMail,
} from "../urls";
import Button from "../components/Button";
import Header from "../components/Header";
import { circles } from "../images";
import InputForm from "../components/InputForm";
import NewMerchantForm from "./NewMerchantForm";
import Swal from "sweetalert2";

const NewMerchant = () => {
  const [pageData, setPageData] = useState({});
  const [message, setMessage] = useState(null);
  const [isInputFilled, setIsInputFilled] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [reqId, setReqId] = useState("");
  const [reqIdMail, setReqIdMail] = useState("");
  const [resendTimer, setResendTimer] = useState(45);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showCurrentComponent, setShowCurrentComponent] = useState(true);
  const [savedContactNo, setSavedContactNo] = useState("");
  const [validMail, setValidMail] = useState(true);
  const [validContact, setValidContact] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsInputFilled(
      (!!pageData.contact_no && pageData.contact_no.length === 10) ||
        (!!pageData.contact_email && pageData.contact_email.length > 0)
    );
  }, [pageData.contact_no, pageData.contact_email]);

  // useEffect for both contact_no and contact_email
  useEffect(() => {
    const validateContact = () => {
      setValidContact(true);
      if (pageData.contact_no) {
        API({
          ...merchantDedup,
          body: {
            contact_no: pageData.contact_no,
          },
          onSuccess: (res) => {
            setMessage({ text: res.data.message, color: "green" });
            setValidContact(false);
          },
          onError: (error) => {
            setValidContact(true);
            setMessage({ text: error.response.data.message, color: "red" });
          },
        });
      } else if (pageData.contact_email) {
        setValidMail(true);
        API({
          ...merchantDedup,
          body: {
            contact_email: pageData.contact_email,
          },
          onSuccess: (res) => {
            setMessage({ text: res.data.message, color: "green" });
            setValidMail(false);
          },
          onError: (error) => {
            setValidMail(true);
            setMessage({ text: error.response.data.message, color: "red" });
          },
        });
      }
    };

    if (isInputFilled) {
      validateContact();
    }
  }, [isInputFilled, pageData.contact_no, pageData.contact_email]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "contact_no" && value.length > 10) {
      return;
    }

    setPageData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "contact_no" ? { contact_email: "" } : {}),
      ...(name === "contact_email" ? { contact_no: "" } : {}),
    }));

    if (name === "contact_no") {
      setSavedContactNo(value);
    }
  };

  // send otp
  const sendOtp = () => {
    setLoading(true);
    API({
      ...merchantOtp,
      body: { contact_no: pageData.contact_no },
      onSuccess: (res) => {
        setLoading(false);
        setReqId(res.data.data.request_id);
        setShowOTPInput(true);
      },
      onError: (error) => {
        setLoading(false);
        console.log("Error");
        setIsResendDisabled(false);
      },
    });
  };

  // send email otp
  const sendEmailOtp = () => {
    setLoading(true);
    API({
      ...merchantMailVerify,
      body: { contact_email: pageData.contact_email },
      onSuccess: (res) => {
        setLoading(false);
        setReqIdMail(res.data.data.request_id);
        setShowOTPInput(true);
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        setIsResendDisabled(false);
      },
    });
  };

  // handle OTP change
  const handleOTPChange = (index, value) => {
    value = value.replace(/[^0-9]/g, "");

    if (value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    if (!value && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }

    const updatedOTP = [...otp];
    updatedOTP[index] = value.charAt(0);
    setOTP(updatedOTP);
  };

  // verify otp
  const verifyOTP = () => {
    setLoading(true);
    const enteredOTP = otp.join("");
    const savedContactNo = pageData.contact_no;
    API({
      ...merchantVerifyOtp,
      body: { otp: enteredOTP, request_id: reqId },
      onSuccess: (res) => {
        setLoading(false);
        setCurrentStep(2);
        setOTP(["", "", "", "", "", ""]);
        setReqId("");
        setShowOTPInput(false);
        clearMessage();
        setPageData({
          ...pageData,
          contact_no: savedContactNo,
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  // verify otp for email
  const verifyOTPMail = () => {
    setLoading(true);
    const enteredOTP = otp.join("");
    API({
      ...merchantVerifyOtpMail,
      body: { otp: enteredOTP, request_id: reqIdMail },
      onSuccess: (res) => {
        setLoading(false);
        setShowCurrentComponent(false);
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  // resend otp
  const resendOTP = () => {
    setIsResendDisabled(true);
    if (pageData.contact_no) {
      sendOtp();
    } else if (pageData.contact_email) {
      sendEmailOtp();
    }
    setResendTimer(45);
  };

  useEffect(() => {
    let timerInterval;

    if (isResendDisabled) {
      timerInterval = setInterval(() => {
        setResendTimer((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timerInterval);
            setIsResendDisabled(false);
            return 45;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isResendDisabled]);

  // Component for displaying messages
  const MessageComponent = ({ text, color }) => {
    return <p style={{ color }}>{text}</p>;
  };

  // Clear all inputs and messages
  const clearMessage = () => {
    setMessage(null);
    setPageData({});
    setShowOTPInput(false);
    setOTP(["", "", "", "", "", ""]);
    setReqId("");
    setResendTimer(45);
  };

  // Handle back button click
  const handleBackButtonClick = () => {
    setCurrentStep(1);
    setShowOTPInput(false);
    setOTP(["", "", "", "", "", ""]);
    setReqId("");
    setMessage(null);
    setPageData({});
  };

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-5 py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="mb-0"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
          }}
        >
          Create Merchant
        </h2>
        {showCurrentComponent && (
          <>
            {currentStep === 1 && (
              <div className="col-5 mx-auto mt-5 pt-1">
                <InputForm
                  type={"number"}
                  placeholder={"Enter your mobile number"}
                  id={"contact_no"}
                  name="contact_no"
                  value={pageData.contact_no}
                  onChange={handleInputChange}
                  disabled={showOTPInput}
                />
                <div className="text-end">
                  {message && (
                    <MessageComponent
                      text={message.text}
                      color={message.color}
                    />
                  )}
                </div>
                <div className="mt-3 text-center">
                  {!showOTPInput ? (
                    <>
                      <button
                        className="text-center p-2"
                        style={{
                          backgroundColor: "#194280",
                          color: "white",
                          borderRadius: "6px",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          border: "none",
                          width: "70%",
                          opacity: validContact || loading ? "0.5" : "1",
                          cursor:
                            validContact || loading ? "not-allowed" : "pointer",
                        }}
                        onClick={sendOtp}
                        disabled={validContact || loading}
                      >
                        {loading ? "Loading..." : "Continue"}
                      </button>
                      <p
                        className="mb-0 mt-1"
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "rgba(105, 102, 102, 1)",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={clearMessage}
                      >
                        cancel
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        className="mb-2 mt-5"
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                        }}
                      >
                        Please enter the OTP sent to <br />
                        <span style={{ fontWeight: "600" }}>
                          {pageData.contact_no}
                        </span>
                      </p>
                      <div className="otp-container mb-3">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            id={`otp-${index}`}
                            type="number"
                            pattern="[0-9]*"
                            maxLength="1"
                            value={digit}
                            style={{
                              margin: "0 5px",
                              textAlign: "center",
                              fontSize: "20px",
                              outline: "none",
                              width: "40px",
                              height: "40px",
                              backgroundColor: "rgba(255, 231, 159, 0.4)",
                              border: "1px solid rgba(79, 87, 91, 1)",
                              borderRadius: "10px",
                            }}
                            onChange={(e) =>
                              handleOTPChange(index, e.target.value)
                            }
                          />
                        ))}
                      </div>
                      <p
                        className="my-2"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#696666",
                          cursor: isResendDisabled ? "not-allowed" : "pointer",
                        }}
                        onClick={isResendDisabled ? null : resendOTP}
                      >
                        Didn't receive the code?{" "}
                        <span
                          style={{
                            color: isResendDisabled ? "#696666" : "#16407F",
                            fontSize: "14px",
                          }}
                        >
                          Resend
                        </span>{" "}
                        <span style={{ color: "#696666", fontSize: "14px" }}>
                          ({resendTimer}s)
                        </span>
                      </p>
                      <Button
                        style={{
                          width: "70%",
                          opacity: loading ? "0.5" : "1",
                          cursor: loading ? "not-allowed" : "pointer",
                        }}
                        onClick={verifyOTP}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Confirm"}
                      </Button>
                      <p
                        className="mb-0 mt-2"
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#696666",
                          cursor: "pointer",
                        }}
                        onClick={clearMessage}
                      >
                        If you want to change register mobile number.
                        <span style={{ color: "#16407F", fontSize: "16px" }}>
                          {" "}
                          click
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <>
                <div className="col-5 mx-auto mt-5 pt-1">
                  <InputForm
                    type={"email"}
                    placeholder={"Enter your email-id"}
                    id={"contact_email"}
                    name="contact_email"
                    value={pageData.contact_email}
                    onChange={handleInputChange}
                    disabled={showOTPInput}
                  />
                  <div className="text-end">
                    {message && (
                      <MessageComponent
                        text={message.text}
                        color={message.color}
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center">
                    {!showOTPInput ? (
                      <>
                        <button
                          className="text-center p-2"
                          style={{
                            backgroundColor: "#194280",
                            color: "white",
                            borderRadius: "6px",
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            border: "none",
                            width: "70%",
                            opacity: validMail || loading ? "0.5" : "1",
                            cursor:
                              validMail || loading ? "not-allowed" : "pointer",
                          }}
                          onClick={sendEmailOtp}
                          disabled={validMail || loading}
                        >
                          {loading ? "Loading..." : "Continue"}
                        </button>
                        <p
                          className="mb-0 mt-1"
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "15px",
                            color: "rgba(105, 102, 102, 1)",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={clearMessage}
                        >
                          cancel
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          className="mb-2 mt-3"
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                          }}
                        >
                          Please enter the OTP sent to <br />
                          <span style={{ fontWeight: "600" }}>
                            {pageData.contact_email}
                          </span>
                        </p>
                        <div className="otp-container mb-3">
                          {otp.map((digit, index) => (
                            <input
                              key={index}
                              id={`otp-${index}`}
                              type="number"
                              pattern="[0-9]*"
                              maxLength="1"
                              value={digit}
                              style={{
                                margin: "0 5px",
                                textAlign: "center",
                                fontSize: "20px",
                                outline: "none",
                                width: "40px",
                                height: "40px",
                                backgroundColor: "rgba(255, 231, 159, 0.4)",
                                border: "1px solid rgba(79, 87, 91, 1)",
                                borderRadius: "10px",
                              }}
                              onChange={(e) =>
                                handleOTPChange(index, e.target.value)
                              }
                            />
                          ))}
                        </div>
                        <p
                          className="my-2"
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "13px",
                            fontWeight: "500",
                            color: "#696666",
                            cursor: isResendDisabled
                              ? "not-allowed"
                              : "pointer",
                          }}
                          onClick={isResendDisabled ? null : resendOTP}
                        >
                          Didn't receive the code?{" "}
                          <span
                            style={{
                              color: isResendDisabled ? "#696666" : "#16407F",
                              fontSize: "14px",
                            }}
                          >
                            Resend
                          </span>{" "}
                          <span style={{ color: "#696666", fontSize: "14px" }}>
                            ({resendTimer}s)
                          </span>
                        </p>
                        <Button
                          style={{
                            width: "70%",
                            opacity: loading ? "0.5" : "1",
                            cursor: loading ? "not-allowed" : "pointer",
                          }}
                          onClick={verifyOTPMail}
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Confirm"}
                        </Button>

                        <p
                          className="mb-0 mt-2"
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "13px",
                            fontWeight: "500",
                            color: "#696666",
                            cursor: "pointer",
                          }}
                          onClick={clearMessage}
                        >
                          If you want to change register email ID.
                          <span style={{ color: "#16407F", fontSize: "16px" }}>
                            {" "}
                            click
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <Button
                  onClick={handleBackButtonClick}
                  children={"Back"}
                ></Button>
              </>
            )}
          </>
        )}
        {!showCurrentComponent && (
          <NewMerchantForm
            contact_no={savedContactNo}
            contact_email={pageData.contact_email}
          />
        )}
      </div>
    </>
  );
};

export default NewMerchant;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../components/api2";
import Button from "../components/Button";
import { getMenusDropdown, addRole } from "../urls";
import Header from "../components/Header";
import { circles } from "../images";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import InputForm from "../components/InputForm";
import Swal from "sweetalert2";

const AddRole = () => {
  const [availMenus, setAvailMenus] = useState([]);
  const [assignMenus, setAssignMenus] = useState([]);
  const [searchTermAvailable, setSearchTermAvailable] = useState("");
  const [searchTermAssigned, setSearchTermAssigned] = useState("");
  const [roleName, setRoleName] = useState("");
  const [isSystemDefined, setIsSystemDefined] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   assign menus
  useEffect(() => {
    fetchAvailMenus();
  }, []);

  const fetchAvailMenus = () => {
    API({
      ...getMenusDropdown,
      onSuccess: (res) => {
        setAvailMenus(res.data.data);
      },
      onError: (error) => {
        console.log("No menus available");
      },
    });
  };

  const handleMenuClick = (menu) => {
    const isAssigned = assignMenus.some(
      (assignedMenu) => assignedMenu.menu_id === menu.menu_id
    );

    if (isAssigned) {
      const updatedAssignedMenus = assignMenus.filter(
        (assignedMenu) => assignedMenu.menu_id !== menu.menu_id
      );
      setAssignMenus(updatedAssignedMenus);

      const updatedAvailMenus = [...availMenus, menu];
      setAvailMenus(updatedAvailMenus);
    } else {
      const updatedAvailMenus = availMenus.filter(
        (availMenu) => availMenu.menu_id !== menu.menu_id
      );
      setAvailMenus(updatedAvailMenus);

      const updatedAssignMenus = [...assignMenus, menu];
      setAssignMenus(updatedAssignMenus);
    }
  };

  const filteredAvailMenus = availMenus.filter((menu) => {
    const menuNameMatches = menu.menu_name
      .toLowerCase()
      .includes(searchTermAvailable.toLowerCase());
    const menuIdMatches = menu.menu_id
      .toString()
      .toLowerCase()
      .includes(searchTermAvailable.toLowerCase());

    return menuNameMatches || menuIdMatches;
  });

  const filteredAssignedMenus = assignMenus.filter((menu) => {
    const menuNameMatches = menu.menu_name
      .toLowerCase()
      .includes(searchTermAssigned.toLowerCase());
    const menuIdMatches = menu.menu_id
      .toString()
      .toLowerCase()
      .includes(searchTermAssigned.toLowerCase());

    return menuNameMatches || menuIdMatches;
  });

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "manageRoles",
      })
    );

    navigate("/");
  };

  //   add role
  const handleCheckboxChange = (event) => {
    setIsSystemDefined(event.target.checked);
  };

  function addNewRole(e) {
    e.preventDefault();
    setLoading(true);
    const issystem_defined = isSystemDefined ? 1 : 0;
    const assignedMenuIds = assignMenus.map((menu) => menu.menu_id);

    API({
      ...addRole,
      body: { role_name: roleName, issystem_defined, menu: assignedMenuIds },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          changePageFunc();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px" }}
        className="bg-white mt-2 me-2 py-4 position-relative overflow-y-scroll container-fluid h-100"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div
          style={{ borderBottom: "1px solid rgba(217, 217, 217, 1)" }}
          className="mx-4 pb-3"
        >
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Add Role
          </h2>
        </div>

        {/* Add role  */}
        <div className="row align-items-center mx-3 mt-2">
          <div className="col-6">
            <InputForm
              type={"text"}
              id={"role_name"}
              name="role_name"
              placeholder={"Add Role"}
              value={roleName}
              onChange={(event) => setRoleName(event.target.value)}
            />
          </div>
          <div className="col-6 mt-5">
            <input
              type="checkbox"
              id="issystem_defined"
              name="issystem_defined"
              checked={isSystemDefined}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="issystem_defined"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(105, 102, 102, 1)",
                fontFamily: "Montserrat",
              }}
            >
              System Defined
            </label>
          </div>
        </div>

        {/* Assign menus  */}
        {!isSystemDefined && (
          <div className="row d-flex justify-content-between mx-2 mt-5">
            <div className="col-5 h-100">
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(25, 66, 128, 1)",
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    className="mb-0 mt-1"
                  >
                    Available Menus
                  </p>
                </div>
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(255, 246, 216, 1)",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <input
                    type="text"
                    value={searchTermAvailable}
                    onChange={(e) => setSearchTermAvailable(e.target.value)}
                    placeholder="Search Available Menus"
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(255, 246, 216, 1)",
                    }}
                    className="text-center"
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ overflowY: "auto", height: "300px" }}>
                    {filteredAvailMenus
                      .filter(
                        (menu) =>
                          !assignMenus.find(
                            (assignedMenu) =>
                              assignedMenu.menu_id === menu.menu_id
                          )
                      )
                      .map((menu, index) => (
                        <div
                          key={index}
                          onClick={() => handleMenuClick(menu)}
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            borderBottom:
                              index !== filteredAvailMenus.length - 1
                                ? "1px solid #D9D9D9"
                                : "none",
                          }}
                        >
                          <p
                            className="mb-0 mt-2"
                            style={{
                              padding: "4px 8px",
                              fontFamily: "Montserrat",
                              fontWeight: "500",
                              fontSize: "13px",
                              color: "rgba(105, 102, 102, 1)",
                            }}
                          >
                            {menu.menu_name
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </p>
                        </div>
                      ))}
                    {filteredAvailMenus.filter(
                      (menu) =>
                        !assignMenus.find(
                          (assignedMenu) =>
                            assignedMenu.menu_id === menu.menu_id
                        )
                    ).length === 0 && (
                      <div className="my-3 px-3" style={{ color: "red" }}>
                        No available menus
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5 h-100">
              <div
                style={{
                  border: "1px solid #D9D9D9",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      color: "rgba(25, 66, 128, 1)",
                      fontWeight: "500",
                      fontSize: "13px",
                    }}
                    className="mb-0 mt-1"
                  >
                    Assigned Menus
                  </p>
                </div>
                <div
                  className="text-center py-1"
                  style={{
                    backgroundColor: "rgba(255, 246, 216, 1)",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <input
                    type="text"
                    value={searchTermAssigned}
                    onChange={(e) => setSearchTermAssigned(e.target.value)}
                    placeholder="Search Assigned Menus"
                    style={{
                      width: "100%",
                      backgroundColor: "rgba(255, 246, 216, 1)",
                    }}
                    className="text-center"
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ overflowY: "auto", height: "300px" }}>
                    {filteredAssignedMenus.map((menu, index) => (
                      <div
                        key={index}
                        onClick={() => handleMenuClick(menu)}
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          borderBottom:
                            index !== filteredAssignedMenus.length - 1
                              ? "1px solid #D9D9D9"
                              : "none",
                        }}
                      >
                        <p
                          className="mb-0 mt-2"
                          style={{
                            padding: "4px 8px",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                            color: "rgba(105, 102, 102, 1)",
                          }}
                        >
                          {menu.menu_name
                            .split("_")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </p>
                      </div>
                    ))}
                    {filteredAssignedMenus.length === 0 && (
                      <div className="my-3 px-3" style={{ color: "red" }}>
                        No assigned menus
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between align-itmes-center mt-5 mx-3">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button
            onClick={addNewRole}
            disabled={loading || !roleName}
            style={{
              opacity: loading || !roleName ? 0.6 : 1,
              pointerEvents: loading || !roleName ? "none" : "auto",
            }}
          >
            {loading ? "Loading..." : "Add"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddRole;

import React, { useState } from "react";
import API from "../components/api2";
import Swal from "sweetalert2";
import { createCategory } from "../urls";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changePage } from "../redux/slice/dashboardSlice";
import Header from "../components/Header";

const NewUserManagement = () => {
  const { state } = useLocation();

  const [values, setValues] = useState(
    state?.data || {
      type: "",
      category: "",
      sub_category: "",
    }
  );
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (/^([^0-9]*)$/.test(value) && value?.length <= 50) {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSubmitDisabled =
    !values.type || !values.category || !values.sub_category;

  // Handle Add User Group
  const handlePostData = () => {
    setLoading(true);
    API({
      ...createCategory,
      body: values,
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          changePageFunc();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allTcManagement",
      })
    );

    navigate("/");
  };
  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px" }}
        className="bg-white mt-2 me-2 py-4 position-relative overflow-y-scroll container-fluid h-100"
      >
        <div
          style={{ borderBottom: "1px solid rgba(217, 217, 217, 1)" }}
          className="pb-3"
        >
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Add New Type & Category
          </h2>
        </div>

        {/* group detail  */}
        <div
          className="py-2 px-3 mt-4"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "fit-content",
            marginLeft: "-12px",
          }}
        >
          Type & Category Details
        </div>

        <Form className="mt-3 row row-cols-auto gy-4 container-sm  mx-auto">
          {[
            {
              name: "type",
              type: "text",
              label: "Type",
              placeholder: "Add Type Here",
            },
            {
              name: "category",
              type: "text",
              label: "Category",
              placeholder: "Add Category Here",
            },
            {
              name: "sub_category",
              type: "text",
              label: "Sub-Category",
              placeholder: "Add Sub-Category Here",
            },
          ].map(({ name, type, placeholder, label }, ind) => (
            <Form.Group
              className=" col-5"
              key={"name"}
              controlId={`${name}${ind}`}
            >
              <Form.Label className="fw-bold mb-2">
                {<span style={{ color: "#A19494" }}>{label}</span>}
              </Form.Label>
              <Form.Control
                readOnly={!!state?.data}
                size="md"
                style={{
                  background: "#FFF6D8",
                  border: "2px solid black!important",
                  maxWidth: "308px",
                }}
                onChange={handleChange}
                type={type}
                name={name}
                value={values?.[name]}
                placeholder={placeholder}
              />
            </Form.Group>
          ))}
        </Form>
        <br />
        {/*====================================|| Audit Info=============================  */}
        {!!state?.data && (
          <section
            className="p-3 my-3 "
            style={{
              borderTop: "1px solid #AD8352",
              borderBottom: "1px solid #AD8352",
            }}
          >
            <div className=" mt-3 mx-auto container-sm">
              <h5 className="text-muted">Audit Info</h5>
              <ul
                className=" justify-content-between d-flex text-muted"
                style={{ listStyleType: "none", padding: "3px 0px" }}
              >
                {[
                  {
                    label: "Created By",
                    data: state?.data?.created_by || "N/A",
                  },
                  {
                    label: "Created On",
                    data: state?.data?.created_on || "N/A",
                  },
                  {
                    label: "Modified By",
                    data: state?.data?.modified_by || "N/A",
                  },
                  {
                    label: "Modified On",
                    data: state?.data?.modified_on || "N/A",
                  },
                ].map(({ label, data }, ind) => (
                  <li key={ind}>
                    {label} : <span>{data}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}

        <div className="d-flex justify-content-between  container-sm mt-5">
          <button
            className="px-2 py-1"
            style={{
              border: "1px solid #3D366A",
              color: "#3D366A",
              borderRadius: "6px",
              boxShadow: "rgba(0, 0, 0, 0.25)",
              fontFamily: "League Spartan",
              width: "100px",
            }}
            onClick={changePageFunc}
          >
            {" "}
            Back
          </button>{" "}
          {!state && (
            <button
              children={loading ? "Loading..." : "Submit"}
              onClick={handlePostData}
              style={{
                backgroundColor: "rgba(22, 64, 127, 1)",
                borderRadius: "10px",
                cursor: isSubmitDisabled || loading ? "not-allowed" : "pointer",
                opacity: isSubmitDisabled || loading ? "0.5" : "1",
                width: "100px",
                color: "white",
              }}
              className="px-3 py-2 border-0"
              disabled={isSubmitDisabled || loading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default NewUserManagement;

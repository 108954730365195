import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../components/api2";
import Button from "../components/Button";
import {
  available,
  assigned,
  saveAvailableRoles,
  saveAssignedRoles,
  getEmployee,
} from "../urls";
import Header from "../components/Header";
import { circles } from "../images";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const EmployeeRights = () => {
  const [availRoles, setAvailRoles] = useState([]);
  const [assignRoles, setAssignRoles] = useState([]);
  const [searchTermAvailable, setSearchTermAvailable] = useState("");
  const [searchTermAssigned, setSearchTermAssigned] = useState("");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { employee } = location.state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // assgin rights
  let fetchAvailRoles = () => {
    API({
      ...available,
      onSuccess: (res) => {
        setAvailRoles(res.data.data);
      },

      onError: (error) => {
        console.log("No role available");
      },
    });
  };
  let fetchAssginRoles = () => {
    API({
      ...assigned,
      params: {
        user_name: employee.employee_code,
      },

      onSuccess: (res) => {
        if (Array.isArray(res.data.data)) {
          setAssignRoles(res.data.data);
          console.log(res.data.data);
        } else {
          console.log("Invalid response for assigned roles");
        }
      },

      onError: (error) => {
        console.log("No role assigned");
      },
    });
  };

  useEffect(() => {
    fetchAvailRoles();
    fetchAssginRoles();
  }, []);

  const handleRoleClick = (role) => {
    const updatedAvailRoles = availRoles.filter(
      (r) => r.role_id !== role.role_id
    );
    setAvailRoles(updatedAvailRoles);

    const isRoleAssigned = assignRoles.some((r) => r.role_id === role.role_id);

    if (!isRoleAssigned) {
      const updatedAssignRoles = [...assignRoles, role];
      setAssignRoles(updatedAssignRoles);
    }
  };

  const deleteAssignedRole = (roleToDelete) => {
    const rolesToSend = assignRoles.filter(
      (role) => role.role_name !== roleToDelete.role_name
    );

    setAssignRoles(rolesToSend);
  };

  const handleUpdateClick = (roleToDelete) => {
    setLoading(true);
    const filteredAssignRoles = assignRoles.filter((role) => role !== null);
    const clickedRole = assignRoles[assignRoles.length - 1];

    if (roleToDelete) {
      const rolesToSend = assignRoles.filter(
        (role) => role.role_id !== roleToDelete.role_id
      );
      setAssignRoles(rolesToSend);

      API({
        ...saveAvailableRoles,
        body: {
          user_name: employee.employee_code,
          role: filteredAssignRoles.map((role) => role.role_id),
        },
        onSuccess: (res) => {
          setLoading(false);
          Swal.fire({
            title: `${res.data.message}`,
          }).then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        },
        onError: (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    } else if (clickedRole) {
      API({
        ...saveAssignedRoles,
        body: {
          user_name: employee.employee_code,
          role: [clickedRole.role_id],
        },
        onSuccess: (res) => {
          setLoading(false);
          Swal.fire({ title: `${res.data.message}` }).then(() => {
            window.location.reload();
          });
        },
        onError: (error) => {
          setLoading(false);
          Swal.fire({ title: `${error.response.data.message}`, icon: "error" });
        },
      });
    }
  };

  const filteredAvailRoles = availRoles.filter(
    (role) =>
      !assignRoles.find(
        (assignedRole) => assignedRole.role_id === role.role_id
      ) &&
      (role.role_name
        .toLowerCase()
        .includes(searchTermAvailable.toLowerCase()) ||
        (role.role_id &&
          role.role_id
            .toString()
            .toLowerCase()
            .includes(searchTermAvailable.toLowerCase())))
  );
  const filteredAssignedRoles = assignRoles.filter(
    (role) =>
      role.role_name.toLowerCase().includes(searchTermAssigned.toLowerCase()) ||
      (role.role_id &&
        role.role_id
          .toString()
          .toLowerCase()
          .includes(searchTermAssigned.toLowerCase()))
  );
  // ****************

  // handle back
  const handleBack = () => {
    dispatch(
      changePage({
        page: "allEmployeeRights",
      })
    );

    navigate("/");
  };

  // get employee
  useEffect(() => {
    const fetchEmployee = () => {
      API({
        ...getEmployee,
        params: { employee_code: employee.employee_code },
        onSuccess: (res) => {
          setUser(res.data.data);
        },
        onError: (error) => {
          console.error("Error fetching user: ", error);
        },
      });
    };
    fetchEmployee();
  }, []);

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px" }}
        className="bg-white mt-2 me-2 py-4 position-relative overflow-y-auto container-fluid h-100"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div
          style={{ borderBottom: "1px solid rgba(217, 217, 217, 1)" }}
          className="mx-4 pb-3"
        >
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            Assign Information
          </h2>
        </div>
        <div className="mx-4 mt-3">
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
            className="mb-0"
          >
            Audit Info
          </p>
          <div className="row mt-3 px-3">
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Created By: {user.created_by}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Created on:{" "}
                {user.created_on ? user.created_on.split("T")[0] : ""}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Modified By: {user.updated_by ? user.updated_by : "N/A"}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Modified On: {user.updated_on ? user.updated_on : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mx-2 mt-3">
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
              backgroundColor: "#EBEDEE",
              borderRadius: "10px",
            }}
            className="mb-0 py-1 px-3"
          >
            User Detail
          </p>
          <div className="row mt-3 mx-2 px-3">
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                User ID: {user.employee_code}
              </p>
            </div>
            <div className="col-6">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Email: {user.email_id}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Last Disabled Date:{" "}
                {user.last_disabled_date ? user.last_disabled_date : "N/A"}
              </p>
            </div>
          </div>
          <div className="row mt-2 mx-2 px-3">
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                User Name: {user.employee_name}
              </p>
            </div>
            <div className="col-6">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Mobile: {user.contact_no}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "13px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Last Enabled Date:{" "}
                {user.last_enabled_date ? user.last_enabled_date : "N/A"}
              </p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#EBEDEE", borderRadius: "10px" }}
            className="d-flex py-1 px-3 mt-3 align-items-center justify-content-between"
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
              className="mb-0 "
            >
              Assigned Activity
            </p>
          </div>
        </div>

        <div className="row d-flex justify-content-between mx-2 mt-4">
          <div className="col-5">
            <div
              style={{
                border: "1px solid #D9D9D9",
                borderRadius: "5px 5px 0 0",
              }}
            >
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(25, 66, 128, 1)",
                    fontWeight: "500",
                    fontSize: "13px",
                  }}
                  className="mb-0 mt-1"
                >
                  Available Roles
                </p>
              </div>
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(255, 246, 216, 1)",
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <input
                  type="text"
                  value={searchTermAvailable}
                  onChange={(e) => setSearchTermAvailable(e.target.value)}
                  placeholder="Search Available Roles"
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(255, 246, 216, 1)",
                  }}
                  className="text-center"
                />
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ overflowY: "auto", height: "280px" }}>
                  {filteredAvailRoles.length > 0 ? (
                    filteredAvailRoles.map((role, index) => (
                      <div
                        key={index}
                        onClick={() => handleRoleClick(role)}
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          borderBottom:
                            index !== filteredAvailRoles.length - 1
                              ? "1px solid #D9D9D9"
                              : "none",
                        }}
                      >
                        <p
                          className="mb-0 mt-2"
                          style={{
                            padding: "4px 8px",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                            color: "rgba(105, 102, 102, 1)",
                          }}
                        >
                          {role.role_name
                            .split("_")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className="my-3 px-3" style={{ color: "red" }}>
                      No available roles
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div
              style={{
                border: "1px solid #D9D9D9",
                borderRadius: "5px 5px 0 0",
              }}
            >
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Montserrat",
                    color: "rgba(25, 66, 128, 1)",
                    fontWeight: "500",
                    fontSize: "13px",
                  }}
                  className="mb-0 mt-1"
                >
                  Assigned Roles
                </p>
              </div>
              <div
                className="text-center py-1"
                style={{
                  backgroundColor: "rgba(255, 246, 216, 1)",
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <input
                  type="text"
                  value={searchTermAssigned}
                  onChange={(e) => setSearchTermAssigned(e.target.value)}
                  placeholder="Search Assigned Roles"
                  style={{
                    width: "100%",
                    backgroundColor: "rgba(255, 246, 216, 1)",
                  }}
                  className="text-center"
                />
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ overflowY: "auto", height: "280px" }}>
                  {filteredAssignedRoles.length === 0 ? (
                    <div className="my-3 px-3" style={{ color: "red" }}>
                      No assigned roles
                    </div>
                  ) : (
                    filteredAssignedRoles.map((role, index) => (
                      <div
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          borderBottom:
                            index !== filteredAssignedRoles.length - 1
                              ? "1px solid #D9D9D9"
                              : "none",
                        }}
                        key={index}
                        onClick={() => deleteAssignedRole(role)}
                      >
                        <p
                          className="mb-0 mt-2"
                          style={{
                            padding: "4px 8px",
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "13px",
                            color: "rgba(105, 102, 102, 1)",
                          }}
                        >
                          {role.role_name
                            .split("_")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </p>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 pt-3 mx-3 d-flex justify-content-between">
          <Button onClick={handleBack} children={"Back"} />
          <Button
            onClick={handleUpdateClick}
            type="submit"
            style={{ opacity: loading ? "0.6" : "1" }}
            children={loading ? "Loading..." : "Update"}
            disabled={loading}
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeRights;

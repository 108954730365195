import React, { useEffect, useState } from "react";
import API from "../components/api2";
import InputForm from "../components/InputForm";
import { addEndCustomer, getMerchantData, getProfile } from "../urls";
import Button from "../components/Button";
import { circles } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const NewEndCustomer = () => {
  const [pageData, setPageData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [merchIdOption, setMerchIdOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [username, setUsername] = useState([]);
  const [loading, setLoading] = useState(false);

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  }

  // validation
  const validationRules = {
    first_name: "first name",
    contact_no: "contact number",
  };

  const validateForm = () => {
    const newErrors = {};

    Object.entries(validationRules).forEach(([key, label]) => {
      if (!pageData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // on submit
  function onSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      API({
        ...addEndCustomer,
        body: { ...pageData, merchant_id: username },
        onSuccess: (res) => {
          setLoading(false);
          Swal.fire({
            title: `${res.data.message}`,
          }).then(() => {
            setPageData("");
            changePageFunc();
          });
        },

        onError: (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    }
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allEndCustomers",
      })
    );

    navigate("/");
  };

  // merchant dropdown
  useEffect(() => {
    API({
      ...getMerchantData,

      onSuccess: (res) => {
        const merchId = res.data.data.table_data.map((i) => ({
          value: i.merchant_id,
          label: `${i.merchant_id} - ${i.first_name}`,
        }));
        setMerchIdOption(merchId);
      },
      onError: (error) => {
        console.error("Error fetching merchant dropdown: ", error);
      },
    });
  }, []);

  useEffect(() => {
    API({
      ...getProfile,
      onSuccess: (res) => {
        setUsername(res.data.data.username);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, [username]);

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto  py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-4 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add End Customer
        </h2>

        {/* details  */}
        <div
          className="py-1 px-0 ms-0 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "270px",
            marginLeft: "-48px",
          }}
        >
          Basic Information
        </div>
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="container-fluid px-5 pb-5"
        >
          <div className="row mt-3">
            <div className="col-6">
              <label
                style={{
                  marginTop: "10px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="merchant_id"
                className="form-label"
              >
                Merchant ID
              </label>

              <input
                name="merchant_id"
                id="merchant_id"
                className="p-2 w-100"
                value={username}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
                readOnly
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"First Name"}
                id={"first_name"}
                name="first_name"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
                showAsterisk={true}
                required
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Middle Name"}
                id={"middle_name"}
                name="middle_name"
                valueState={pageData}
                onChange={pageDataFunc}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Last Name"}
                id={"last_name"}
                name="last_name"
                valueState={pageData}
                onChange={pageDataFunc}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"GST Number"}
                id={"gst_no"}
                name="gst_no"
                valueState={pageData}
                onChange={pageDataFunc}
                maxInputLength="15"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Aadhar ID"}
                id={"aadhar_no"}
                name="aadhar_no"
                valueState={pageData}
                onChange={pageDataFunc}
                maxInputLength="12"
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"PAN Number"}
                id={"pan_no"}
                name="pan_no"
                valueState={pageData}
                onChange={pageDataFunc}
                maxInputLength="10"
              />
            </div>
          </div>
        </div>

        {/* mailing address  */}
        <div
          className="py-1 px-0 ms-0 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "270px",
            marginLeft: "-48px",
          }}
        >
          Mailing Address
        </div>
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="pb-5"
        >
          <div className="container-fluid px-5">
            <div className="row mt-3">
              <div className="col-6">
                <InputForm
                  type={"number"}
                  placeholder={"Contact Number"}
                  id={"contact_no"}
                  name="contact_no"
                  valueState={pageData}
                  onChange={pageDataFunc}
                  errorState={errors}
                  required
                  showAsterisk={true}
                  maxInputLength="10"
                />
              </div>
              <div className="col-6">
                <InputForm
                  type={"email"}
                  placeholder={"Contact Email"}
                  id={"contact_email"}
                  name="contact_email"
                  valueState={pageData}
                  onChange={pageDataFunc}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <InputForm
                  type={"text"}
                  placeholder={"Residential Mailing City"}
                  id={"res_mail_city"}
                  name="res_mail_city"
                  valueState={pageData}
                  onChange={pageDataFunc}
                />
              </div>
              <div className="col-6">
                <InputForm
                  type={"text"}
                  placeholder={"Residential Mailing State"}
                  id={"res_mail_state"}
                  name="res_mail_state"
                  valueState={pageData}
                  onChange={pageDataFunc}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <InputForm
                  type={"number"}
                  placeholder={"Residential Mailing PIN"}
                  id={"res_mail_pin"}
                  name="res_mail_pin"
                  valueState={pageData}
                  onChange={pageDataFunc}
                  maxInputLength="6"
                />
              </div>
            </div>
            <div className="mt-3">
              <InputForm
                type={"text"}
                placeholder={"Residential Mailing Address"}
                id={"res_mail_addr"}
                name="res_mail_addr"
                valueState={pageData}
                onChange={pageDataFunc}
              />
            </div>
          </div>
        </div>

        {/* kyc details  */}
        <div
          className="py-1 px-0 ms-0 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "270px",
            marginLeft: "-48px",
          }}
        >
          KYC Details
        </div>
        <div className="container-fluid px-5">
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"KYC Contact Person"}
                id={"kyc_contact_person"}
                name="kyc_contact_person"
                valueState={pageData}
                onChange={pageDataFunc}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"email"}
                placeholder={"KYC Contact Email"}
                id={"kyc_contact_email"}
                name="kyc_contact_email"
                valueState={pageData}
                onChange={pageDataFunc}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"KYC Contact No"}
                id={"kyc_contact_no"}
                name="kyc_contact_no"
                valueState={pageData}
                onChange={pageDataFunc}
                maxInputLength="10"
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"KYC Mailing Pin"}
                id={"kyc_mail_pin"}
                name="kyc_mail_pin"
                valueState={pageData}
                onChange={pageDataFunc}
                maxInputLength="6"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"KYC Mailing City"}
                id={"kyc_mail_city"}
                name="kyc_mail_city"
                valueState={pageData}
                onChange={pageDataFunc}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"KYC Mailing State"}
                id={"kyc_mail_state"}
                name="kyc_mail_state"
                valueState={pageData}
                onChange={pageDataFunc}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <InputForm
                type={"text"}
                placeholder={"KYC Mailing Address"}
                id={"kyc_mail_address"}
                name="kyc_mail_address"
                valueState={pageData}
                onChange={pageDataFunc}
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-5 px-5">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button
            style={{
              opacity: loading ? 0.6 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  name: state.user.name,
});

export default connect(mapStateToProps)(NewEndCustomer);

import React, { useEffect, useState } from "react";
import { circles, arrowRight, backArrow } from "../images";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../components/api2";
import ImageViewer from "../components/ImageViewer";

import {
  getTicket,
  addTicketComments,
  getTicketComments,
  updateTicket,
  assginedToTicket,
  revertToTicket,
  progressTracker,
  helpdeskHistory,
} from "../urls";
import Swal from "sweetalert2";
import loaderGif from "../gif/buffer.gif";
import PdfViewer from "../components/PdfViewer";
import ExcelViewer from "../components/ExcelViewer";
import WordViewer from "../components/WordViewer";
import VideoViewer from "../components/VideoViewer";
import { useDispatch } from "react-redux";
import { changePage } from "../redux/slice/dashboardSlice";

const TicketInfo = () => {
  const location = useLocation();
  const service = location.state || {};
  const dispatch = useDispatch();

  const [ticketData, setTicketData] = useState([]);
  const [comments, setComments] = useState([]);
  const [history, setHistory] = useState([]);
  const [showAllComments, setShowAllComments] = useState(false);
  const [showAllHistory, setShowAllHistory] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [assignOptions, setAssignOptions] = useState([]);
  const [revertOptions, setRevertOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedSection, setSelectedSection] = useState("comments");
  const [htmlContent, setHtmlContent] = useState("");

  const [editableFields, setEditableFields] = useState({
    ticket_status: "",
    ticket_desc: "",
    ticket_id: "",
    created_by: "",
    created_on: "",
    additional_info: "",
    assigned_to: "",
    revert_to: "",
  });

  const navigate = useNavigate();

  const [steps, setSteps] = useState([]);

  const handleInputChange = (fieldName, value) => {
    if (fieldName === "assigned_to") {
      value = Number(value);
    }
    if (fieldName === "revert_step" || fieldName === "assigned_to") {
      handleUpdate(value, fieldName);
    }
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const fetchHtmlContent = async (url) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const text = await response.text();
        console.log(text);
        setHtmlContent(text);
      } else {
        console.error(
          `Failed to fetch HTML content. Status code: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error fetching the HTML content:", error);
    }
  };

  // fetch ticket by ticket id
  const fetchTicket = () => {
    setLoader(true);
    API({
      ...getTicket,
      params: { ticket_sid: service?.service?.ticket_sid },
      onSuccess: (res) => {
        setTicketData(res.data.data);
        setEditableFields(res.data.data);
        fetchHtmlContent(res.data.data.ticket_desc[0]);
        setLoader(false);
      },
      onError: (error) => {
        console.error("Error fetching ticket: ", error);
        setLoader(true);
      },
    });
  };
  const { ticket_status } = ticketData;

  // assigned to & revert to
  useEffect(() => {
    const fetchAssignTo = () => {
      API({
        ...assginedToTicket,
        params: {
          ticket_sid: service?.service?.ticket_sid,
          ticket_id: editableFields.ticket_id,
        },
        onSuccess: (res) => {
          setAssignOptions(
            res.data.data.map((a) => ({
              value: Number(a.assigned_to),
              label: `${a.assigned_to} - ${a.name}`,
            }))
          );
        },
        onError: (error) => {
          console.error("Error fetching assigned to:", error);
        },
      });
    };
    const fetchRevertTo = () => {
      API({
        ...revertToTicket,
        params: { ticket_sid: service?.service?.ticket_sid },
        onSuccess: (res) => {
          setRevertOptions(
            res.data.data.map((r) => ({
              value: r.step_id,
              label: `${r.step_id} - ${r.step_name}`,
            }))
          );
        },
        onError: (error) => {
          console.error("Error fetching revert to:", error);
        },
      });
    };
    if (editableFields && editableFields.ticket_id) {
      fetchAssignTo();
      fetchRevertTo();
    }
  }, [editableFields.ticket_id]);

  // progress tracker
  useEffect(() => {
    const getTracker = () => {
      API({
        ...progressTracker,
        params: { ticket_sid: service?.service?.ticket_sid },
        onSuccess: (res) => {
          const defaultStep = {
            step_id: 0,
            step_name: "Ticket Reported",
            complete: true,
          };
          const apiSteps = res.data.data;
          setSteps([defaultStep, ...apiSteps]);
        },
        onError: (error) => {
          console.error("Error fetching progress tracker:", error);
        },
      });
    };
    getTracker();
  }, []);

  // comments
  useEffect(() => {
    if (editableFields.ticket_id) {
      fetchComments();
    }
  }, [editableFields.ticket_id]);

  const fetchComments = () => {
    API({
      ...getTicketComments,
      params: { ticket_id: service?.service?.ticket_sid },
      onSuccess: (res) => {
        setComments(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching comments: ", error);
      },
    });
  };
  const handleAddComment = () => {
    const comment = document.getElementById("comments").value;
    const formData = new FormData();
    formData.append("ticket_id", service?.service?.ticket_sid);
    formData.append("comment", comment);
    selectedFiles.forEach((file, index) => {
      formData.append("attachments", file);
    });

    if (!comment.trim() && selectedFiles.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Comment cannot be empty",
      });
      return;
    }
    setLoader(true);

    API({
      ...addTicketComments,
      body: formData,
      isFormData: true,
      onSuccess: (res) => {
        setLoader(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoader(false);
        console.error("Error adding comment: ", error);
      },
    });
  };

  const toggleComments = () => {
    setShowAllComments(!showAllComments);
  };
  const commentsToDisplay = showAllComments ? comments : comments.slice(0, 3);

  // comment attachments
  const handleFileChange = (event) => {
    const imageFiles = Array.from(event.target.files).filter((file) =>
      file.type.startsWith("image/")
    );
    setSelectedFiles([...selectedFiles, ...imageFiles]);
  };
  const handleRemoveAttachment = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter(
      (file, index) => index !== indexToRemove
    );
    setSelectedFiles(updatedFiles);
  };

  // history
  useEffect(() => {
    API({
      ...helpdeskHistory,
      params: { ticket_id: service?.service?.ticket_sid },
      onSuccess: (res) => {
        setHistory(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching history logs: ", error);
      },
    });
  }, [editableFields.ticket_id]);

  const toggleHistory = () => {
    setShowAllHistory(!showAllHistory);
  };
  const sortedHistory = [...history].sort(
    (a, b) => new Date(b.created_on) - new Date(a.created_on)
  );
  const historyToDisplay = showAllHistory
    ? sortedHistory
    : sortedHistory.slice(0, 3);

  // handle update
  const handleUpdate = (value, field) => {
    setLoader(true);
    const updatedFields = {
      [field]: value,
      ticket_id: editableFields.ticket_id,
      ticket_sid: service?.service?.ticket_sid,
    };
    API({
      ...updateTicket,
      body: updatedFields,
      onSuccess: (res) => {
        setLoader(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },
      onError: (error) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  useEffect(() => {
    fetchTicket();
  }, []);

  useEffect(() => {
    const container = document.getElementById("htmlContainer");
    if (container) {
      const tables = container.getElementsByTagName("table");
      for (let i = 0; i < tables.length; i++) {
        const table = tables[i];
        table.style.border = "1px solid #ccc";
        table.style.borderCollapse = "collapse";
        table.style.width = "100%";
        table.style.marginBottom = "20px";

        const cells = table.getElementsByTagName("td");
        for (let j = 0; j < cells.length; j++) {
          cells[j].style.padding = "8px";
          cells[j].style.border = "1px solid #ccc";
        }

        const rows = table.getElementsByTagName("tr");
        for (let k = 0; k < rows.length; k++) {
          rows[k].style.borderBottom = "1px solid #ccc";
        }

        const columns = table.querySelectorAll("th, td:first-child");
        for (let l = 0; l < columns.length; l++) {
          columns[l].style.borderRight = "1px solid #ccc";
        }
      }
    }
  }, [htmlContent]);

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allTickets",
      })
    );
    navigate("/");
  };

  return (
    <>
      {loader ? (
        <img
          className="position-absolute"
          style={{
            height: "70px",
            width: "70px",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src={loaderGif}
          alt="Loading..."
        />
      ) : (
        <div className="py-4 overflow-y-auto my-2 flex-grow-1 position-relative container-fluid h-100 bg-white">
          <img
            style={{ position: "absolute", right: "0", top: "0" }}
            src={circles}
            alt="circles"
          />
          <div
            style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
            className="d-flex align-items-center pb-4 ps-2"
          >
            <img
              style={{ cursor: "pointer", pointerEvents: "all" }}
              src={backArrow}
              alt="back"
              onClick={changePageFunc}
            />
            <h2
              className="mb-0 ms-3"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "24px",
              }}
            >
              Ticket No. {service?.service?.ticket_sid}
            </h2>
          </div>

          {/* ticket info */}
          <div
            style={{
              borderBottom: "1px solid #AD8352",
            }}
          >
            <div className="mx-3 my-4 pb-4">
              {" "}
              <p
                className="mb-0"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  color: "rgba(105, 102, 102, 1)",
                  width: "240px",
                }}
              >
                Ticket Info
              </p>
              <div className="mx-2">
                <div className="row mt-3">
                  <div className="col-5">
                    {/* type */}
                    <div className="d-flex align-items-center">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "600",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          width: "160px",
                          color: "rgba(173, 131, 82, 1)",
                        }}
                      >
                        Type
                      </p>
                      <input
                        type="text"
                        name="type"
                        className="p-2 w-100"
                        value={editableFields.type}
                        onChange={(e) =>
                          handleInputChange("type", e.target.value)
                        }
                        style={{
                          backgroundColor: "#E7E8E9",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                {/* category & sub category */}
                <div className="row justify-content-between mt-4">
                  <div className="col-5">
                    <div className="d-flex align-items-center">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "600",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          color: "rgba(173, 131, 82, 1)",
                          width: "160px",
                        }}
                      >
                        Category
                      </p>
                      <input
                        type="text"
                        name="category"
                        className="p-2 w-100"
                        value={editableFields.category}
                        onChange={(e) =>
                          handleInputChange("category", e.target.value)
                        }
                        style={{
                          backgroundColor: "#E7E8E9",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="d-flex align-items-center">
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: "600",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          width: "160px",
                          color: "rgba(173, 131, 82, 1)",
                        }}
                      >
                        Sub-Category
                      </p>
                      <input
                        type="text"
                        name="subcategory"
                        className="p-2 w-100 ms-4"
                        value={editableFields.subcategory}
                        onChange={(e) =>
                          handleInputChange("subcategory", e.target.value)
                        }
                        style={{
                          backgroundColor: "#E7E8E9",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                {/* title */}
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Title
                  </p>
                  <input
                    type="text"
                    name="subject"
                    className="p-2 w-100 mt-2 title"
                    value={editableFields.subject}
                    onChange={(e) =>
                      handleInputChange("subject", e.target.value)
                    }
                    readOnly
                  />
                </div>

                {/* additional fields */}
                <div className="mt-4">
                  {Array.isArray(ticketData.additional_fields) &&
                    ticketData.additional_fields.length > 0 &&
                    ticketData.additional_fields.map((field, index) => {
                      const { field_label, field_value } = field;

                      return (
                        <div key={index} className="mb-3">
                          <p
                            className="mb-0"
                            style={{
                              fontWeight: "600",
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                              color: "rgba(173, 131, 82, 1)",
                            }}
                          >
                            {field_label}
                          </p>
                          <input
                            type="text"
                            name={field_label}
                            className="p-2 w-100 mt-2 title"
                            value={field_value}
                            onChange={(e) =>
                              handleInputChange(field_label, e.target.value)
                            }
                            readOnly
                          />
                        </div>
                      );
                    })}
                </div>

                {/* description */}
                <div className="mt-4">
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    Description
                  </p>
                  <div
                    id="htmlContainer"
                    className="w-100 p-2 mt-2"
                    style={{
                      outline: "none",
                      border: "1px solid rgba(217, 217, 217, 1)",
                      borderRadius: "10px",
                      resize: "none",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: htmlContent,
                    }}
                  ></div>
                </div>

                {/* reporter */}
                <div className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column align-items-baseline">
                      <div
                        style={{
                          backgroundColor: "rgba(173, 131, 82, 1)",
                          borderRadius: "5px",
                          width: "150px",
                        }}
                      >
                        <p
                          className="mb-0 text-white px-2 py-1"
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}
                        >
                          Reported by
                        </p>
                      </div>
                      <p
                        className="mb-0 text-center mt-1"
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        {ticketData.name} ({ticketData.reported_by})
                      </p>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <div
                        style={{
                          backgroundColor: "rgba(173, 131, 82, 1)",
                          borderRadius: "5px",
                          width: "150px",
                          textAlign: "end",
                        }}
                      >
                        <p
                          className="mb-0 text-white px-2 py-1"
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}
                        >
                          Reported on
                        </p>
                      </div>
                      <p
                        className="mb-0 text-center mt-1"
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        {ticketData.created_on}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* assignee, revert to, status */}
          <div style={{ borderBottom: "1px solid #AD8352" }} className="pb-4">
            <div className="row justify-content-between mt-4 mx-2">
              <div className="col-4">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="assigned_to"
                  className="form-label ms-2"
                >
                  Assignee
                </label>
                <select
                  name="assigned_to"
                  value={editableFields.assigned_to}
                  placeholder="Choose Assignee"
                  onChange={(e) =>
                    handleInputChange("assigned_to", e.target.value)
                  }
                  className="p-2 w-100 mt-1"
                  style={{
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    marginTop: "8px",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                    width: "100%",
                    fontFamily: "Montserrat",
                    outline: "none",
                    padding: "12px 8px",
                  }}
                >
                  <option disabled value="">
                    Choose Assignee
                  </option>
                  {assignOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="revert_step"
                  className="form-label ms-2"
                >
                  Revert to
                </label>
                <select
                  name="revert_step"
                  value={editableFields.revert_step}
                  onChange={(e) =>
                    handleInputChange("revert_step", e.target.value)
                  }
                  className="p-2 w-100 mt-1"
                  placeholder="Select Status"
                  style={{
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    marginTop: "8px",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                    width: "100%",
                    fontFamily: "Montserrat",
                    outline: "none",
                    padding: "12px 8px",
                  }}
                  disabled={ticket_status === "Close"}
                >
                  <option value="">Select Status</option>
                  {revertOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row align-items-center justify-content-between my-4 mx-2">
              <div className="col-4">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="ticket_status"
                  className="form-label ms-2"
                >
                  Status
                </label>
                <input
                  name="ticket_status"
                  value={editableFields.ticket_status}
                  onChange={(e) =>
                    handleInputChange("ticket_status", e.target.value)
                  }
                  className="p-2 w-100 mt-1"
                  placeholder="Enter Status"
                  style={{
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    marginTop: "8px",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                    width: "100%",
                    fontFamily: "Montserrat",
                    outline: "none",
                    padding: "12px 8px",
                  }}
                  disabled={true}
                />
              </div>
              <div className="col-4 mt-5">
                <button
                  onClick={() => handleUpdate("Proceed", "ticket_status")}
                  className="py-1 px-4 border-0"
                  style={{
                    backgroundColor: "rgba(22, 64, 127, 1)",
                    color: "white",
                    borderRadius: "6px",
                    fontSize: "18px",
                    opacity: ticket_status === "Close" ? "0.5" : "1",
                    cursor: ticket_status === "Close" ? "no-drop" : "pointer",
                  }}
                  disabled={ticket_status === "Close"}
                >
                  {ticket_status === "Close" ? "Completed" : " Complete Task"}
                </button>
              </div>
            </div>
          </div>

          {/* progress tracker */}
          <div
            style={{ borderBottom: "1px solid #AD8352" }}
            className="mt-4 pt-2 pb-4"
          >
            <h3
              className="px-4"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Ticket Progress Tracker
            </h3>
            <div className="d-flex align-items-center my-4 justify-content-center">
              {steps.map((step, index) => (
                <React.Fragment key={step.step_id}>
                  <div
                    style={{ width: "20px", height: "90px" }}
                    className="d-flex flex-column align-items-center"
                  >
                    <div
                      className={`dot ${step.complete ? "filled" : "hollow"} ${
                        step.revert ? "reverted" : ""
                      }`}
                    />

                    <div
                      style={{
                        width: "1px",
                        height: index % 2 === 0 ? "20px" : "40px",
                        borderLeft: " 2px dotted rgba(173, 131, 82, 1)",
                      }}
                    ></div>
                    <div
                      className="mt-1"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        color: "rgba(173, 131, 82, 1)",
                        fontSize: "13px",
                        width: "120px",
                        textAlign: "center",
                      }}
                    >
                      {step.step_name || `Step ${index}`}
                    </div>
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        color: "rgba(173, 131, 82, 1)",
                        fontSize: "13px",
                        width: "120px",
                        textAlign: "center",
                      }}
                    ></div>
                  </div>
                  {index < steps.length - 1 && <div className="line"></div>}
                </React.Fragment>
              ))}
            </div>
          </div>

          {/* Attachments*/}
          <div
            style={{ borderBottom: "1px solid #AD8352" }}
            className="mt-4 pt-2 pb-4"
          >
            <h3
              className="px-4"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Attachments
            </h3>
            <div className="row align-items-center mx-4">
              {!editableFields?.attachments ||
              editableFields.attachments.length === 0 ? (
                <div className="col-12 mt-4">
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(173, 131, 82, 1)",
                    }}
                  >
                    No attachments found
                  </p>
                </div>
              ) : (
                editableFields.attachments.map((attachment, index) => {
                  const fileExtension = attachment
                    .split(".")
                    .pop()
                    .toLowerCase();
                  return (
                    <div key={index} className="col-3 mx-2 mt-2">
                      {fileExtension === "pdf" ? (
                        <PdfViewer
                          src={attachment}
                          alt={`attachment-${index}`}
                        />
                      ) : fileExtension === "xlsx" ? (
                        <ExcelViewer
                          src={attachment}
                          alt={`attachment-${index}`}
                        />
                      ) : fileExtension === "doc" ||
                        fileExtension === "docx" ? (
                        <WordViewer src={attachment} />
                      ) : fileExtension === "mp4" ||
                        fileExtension === "avi" ||
                        fileExtension === "mov" ? (
                        <VideoViewer src={attachment} />
                      ) : (
                        <ImageViewer
                          thumbnailHeight="150px"
                          src={attachment}
                          alt={`attachment-${index}`}
                        />
                      )}
                    </div>
                  );
                })
              )}
            </div>
          </div>

          {/* comment & history */}
          <div className="mt-4 pb-3">
            <div className="d-flex align-items-center">
              <h3
                className="px-4"
                onClick={() => handleSectionClick("comments")}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                  cursor: "pointer",
                  textDecoration:
                    selectedSection === "comments" ? "underline" : "",
                }}
              >
                Comments
              </h3>
              <h3
                onClick={() => handleSectionClick("history")}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "rgba(105, 102, 102, 1)",
                  cursor: "pointer",
                  textDecoration:
                    selectedSection === "history" ? "underline" : "",
                }}
              >
                History
              </h3>
            </div>
            {selectedSection === "comments" && (
              <>
                <div
                  className="mt-4 mx-4 px-5 py-3"
                  style={{
                    overflowY: "auto",
                  }}
                >
                  {commentsToDisplay.length === 0 ? (
                    <p
                      className="text-center"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                      }}
                    >
                      No comments found.
                    </p>
                  ) : (
                    commentsToDisplay.map((comment) => (
                      <React.Fragment key={comment.comment_id}>
                        <div className="d-flex align-items-center justify-content-between">
                          <p
                            className="mb-0"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: "600",
                            }}
                          >
                            {`${comment.name} (${comment.created_by})`}
                          </p>
                          <p
                            className="mb-0"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: "600",
                            }}
                          >
                            {comment.created_on.replace(" ", ", ")}
                          </p>
                        </div>
                        <div
                          className="row d-flex justify-content-around p-2 mt-1 mb-4"
                          style={{
                            backgroundColor: "#E7E8E9",
                            borderRadius: "0px 14px 14px 14px",
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "14px",
                            }}
                          >
                            {comment.comments}
                          </p>
                          {comment.attachments.length > 0 && (
                            <div className="d-flex align-items-center">
                              {comment.attachments.map((attachment, index) => (
                                <ImageViewer
                                  key={index}
                                  // thumbnailHeight="20px"
                                  thumbnailWidth="40px"
                                  src={attachment}
                                  alt={`comment-${index}`}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    ))
                  )}
                  {comments.length > 3 && (
                    <div className="d-flex ms-0">
                      <button
                        style={{
                          color: "rgba(105, 102, 102, 1)",
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                        onClick={toggleComments}
                        className="btn btn-link"
                      >
                        {showAllComments
                          ? "Hide old comments"
                          : "See old comments"}
                      </button>
                    </div>
                  )}
                </div>
                <div className="px-5">
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "14px",
                      color: "rgba(105, 102, 102, 1)",
                    }}
                    className="mb-0 mt-3 ms-3"
                  >
                    Add Response
                  </p>
                  <div
                    style={{
                      backgroundColor: "#FFF6D8",
                      borderRadius: "14px",
                      border: "1.5px solid #D9D9D9",
                    }}
                  >
                    <div className="d-flex align-items-center py-2">
                      <textarea
                        className="w-100 px-3"
                        style={{
                          outline: "none",
                          backgroundColor: "#FFF6D8",
                          resize: "none",
                          border: "none",
                          borderRadius: "14px",
                        }}
                        name="comments"
                        placeholder="Write Response Here"
                        id="comments"
                        rows="4"
                      ></textarea>

                      <div className="d-flex align-items-center mt-4">
                        <div className="file-container2">
                          {selectedFiles.map((file, index) => (
                            <div
                              key={index}
                              className="file-box"
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                              onClick={() => handleRemoveAttachment(index)}
                            >
                              {file.type.startsWith("image/") ? (
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`file_${index}`}
                                  className="uploaded-image"
                                />
                              ) : (
                                <div className="file-icon">
                                  <i
                                    className="fa fa-file-text-o"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        <input
                          type="file"
                          id="attachments"
                          name="attachments"
                          accept="image/*"
                          multiple
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <label
                          htmlFor="attachments"
                          className={"custom-upload-btn2 d-block"}
                          style={{ fontSize: "13px", color: "black" }}
                        >
                          Upload Supporting Documents
                        </label>
                        <button
                          style={{
                            borderRadius: "14px",
                            border: "1px solid black",
                          }}
                          className="mx-3 px-3 bg-white"
                          onClick={handleAddComment}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {loader && (
              <img
                style={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                  width: "60px",
                  transform: "translate(-50%)",
                }}
                src={loaderGif}
                alt="Loading..."
              />
            )}
            {selectedSection === "history" && (
              <div
                className="mt-4 mx-4 px-3 py-3"
                style={{
                  overflowY: "auto",
                }}
              >
                {historyToDisplay.length === 0 ? (
                  <p
                    className="text-center"
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(105, 102, 102, 1)",
                    }}
                  >
                    No history found.
                  </p>
                ) : (
                  historyToDisplay.map((log, index) => (
                    <div className="mt-4" key={index}>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="d-flex">
                          <h2
                            className="mb-0"
                            style={{
                              fontFamily: "Montserrat",
                              fontWeight: "600",
                              fontSize: "14px",
                              color: "rgba(105, 102, 102, 1)",
                            }}
                          >
                            {log.name} ({log.created_by})
                          </h2>
                          <div>
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "rgba(105, 102, 102, 1)",
                                marginTop: "-2px",
                              }}
                              className="mb-0 ms-4"
                            >
                              {log.type}
                            </p>
                            {log.from_field && log.to && (
                              <div className="d-flex align-items-center mt-2">
                                <p
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: "600",
                                    fontSize: "12px",
                                    backgroundColor: "rgba(79, 87, 91, 1)",
                                    borderRadius: "5px",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                  className="mb-0 px-2 py-1 ms-3"
                                >
                                  {log.from_field}
                                </p>
                                <img
                                  className="mx-3"
                                  src={arrowRight}
                                  alt="arrow"
                                />
                                <p
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: "600",
                                    fontSize: "12px",
                                    backgroundColor: "rgba(25, 66, 128, 1)",
                                    borderRadius: "5px",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                  className="mb-0 px-2 py-1"
                                >
                                  {log.to}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <p
                          className="mb-0"
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: "600",
                            fontSize: "14px",
                            color: "rgba(105, 102, 102, 1)",
                          }}
                        >
                          {log.created_on}
                        </p>
                      </div>
                    </div>
                  ))
                )}
                {history.length > 3 && (
                  <div className="d-flex ms-0 mt-5">
                    <button
                      style={{
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                      onClick={toggleHistory}
                      className="btn btn-link"
                    >
                      {showAllHistory ? "Hide old history" : "See old history"}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TicketInfo;

export const fetchHtmlContent = async (link) => {
  try {
    const response = await fetch(link);
    if (response.ok) {
      const text = await response.text();
      console.log(text);
      return text;
    } else {
      console.error(
        `Failed to fetch HTML content. Status code: ${response.status}`
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching the HTML content:", error);
    return null;
  }
};

export const splitFormatTime = (tat) => {
  const daysMatch = tat.match(/(\d+) days/);
  const timeMatch = tat.match(/(\d{2}):(\d{2}):(\d{2})/);
  const days = daysMatch ? daysMatch[1] : "00";
  const hours = timeMatch ? timeMatch[1] : "00";
  const minutes = timeMatch ? timeMatch[2] : "00";
  const seconds = timeMatch ? timeMatch[3] : "00";

  return { days, hours, minutes, seconds };
};
export const checkAlphabet = (value) => /^[A-Za-z ]*$/.test(value);
export const checkNumeric = (value) => /^[0-9]*$/.test(value);
export const checkSplCharacter = (value) => /^[A-Za-z0-9-, ]*$/.test(value);
export const checkAlphaNumeric = (value) => /^[A-Za-z0-9 ]*$/.test(value);
export const checkPAN = (value) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);

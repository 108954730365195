import React, { useEffect, useState } from "react";
import API from "../components/api2";
import InputForm from "../components/InputForm";
import { addConsumer, getApiPortalDropdown } from "../urls";
import Button from "../components/Button";
import { circles } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

const NewConsumer = () => {
  const [pageData, setPageData] = useState({});
  const [standardServiceOptions, setStandardServiceOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [serviceTypeOptions] = useState([
    { value: "FINANCIAL", label: "FINANCIAL" },
    { value: "NON-FINANCIAL", label: "NON-FINANCIAL" },
  ]);

  const commissionType = [
    { value: "POST-TRANSACTION", label: "POST-TRANSACTION" },
    { value: "CHARGE-CONSUMER", label: "CHARGE-CONSUMER" },
    { value: "ADVANCE-DISCOUNT", label: "ADVANCE-DISCOUNT" },
  ];

  useEffect(() => {
    const fetchStandardService = () => {
      API({
        ...getApiPortalDropdown,
        onSuccess: (res) => {
          const consumers = res.data.data.utility_service_names.map((c) => ({
            value: c.service_id,
            label: `${c.service_id} - ${c.utility_service_name}`,
          }));
          setStandardServiceOptions(consumers);
        },

        onError: (error) => {
          console.log("error");
        },
      });
    };

    fetchStandardService();
  }, []);

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  }

  // validation
  const validationRules = {
    service_id: "service id",
    service_type: "service type",
    consumer_service_name: "consumer service name",
    commision_type: "commission type",
    commission_fixed: "commission fixed",
    commission_percent: "commission percent",
    additional_surcharge: "additional surcharge",
    applied_commision: "applied commission",
  };

  const validateForm = () => {
    const newErrors = {};

    Object.entries(validationRules).forEach(([key, label]) => {
      if (!pageData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // on submit
  function onSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      API({
        ...addConsumer,
        body: pageData,
        onSuccess: (res) => {
          setLoading(false);
          Swal.fire({
            title: `${res.data.message}`,
          }).then(() => {
            changePageFunc();
          });
        },

        onError: (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    }
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "getConsumer",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />

      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto  py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add Consumer Service
        </h2>
        <div
          className="py-1 px-0 ms-0 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "270px",
            marginLeft: "-48px",
          }}
        >
          Consumer Service Info
        </div>
        <div className="px-5 container-fluid">
          <div className="row mt-3">
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="service_id"
                className="form-label"
              >
                API & Portal Based Utility Service Id
              </label>
              <Select
                options={standardServiceOptions}
                placeholder="Select ID"
                id={"service_id"}
                name={"service_id"}
                valueState={pageData["service_id"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    service_id: selectedOption.value,
                  })
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["service_id"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["service_id"] ? "1px solid #DC4C64" : "none",
                  }),
                }}
              />
              {errors["service_id"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["service_id"]}
                </div>
              )}
            </div>
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="service_type"
                className="form-label"
              >
                Service Mode
              </label>
              <Select
                options={serviceTypeOptions}
                placeholder="Select Service Mode"
                id={"service_type"}
                name={"service_type"}
                valueState={pageData["service_type"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    service_type: selectedOption.value,
                  })
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["service_type"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["service_type"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
              {errors["service_type"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["service_type"]}
                </div>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Consumer Service Name"}
                id={"consumer_service_name"}
                name="consumer_service_name"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="commision_type"
                className="form-label"
              >
                Commission Type
              </label>
              <Select
                options={commissionType}
                placeholder="Select Type"
                id={"commision_type"}
                name={"commision_type"}
                valueState={pageData["commision_type"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    commision_type: selectedOption.value,
                  })
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["commision_type"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["commision_type"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
              {errors["commision_type"] && (
                <div style={{ fontSize: "14px" }} className="text-danger mt-1">
                  {errors["commision_type"]}
                </div>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Commission Fixed"}
                id={"commission_fixed"}
                name="commission_fixed"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Commission Percent"}
                id={"commission_percent"}
                name="commission_percent"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Additional Surcharge"}
                id={"additional_surcharge"}
                name="additional_surcharge"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Applied Commission"}
                id={"applied_commision"}
                name="applied_commision"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <Button onClick={changePageFunc} children={"Back"} />
            <Button
              style={{
                opacity: loading ? 0.6 : 1,
                pointerEvents: loading ? "none" : "auto",
              }}
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewConsumer;

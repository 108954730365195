import React, { useEffect, useState, useRef } from "react";
import API from "../components/api2";
import { verifyOTP, generateOTP, getLoggedMenus, aepsRegLock } from "../urls";
import { changePage } from "../redux/slice/dashboardSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  onVerifyLogin,
  menuList,
  isRegEnabled,
  aepsUserData,
  rolesToSend,
} from "../redux/slice/userSlice";

const OtpVerification = ({ duid, username }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timerSeconds, setTimerSeconds] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isOtpFilled, setIsOtpFilled] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRefs = useRef(Array(6).fill(null));
  const [reqId, setReqId] = useState(null);
  const { redirectPage, reqId: initialReqId } = location.state || {};
  const { name } = location.state || {};
  const typedEmail = location.state ? location.state.typedEmail : null;
  useEffect(() => {
    let timerInterval;

    if (isResendDisabled) {
      timerInterval = setInterval(() => {
        setTimerSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timerInterval);
            setIsResendDisabled(false);
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [isResendDisabled]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (initialReqId) {
      setReqId(initialReqId);
    }
  }, [initialReqId]);

  useEffect(() => {
    const isFilled = otp.every((digit) => digit !== "");
    setIsOtpFilled(isFilled);
  }, [otp]);

  // logged menus
  const fetchLoggedMenus = () => {
    API({
      ...getLoggedMenus,
      onSuccess: (res) => {
        dispatch(
          menuList({
            menus: res.data.data.menu_data,
            roles: res.data.data.roles_to_send,
          })
        );
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  // apes reg lock
  const aepsRegVerify = () => {
    API({
      ...aepsRegLock,
      onSuccess: (res) => {
        const data = res.data.data.registration;
        const aepsMob = res.data.data.mobile;
        const aepsAadharNo = res.data.data.aadhar;
        if (data === true) {
          dispatch(
            isRegEnabled({
              aepsReg: true,
            })
          );
        }
        dispatch(
          aepsUserData({
            aepsMobile: aepsMob,
            aepsAadhar: aepsAadharNo,
          })
        );
      },
      onError: (error) => {
        console.log("Error", error);
      },
    });
  };

  const handleVerifyOTP = () => {
    const enteredOTP = otp.join("");
    setLoading(true);

    API({
      ...verifyOTP,
      body: {
        duid,
        username,
        request_id: reqId,
        otp: enteredOTP,
      },
      onSuccess: (res) => {
        setLoading(false);
        let successMessage = "Logged In Successfully";

        if (typedEmail) {
          successMessage = "Email verified successfully";
        }
        Swal.fire({
          icon: "success",
          title: successMessage,
        });
        aepsRegVerify();
        fetchLoggedMenus();
        dispatch(
          onVerifyLogin({
            t: res.data.data.t,
            rt: res.data.data.rt,
          })
        );
        changePageFunc(redirectPage);
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const handleResendOTP = () => {
    setIsResendDisabled(true);
    setTimerSeconds(30);
    API({
      ...generateOTP,

      onSuccess: (res) => {
        const newRequestId = res.data.data.request_id;
        setReqId(newRequestId);
        Swal.fire({
          icon: "success",
          title: `OTP sent successfully`,
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
        setIsResendDisabled(false);
      },
    });
  };

  const changePageFunc = (redirectPage) => {
    redirectPage = typedEmail ? "login" : "home";

    dispatch(
      changePage({
        page: redirectPage,
      })
    );

    if (redirectPage === "login") {
      navigate("/login", {
        state: {
          username,
          name,
        },
      });
    } else {
      navigate("/", {
        state: {
          username,
          name,
        },
      });
    }
  };

  const handleInputChange = (index, value, event) => {
    value = value.replace(/[^0-9]/g, "");

    const updatedOtp = [...otp];
    updatedOtp[index] = value.charAt(0);
    setOtp(updatedOtp);

    if (inputRefs.current[index]) {
      inputRefs.current[index].value = value.charAt(0);
    }

    if (value !== "" && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (event.key === "Backspace" && index > 0 && value === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <section className="bg-white min-vh-100 w-100">
      <div
        className="mx-auto text-center col-6"
        style={{ paddingTop: "150px" }}
      >
        <p
          className="login_text"
          style={{
            fontWeight: "600",
            fontSize: "20px",
            color: "rgba(79, 87, 91, 1)",
            letterSpacing: "1px",
          }}
        >
          Please enter the One-Time Password to verify account
        </p>
        <p
          className="mt-2 login_text"
          style={{
            fontWeight: "400",
            fontSize: "15px",
            color: "rgba(79, 87, 91, 1)",
            letterSpacing: "1px",
          }}
        >
          OTP sent to your{" "}
          <span style={{ fontWeight: "600" }}>
            {typedEmail ? typedEmail : "registered email ID"}
          </span>
        </p>
        <div className="mt-5">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="number"
              maxLength="1"
              pattern="[0-9]*"
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value, e)}
              onKeyDown={(e) => handleInputChange(index, digit, e)}
              style={{
                width: "30px",
                margin: "0 5px",
                textAlign: "center",
                fontSize: "20px",
                outline: "none",
              }}
              className="borderBottom login_text"
              ref={(ref) => (inputRefs.current[index] = ref)}
            />
          ))}
        </div>
        <div className="text-center mt-5">
          <button
            type="submit"
            className="border-0 text-white py-1 px-4 mt-4 col-5 login_text"
            style={{
              backgroundColor: loading ? "#9ca3af" : "#194280",
              borderRadius: "10px",
              fontSize: "18px",
              opacity: isOtpFilled ? (loading ? 0.7 : 1) : 0.5,
              pointerEvents: isOtpFilled && !loading ? "auto" : "none",
              cursor: loading ? "not-allowed" : "pointer",
            }}
            disabled={!isOtpFilled || loading}
            onClick={handleVerifyOTP}
          >
            {loading ? "Verifying..." : "Verify"}
          </button>
          <div className="mt-2">
            {!isResendDisabled && (
              <>
                <button
                  className="bg-transparent border-0 login_text"
                  onClick={handleResendOTP}
                  disabled={isResendDisabled}
                  style={{
                    opacity: isResendDisabled ? 0.5 : 1,
                    cursor: "pointer",
                    color: "rgba(79, 87, 91, 1)",
                    fontWeight: "400",
                  }}
                >
                  Didn't receive any code?{" "}
                  <span
                    style={{
                      color: "rgba(249, 124, 75, 1)",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    Resend OTP
                  </span>
                </button>
              </>
            )}
            {isResendDisabled && (
              <span
                className="login_text"
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "rgba(118, 127, 131, 1)",
                }}
              >
                Resend OTP in {timerSeconds} seconds
              </span>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtpVerification;

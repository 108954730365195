import React, { useEffect, useState } from "react";
import InputForm from "../components/InputForm";
import API from "../components/api2";
import { changePassword, logout } from "../urls";
import Swal from "sweetalert2";
import Header from "../components/Header";
import { circles, liDot } from "../images";
import { useDispatch } from "react-redux";
import { onLogout } from "../redux/slice/userSlice";

const ChangePassProfile = () => {
  const [pageData, setPageData] = useState({});
  const [formFilled, setFormFilled] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [confirmTouched, setConfirmTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function pageDataFunc(event) {
    const { id, value } = event.target;
    return setPageData((prev) => ({ ...prev, [id]: value }));
  }

  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const { new_password, confirm_password, old_password } = pageData;

    if (new_password !== confirm_password) {
      Swal.fire({
        icon: "error",
        title: "Passwords do not match",
      });
      return;
    }

    API({
      ...changePassword,
      body: {
        new_password,
        confirm_password,
        old_password,
      },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          API({
            ...logout,
            onSuccess: (res) => {
              dispatch(onLogout());
            },
            onError: (error) => {
              console.error("Logout failed");
            },
          });
        });
      },
      onError: (error) => {
        setLoading(false);
        console.error("Change Password API Error:", error);
        const errorMessage =
          error?.res?.data?.message || "An error occurred. Please try again.";
        Swal.fire({
          icon: "error",
          title: errorMessage,
        });
      },
    });
  }

  const passwordRequirements = [
    "Your password can’t be too similar to your other personal information.",
    "Your password must contain at least 8 characters.",
    "Your password can’t be a commonly used password.",
    "Your password can’t be entirely numeric.",
    "Your password must contain at least 1 uppercase letter, A-Z.",
    "Your password must contain at least 1 lowercase letter, a-z.",
    "Your password must contain at least 1 symbol: ()[]{}|`~!@#$%^&*_-+=;:'\",<>./?",
    "The new password cannot be the same as the old password.",
  ];

  useEffect(() => {
    const isFilled = Object.values(pageData).every(
      (val) => val && val.trim() !== ""
    );
    setFormFilled(isFilled);

    if (
      confirmTouched &&
      pageData.new_password &&
      pageData.confirm_password &&
      pageData.new_password === pageData.confirm_password
    ) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [pageData, confirmTouched]);

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Change Password
          </h2>
        </div>
        <div className="container-fluid">
          <form onSubmit={onSubmit} className="pt-4">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-5 mx-auto">
                <InputForm
                  type="password"
                  name="old_password"
                  id="old_password"
                  autoComplete="off"
                  placeholder="Old Password"
                  valueState={pageData}
                  onChange={pageDataFunc}
                  showEyeIcon={false}
                />
                <InputForm
                  type="password"
                  name="new_password"
                  id="new_password"
                  autoComplete="off"
                  placeholder="New Password"
                  valueState={pageData}
                  onChange={pageDataFunc}
                  showEyeIcon={false}
                />
                <InputForm
                  type="password"
                  name="confirm_password"
                  id="confirm_password"
                  autoComplete="off"
                  placeholder="Confirm Password"
                  valueState={pageData}
                  onChange={(event) => {
                    pageDataFunc(event);
                    setConfirmTouched(true);
                  }}
                  showEyeIcon={false}
                />
                {!passwordsMatch && confirmTouched && (
                  <p style={{ color: "red" }}>Passwords do not match</p>
                )}
                <div className="col-6">
                  <button
                    type="submit"
                    className="border-0 text-white py-2 px-4 mt-4 col-12"
                    style={{
                      backgroundColor: "#194280",
                      borderRadius: "10px",
                      opacity:
                        (formFilled && passwordsMatch) || !loading ? 1 : 0.5,
                      pointerEvents:
                        (formFilled && passwordsMatch) || !loading
                          ? "auto"
                          : "none",
                    }}
                    disabled={!formFilled || !passwordsMatch || loading}
                  >
                    {loading ? "Loading..." : "Change Password"}
                  </button>
                </div>
              </div>
              <div className="col-6 mx-auto mt-2">
                <div
                  className="py-2 px-1"
                  style={{
                    border: "1.5px solid #B0B8CF",
                    borderRadius: "10px",
                  }}
                >
                  <ul style={{ listStyleType: "none" }}>
                    {passwordRequirements.map((text, index) => {
                      return (
                        <li
                          key={index}
                          className="mb-0 mt-1"
                          style={{
                            color: "rgba(18, 33, 88, 1)",
                            fontSize: "16px",
                          }}
                        >
                          {text}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassProfile;

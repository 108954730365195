import React, { useState } from "react";
import API from "../components/api2";
import InputForm from "../components/InputForm";
import { addServiceProvider } from "../urls";
import Button from "../components/Button";
import { circles } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const NewServiceProvider = () => {
  const [pageData, setPageData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  }

  // validation
  const validationRules = {
    service_provider_name: "service provider name",
    legal_name: "legal name",
    contact_person: "contact person",
    contact_email: "contact email",
    contact_no: "contact number",
    mailing_pin: "mailing pin",
    mailing_city: "mailing city",
    mailing_state: "mailing state",
    mailing_address: "mailing address",
  };
  const validateForm = () => {
    const newErrors = {};

    Object.entries(validationRules).forEach(([key, label]) => {
      if (!pageData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // on submit
  function onSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      const requestData = {
        ...pageData,
        platform: "1",
      };
      setLoading(true);
      API({
        ...addServiceProvider,
        body: requestData,
        onSuccess: (res) => {
          setLoading(false);
          Swal.fire({
            title: `${res.data.message}`,
          }).theb(() => {
            changePageFunc();
          });
        },

        onError: (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    }
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allServiceProviders",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />

      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto  py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-4 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add Service Provider
        </h2>

        {/* details  */}
        <div
          className="py-1 px-0 ms-0 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "270px",
            marginLeft: "-48px",
          }}
        >
          Service Provider Details
        </div>
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="container-fluid px-5 pb-5"
        >
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Service Provider Name"}
                id={"service_provider_name"}
                name="service_provider_name"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Legal Name"}
                id={"legal_name"}
                name="legal_name"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
        </div>

        {/* mailing address  */}
        <div
          className="py-1 px-0 ms-0 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "270px",
            marginLeft: "-48px",
          }}
        >
          Mailing Address
        </div>
        <div className="container-fluid px-5">
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Contact Person"}
                id={"contact_person"}
                name="contact_person"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"email"}
                placeholder={"Contact Email"}
                id={"contact_email"}
                name="contact_email"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Contact No"}
                id={"contact_no"}
                name="contact_no"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
                maxInputLength="10"
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"PIN"}
                id={"mailing_pin"}
                name="mailing_pin"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
                maxInputLength="6"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"City"}
                id={"mailing_city"}
                name="mailing_city"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"State"}
                id={"mailing_state"}
                name="mailing_state"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="mt-3">
            <InputForm
              type={"text"}
              placeholder={"Address"}
              id={"mailing_address"}
              name="mailing_address"
              valueState={pageData}
              onChange={pageDataFunc}
              errorState={errors}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-5 px-5">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button
            style={{
              opacity: loading ? 0.6 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewServiceProvider;

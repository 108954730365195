import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { circles } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";

const UserManagementInfo = () => {
  const location = useLocation();
  const { service } = location.state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // change page
  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allTcManagement",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Type Category Information
          </h2>
        </div>

        {/* Information  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mt-4 pb-3"
        >
          <div
            className="py-2 px-3 mt-4"
            style={{
              backgroundColor: "rgba(217, 217, 217, 1)",
              borderRadius: "0px 27.5px 27.5px 0px",
              fontSize: "18px",
              fontWeight: "700",
              fontFamily: "Montserrat",
              color: "rgba(173, 131, 82, 1)",
              width: "fit-content",
              marginLeft: "-12px",
            }}
          >
            Type & Category Details
          </div>

          <div className="row justify-content-between mt-4 px-5">
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Type
              </p>

              <input
                type="text"
                name="service_name"
                className="p-2 w-100 mt-1"
                value={service?.type ?? "N/A"}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
                readOnly
              />
            </div>
            <div className="col-6">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Category
              </p>
              <input
                type="text"
                name="service_name"
                className="p-2 w-100 mt-1"
                value={service?.category ?? "N/A"}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
                readOnly
              />
            </div>
            <div className="col-6 mt-4">
              <p
                className="mb-0"
                style={{
                  fontWeight: "600",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                Sub-Category
              </p>
              <input
                type="text"
                name="service_name"
                className="p-2 w-100 mt-1"
                value={service?.sub_category ?? "N/A"}
                style={{
                  backgroundColor: "rgba(255, 231, 159, 0.4)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                }}
                readOnly
              />
            </div>
          </div>
        </div>

        {/* Audit info  */}
        <div
          style={{ borderBottom: "1px solid rgba(173, 131, 82, 1)" }}
          className="mx-1 mt-4 pb-4"
        >
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              color: "#696666",
            }}
            className="mb-0 py-1 px-3 mx-1"
          >
            Audit Info
          </p>
          <div className="row mt-3 mx-4">
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Created By: {service?.created_by ?? "N/A"}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Created On: {service?.created_on ?? "N/A"}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Modified By: {service?.modified_by ?? "N/A"}
              </p>
            </div>
            <div className="col-3">
              <p
                className="mb-0"
                style={{
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  color: "rgba(105, 102, 102, 1)",
                }}
              >
                Modified On: {service?.modified_on ?? "N/A"}
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-itmes-center mt-5 px-4">
          <Button onClick={changePageFunc} children={"Back"} />
          {/* <Button children={"Update"} onClick={handleClickUpdate} /> */}
        </div>
      </div>
    </>
  );
};

export default UserManagementInfo;

import React, { useEffect, useState } from "react";
import { getMerchant, merchantApplicationDropdown } from "../urls";
import API from "../components/api2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { actionIcon, circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import Filter2 from "../components/Filter2";

const AllMerchantApplication = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 11 });
  const [dropdown, setDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const [active, setActive] = useState();
  const [inActive, setInActive] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchApplications({ initial: true });
    fetchApplicationsDropdown();
  }, []);

  async function fetchApplicationExcel({ filterValues = {} } = {}) {
    return await new Promise((resolve, reject) => {
      fetchApplications({
        filterValues: filterValues,
        resolve: resolve,
        reject: reject,
      });
    });
  }
  const fetchApplicationsDropdown = () => {
    API({
      ...merchantApplicationDropdown,
      onSuccess: (res) => {
        setDropdown(res.data.data);
      },
      onError: (error) => {
        console.error("Error fetching brands dropdown: ", error);
      },
    });
  };
  const fetchApplications = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }

    API({
      ...getMerchant,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve ? { include_excel: true } : {}),
          },
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          setActive(res.data.data.active_count);
          setInActive(res.data.data.inactive_count);
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [
                ...prev,
                ...res.data.data.table_data,
              ]);
            } else {
              setFilteredTable(res.data.data.table_data);
              setTotalRows(res.data.data.total_rows);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error("Error fetching merchant applications: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const changePageFunc = ({ clickedService, targetPage }) => {
    if (clickedService) {
      dispatch(
        changePage({
          page: "merchantAppInfo",
          service: clickedService,
        })
      );

      navigate("/", { state: { service: clickedService } });
    } else {
      dispatch(
        changePage({
          page: targetPage,
        })
      );
      navigate("/", {});
    }
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchApplications({ append: true });
    }
  }, [pagination]);
  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            All Applications
          </h2>
        </div>

        <Filter2
          getDataFunc={fetchApplications}
          excelFunc={fetchApplicationExcel}
          loading={loading}
          excelFileName={"Application Data"}
          filters={[
            {
              placeholder: "Merchant Name",
              id: "first_name",
              filterType: "select",
              inputType: null,
              options: dropdown?.first_names?.map((item) => ({
                value: item.first_name,
                label: item.first_name,
              })),
            },
            {
              placeholder: "Shop Name",
              id: "shop_area",
              filterType: "select",
              inputType: null,
              options: dropdown?.shop_areas?.map((item) => ({
                value: item.shop_area,
                label: item.shop_area,
              })),
            },

            {
              placeholder: "Business Area",
              id: "business_area",
              filterType: "input",
              inputType: "text",
              options: [],
            },
            {
              placeholder: "Status",
              id: "active_status",
              filterType: "select",
              inputType: null,
              options: [
                { value: "Approved", label: "Approved" },
                { value: "Rejected", label: "Rejected" },
                { value: "Send Back", label: "Send Back" },
                { value: "Pending", label: "Pending" },
              ],
            },
          ]}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              console.log("newMerchant");
              changePageFunc({ targetPage: "newMerchant" });
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD Application"}
            </p>
          </div>
        </Filter2>
        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() =>
                      changePageFunc({ clickedService: row.original })
                    }
                  />
                );
              },
            },
            { Header: "Application No.", accessor: "application_number" },
            { Header: "Merchant Name", accessor: "first_name" },
            {
              Header: "Contact No",
              accessor: "contact_no",
            },
            { Header: "Contact Email", accessor: "contact_email" },
            {
              Header: "Active Status",
              accessor: "active_status",
            },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
          totalRows={totalRows}
        />
      </div>
    </>
  );
};

export default AllMerchantApplication;

import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import API from "../components/api2";
import { changePassword } from "../urls";
import Swal from "sweetalert2";
import LoginNav from "../components/LoginNav";
import { useLocation, useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [pageData, setPageData] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [formFilled, setFormFilled] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { password } = location.state || {};

  const pageDataFunc = (event) => {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    // Check if all fields are filled
    const isFilled = Object.values(pageData).every((val) => val.trim() !== "");
    setFormFilled(isFilled);

    // Check if passwords match
    setPasswordsMatch(pageData.new_password === pageData.confirm_password);
  }, [pageData]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsVerifying(true);

    const { new_password, confirm_password } = pageData;

    API({
      ...changePassword,
      body: {
        new_password,
        confirm_password,
        old_password: password,
      },
      onSuccess: (res) => {
        setIsVerifying(false);
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        }).then(() => {
          navigate("/login");
        });
      },
      onError: (error) => {
        setIsVerifying(false);

        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  return (
    <section className="bg-white min-vh-100">
      <div className="px-4">
        <LoginNav />

        <div className="mt-5 col-6 mx-auto">
          <h2
            style={{
              fontWeight: "500",
              fontSize: "26px",
              textAlign: "center",
              letterSpacing: "3px",
              color: "rgba(79, 87, 91, 1)",
            }}
          >
            Change your password
          </h2>
          <form onSubmit={onSubmit} className="pt-4 text-start">
            <div className="d-flex flex-column w-100">
              <Input
                type="password"
                name="new_password"
                id="new_password"
                autoComplete="off"
                showLabel={false}
                placeholder="New Password"
                value={pageData.new_password}
                onChange={pageDataFunc}
                className="p-2 mt-4 w-100"
                showPassIcon2={true}
              />
              <Input
                type="password"
                name="confirm_password"
                id="confirm_password"
                autoComplete="off"
                placeholder="Confirm New Password"
                value={pageData.confirm_password}
                onChange={pageDataFunc}
                className="p-2 mt-4 w-100"
                showLabel={false}
                showPassIcon2={true}
              />
              {!passwordsMatch && (
                <p style={{ color: "red" }}>Passwords do not match</p>
              )}
              <div className="text-center">
                <button
                  type="submit"
                  className="border-0 text-white py-2 px-4 mt-4 col-6"
                  style={{
                    backgroundColor: "#194280",
                    borderRadius: "10px",
                    opacity:
                      formFilled && passwordsMatch && !isVerifying ? 1 : 0.5,
                  }}
                  disabled={!formFilled || !passwordsMatch || isVerifying}
                >
                  {isVerifying ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import Header from "../components/Header";
import { active, circles, inactive } from "../images";
import { resetPassword, getUser, activeUser } from "../urls";
import API from "../components/api2";
import Swal from "sweetalert2";
import Alert from "../components/Alert";

const AdminResetPage = () => {
  const [username, setUsername] = useState("");
  const [userData, setUserData] = useState([]);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [noUserFound, setNoUserFound] = useState(false);
  const [showAlertActive, setShowAlertActive] = useState(false);
  const [alertTextActive, setAlertTextActive] = useState("");
  const [walletColor, setWalletColor] = useState(false);
  const [loading, setLoading] = useState(false);

  // alert
  const hideAlert = () => {
    setShowAlertActive(false);
  };
  const showAlertWithTextActive = (text, walletColor) => {
    setAlertTextActive(text);
    setShowAlertActive(true);
    setWalletColor(walletColor);
  };

  const handleClickActive = () => {
    showAlertWithTextActive("Are you sure you want to", "green");
  };

  const isUsernameEntered = username.trim() !== "";

  const handleSearch = () => {
    if (username.trim() === "") {
      console.log("Please enter a username before searching.");
      return;
    }
    setIsSearchClicked(true);
    API({
      ...getUser,
      params: { username: username },
      onSuccess: (res) => {
        const userFromApi = res.data.data[0];
        if (userFromApi) {
          setUserData(userFromApi);
          setIsResetDisabled(false);
          setNoUserFound(false);
        } else {
          setNoUserFound(true);
          setUserData([]);
          setIsResetDisabled(true);
        }
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const resetPass = () => {
    setLoading(true);
    setIsSearchClicked(false);
    API({
      ...resetPassword,
      body: { username: username },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
        setIsResetDisabled(false);
      },
      onError: (error) => {
        setLoading(false);
        console.log("Error fetching user data:", error);
      },
    });
  };

  const { active_status } = userData;
  const initialIsActive = active_status !== "ACTIVE";

  const [isActive, setIsActive] = useState(initialIsActive);
  useEffect(() => {
    setIsActive(active_status !== "ACTIVE");
  }, [active_status]);

  // active inactive
  const handleConfirmActive = (actionText) => {
    const isActive = actionText === "Active" ? "ACTIVE" : "INACTIVE";
    API({
      ...activeUser,
      body: {
        username: username,
        user_status: isActive,
      },
      onSuccess: (res) => {
        Swal.fire({
          title: ` User has been successfully ${
            isActive === "INACTIVE" ? "Deactivate" : "Activate"
          } `,
        });
      },
      onError: (error) => {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
    setShowAlertActive(false);
  };
  const sliderContent = isActive ? "Inactive" : "Active";
  const imageSource = isActive ? inactive : active;

  const handleToggleSwitch = () => {
    setIsActive((prev) => {
      return !prev;
    });
  };

  function formatTimestamp(timestamp) {
    if (!timestamp) return "";

    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    return new Date(timestamp).toLocaleString("en-GB", options);
  }

  return (
    <>
      <Header />
      <section className="overflow-y-auto overflow-x-hidden">
        <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-3 py-4 me-2 position-relative">
          <img
            style={{ position: "absolute", right: "0", top: "0" }}
            src={circles}
            alt="circles"
          />
          <h2
            className="pb-3 mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "22px",
              borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
            }}
          >
            Reset Password
          </h2>
          <div className="mt-5">
            <input
              type="text"
              id="username"
              autoComplete="off"
              placeholder="Username/Mobile No."
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{
                fontSize: "15px",
                width: "50%",
                padding: "6px 5px",
                borderRadius: "10px",
                backgroundColor: noUserFound
                  ? "white"
                  : "rgba(255, 231, 159, 0.4)",
                border: noUserFound ? "1.5px solid red" : "1.5px solid black",
              }}
            />
            <Button
              onClick={handleSearch}
              style={{
                marginLeft: "10px",
                opacity: isUsernameEntered ? 1 : 0.5,
                cursor: isUsernameEntered ? "pointer" : "no-drop",
              }}
              children={"Search"}
              disabled={!isUsernameEntered}
            />
          </div>
          {noUserFound && (
            <p className="mb-0 mt-1 ms-1" style={{ color: "red" }}>
              No user found with the given username.
            </p>
          )}

          {/* user data  */}
          <div className="mt-5">
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
                backgroundColor: "#FFF6D8",
                borderRadius: "10px",
              }}
              className="mb-0 py-1 px-3"
            >
              User Detail
            </p>
            <div className="row mt-3 mx-2 px-3">
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Username: {userData.username || "N/A"}
                </p>
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Email: {userData.registered_id || "N/A"}
                </p>
              </div>
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Last Disabled Date: {userData.last_disabled_date || "N/A"}
                </p>
              </div>
            </div>
            <div className="row mt-2 mx-2 px-3">
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Name: {userData.Name || "N/A"}
                </p>
              </div>
              <div className="col-6">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Mobile: {userData.registered_no || "N/A"}
                </p>
              </div>
              <div className="col-3">
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "13px",
                    color: "rgba(105, 102, 102, 1)",
                  }}
                  className="mb-0"
                >
                  Last Enabled Date: {userData.last_enabled_date || "N/A"}
                </p>
              </div>
            </div>
          </div>

          {/* user operation  */}
          <div className="mt-5">
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
                borderRadius: "10px",
              }}
              className="mb-0 py-1 px-3"
            >
              User Operation
            </p>
          </div>
        </div>

        <div style={{ borderBottom: "1.5px solid #AD8352" }}>
          <div className="d-flex mt-5 justify-content-between align-items-center pb-5 mx-5 px-5">
            <div
              className="d-flex align-items-center"
              onClick={handleClickActive}
            >
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isActive}
                  onChange={handleToggleSwitch}
                />
                <span
                  className="slider round"
                  data-content={sliderContent}
                  style={{
                    color: isActive ? "#AAAEB0" : "#AAAEB0",
                    fontWeight: "500",
                    paddingLeft: isActive ? "80px" : "10px",
                    paddingTop: "2px",
                  }}
                >
                  {isActive ? "Active" : "Inactive"}
                </span>
              </label>
              <img
                className="ms-2 toggleUser"
                src={imageSource}
                alt={isActive ? "Inactive" : "Active"}
                style={{
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  pointerEvents: "all",
                }}
              />
            </div>
            <Alert
              show={showAlertActive}
              onHide={hideAlert}
              title="Confirmation"
              actionText={isActive ? "deactivate" : "activate"}
              message={alertTextActive}
              term="System User"
              forTerm=" "
              walletColor={isActive ? "red" : "green"}
              onConfirm={() =>
                handleConfirmActive(isActive ? "Inactive" : "Active")
              }
            />
            <Button
              style={{
                opacity: isSearchClicked && !noUserFound && !loading ? 1 : 0.5,
                cursor:
                  isSearchClicked && !noUserFound && !loading
                    ? "pointer"
                    : "no-drop",
              }}
              children={loading ? "Please Wait..." : "Reset Password"}
              disabled={!isUsernameEntered || noUserFound || loading}
              onClick={resetPass}
            />
          </div>
        </div>

        {/* audit info  */}
        <div style={{ backgroundColor: "white" }} className="px-4 pb-5 pt-5">
          <p
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
            className="mb-0"
          >
            Audit Info
          </p>
          <div className="row mt-3 px-3">
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Created By: {userData.created_by || "N/A"}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Created On: {formatTimestamp(userData.created_on)}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Modified By: {userData.updated_by || "N/A"}
              </p>
            </div>
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                }}
                className="mb-0"
              >
                Modified On: {""}
                {formatTimestamp(userData.updated_on)}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminResetPage;

import React, { useState } from "react";
import API from "../../components/api2";
import { postDesignation } from "../../urls";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { circles } from "../../images";
import InputForm from "../../components/InputForm";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePage } from "../../redux/slice/dashboardSlice";
import loaderGif from "../../gif/buffer.gif";

const NewDesignation = () => {
  const [designationName, setDesignationName] = useState("");
  const [designationId, setDesignationId] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "manageDesignation",
      })
    );
    navigate("/");
  };

  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);

    API({
      ...postDesignation,
      body: {
        designation_id: designationId,
        designation_name: designationName,
      },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `Designation ${designationName} added successfully`,
        }).then(() => {
          changePageFunc();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }

  const isFormIncomplete = !designationId || !designationName;

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-5 py-4 me-2 position-relative">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <form onSubmit={onSubmit} className="text-start">
          <h2
            className="pb-3 mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
            }}
          >
            Add Designation
          </h2>
          <div className="mt-4 col-6">
            <InputForm
              type="number"
              id="designation_id"
              name="designation_id"
              placeholder="Designation ID"
              value={designationId}
              onChange={(event) => setDesignationId(event.target.value)}
            />
            <InputForm
              type="text"
              id="designation_name"
              name="designation_name"
              placeholder="Designation Name"
              value={designationName}
              onChange={(event) => setDesignationName(event.target.value)}
            />
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-between col-6">
            <Button onClick={changePageFunc}>Back</Button>
            <Button
              type="submit"
              disabled={isFormIncomplete || loading}
              style={{ opacity: isFormIncomplete || loading ? 0.5 : 1 }}
            >
              {loading ? (
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={loaderGif}
                  alt="Loading..."
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewDesignation;

import React, { useEffect, useState } from "react";
import { getMyNetwork, merchNetworkDropdown } from "../urls";
import API from "../components/api2";
import { actionIcon, circles } from "../images";
import PaginationTable from "../components/PaginationTable";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import Filter2 from "../components/Filter2";

const MyNetwork = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [merchDropdown, setMerchDropdown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchMyNetwork({ initial: true });
  }, []);

  async function fetchApplicationExcel({ filterValues = {} } = {}) {
    return await new Promise((resolve, reject) => {
      fetchMyNetwork({
        filterValues: filterValues,
        resolve: resolve,
        reject: reject,
      });
    });
  }

  const fetchMyNetwork = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }
    API({
      ...getMyNetwork,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          // setActive(res.data.data.active_count);
          // setInActive(res.data.data.inactive_count);
        } else {
          if (resolve) {
            resolve(res.data.data);
          } else {
            const tableData = Array.isArray(res.data.data.table_data)
              ? res.data.data.table_data
              : [res.data.data];
            if (append) {
              setFilteredTable((prev) => [...prev, ...tableData]);
            } else {
              setFilteredTable(tableData);
              setTotalRows(res.data.data.total_rows);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error("Error fetching network: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const changePageFunc = ({ clickedService, targetPage }) => {
    if (clickedService) {
      dispatch(
        changePage({
          page: "myNetworkInfo",
          service: clickedService,
        })
      );

      navigate("/", { state: { service: clickedService } });
    } else {
      dispatch(
        changePage({
          page: targetPage,
        })
      );
      navigate("/", {});
    }
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchMyNetwork({ append: true });
    }
  }, [pagination]);

  // merchant dropdown
  useEffect(() => {
    API({
      ...merchNetworkDropdown,
      onSuccess: (res) => {
        setMerchDropdown(res?.data?.data);
      },
      onError: (error) => {
        console.error("Error fetching merchant data: ", error);
      },
    });
  }, []);

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            My Network
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchMyNetwork}
          excelFunc={fetchApplicationExcel}
          forPage="My Network"
          loading={loading}
          excelFileName={"My Network"}
          filters={[
            {
              placeholder: "Merchant ID",
              id: "merchant_id",
              filterType: "select",
              inputType: null,
              options: merchDropdown?.map((item) => ({
                value: item.merchant_id,
                label: item.merchant_id,
              })),
            },
          ]}
        ></Filter2>

        <PaginationTable
          columns={[
            {
              Header: "Action",
              Cell: ({ row }) => {
                return (
                  <img
                    src={actionIcon}
                    alt="view ICON"
                    style={{ cursor: "pointer", pointerEvents: "all" }}
                    onClick={() =>
                      changePageFunc({ clickedService: row.original })
                    }
                  />
                );
              },
            },
            { Header: "Merchant ID", accessor: "merchant_id" },
            { Header: "Brand ID", accessor: "brand_id" },
            { Header: "Merchant Type", accessor: "merchant_type" },
            { Header: "Merchant Name", accessor: "first_name" },
            {
              Header: "Super Distributor ID",
              accessor: "regional_distributor_id",
            },
            {
              Header: "Master Distributor ID",
              accessor: "area_distributor_id",
            },
            {
              Header: "Distributor ID",
              accessor: "local_distributor_id",
            },
            { Header: "Wallet Status", accessor: "wallet_status" },
            {
              Header: "Main Closing Balance",
              accessor: "main_closing_balance",
            },
          ]}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
          totalRows={totalRows}
        />
      </div>
    </>
  );
};

export default MyNetwork;

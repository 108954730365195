import React, { useState } from "react";
import API from "../components/api2";
import { addBusinessBrand } from "../urls";
import Button from "../components/Button";
import Header from "../components/Header";
import { circles } from "../images";
import InputForm from "../components/InputForm";
import Swal from "sweetalert2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const NewBusinessBrand = () => {
  const { t } = useTranslation();

  const [pageData, setPageData] = useState({
    allow_create_business_scheme: "NO",
    allow_making_commission_structure: "NO",
  });
  const [selectedFiles, setSelectedFiles] = useState(
    initializeSelectedFiles([
      "gstn_certificate",
      "cinn_certificate",
      "pan_docs",
      "tan_docs",
      "company_logo",
    ])
  );

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function initializeSelectedFiles(fields) {
    const initialState = {};
    fields.forEach((field) => {
      initialState[field] = [];
    });
    return initialState;
  }

  const handleFileChange = (event, type) => {
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [type]: Array.from(event.target.files),
    }));
  };

  function pageDataFunc(event) {
    if (event.target) {
      const { id, type } = event.target;
      let newValue;

      if (type === "checkbox") {
        newValue = event.target.checked ? "YES" : "NO";
      } else {
        newValue = event.target.value;
      }

      setPageData((prev) => ({ ...prev, [id]: newValue }));
    }
  }

  const validationRules = {
    business_name: "Brand name",
    legal_name: "Legal name",
    cinn_llpin: "CIN",
    gst_number: "GST No",
    pan_number: "PAN No",
    tan_number: "TAN No",
    contact_person: "contact person",
    contact_email: "contact email",
    contact_no: "contact no",
    mailing_pin: "PIN",
    mailing_city: "Mailing city",
    mailing_state: "Mailing state",
    mailing_address: "Mailing address",
    kyc_contact_person: "KYC contact person",
    kyc_contact_email: "KYC contact email",
    kyc_contact_no: "KYC contact no",
    kyc_mailing_pin: "KYC PIN",
    kyc_mailing_city: "KYC city",
    kyc_mailing_state: "KYC state",
    kyc_mailing_address: "KYC address",
  };

  const validateForm = () => {
    const newErrors = {};

    Object.entries(validationRules).forEach(([key, label]) => {
      if (!pageData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function onSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();

      Object.entries(pageData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      Object.entries(selectedFiles).forEach(([key, files]) => {
        if (files.length > 0) {
          formData.append(key, files[0]);
        }
      });

      API({
        ...addBusinessBrand,
        body: formData,
        isFormData: true,
        onSuccess: (res) => {
          setLoading(false);
          Swal.fire({
            title: `${res.data.message}`,
          }).then(() => {
            changePageFunc();
          });
        },
        onError: (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    }
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "updateBusinessBrand",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />
      <div className="bg-worker my-2 mx-auto px-5 py-4 overflow-y-scroll w-100 flex-grow-1 me-2 position-relative">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <form onSubmit={onSubmit} className="text-start ">
          <h2
            className="pb-4 mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
            }}
          >
            Add New Brand
          </h2>

          {/* Information */}
          <div
            className="py-1 ps-3"
            style={{
              backgroundColor: "rgba(217, 217, 217, 1)",
              borderRadius: "0px 27.5px 27.5px 0px",
              fontSize: "18px",
              fontWeight: "700",
              fontFamily: "Montserrat",
              color: "rgba(173, 131, 82, 1)",
              width: "270px",
              marginLeft: "-48px",
              marginTop: "-2px",
            }}
          >
            Business Information
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Brand Name"}
                id={"business_name"}
                name="business_name"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Legal Name"}
                id={"legal_name"}
                name="legal_name"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"CIN"}
                id={"cinn_llpin"}
                name="cinn_llpin"
                valueState={pageData}
                onChange={pageDataFunc}
                maxInputLength="21"
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"GST"}
                id={"gst_number"}
                name="gst_number"
                maxInputLength="15"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"PAN No"}
                id={"pan_number"}
                name="pan_number"
                maxInputLength="10"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"TAN No"}
                id={"tan_number"}
                name="tan_number"
                maxInputLength="10"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>

          {/* Mailing Address  */}
          <div
            className="mt-5"
            style={{ borderTop: "1.5px solid rgba(217, 217, 217, 1)" }}
          >
            <div
              className="py-1 px-0 ps-3"
              style={{
                backgroundColor: "rgba(217, 217, 217, 1)",
                borderRadius: "0px 27.5px 27.5px 0px",
                fontSize: "18px",
                fontWeight: "700",
                fontFamily: "Montserrat",
                color: "rgba(173, 131, 82, 1)",
                width: "270px",
                marginLeft: "-48px",
                marginTop: "-2px",
              }}
            >
              Business Mailing Address
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Contact Person"}
                id={"contact_person"}
                name="contact_person"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"email"}
                placeholder={"Contact Email"}
                id={"contact_email"}
                name="contact_email"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Contact No"}
                id={"contact_no"}
                name="contact_no"
                maxInputLength="10"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"PIN"}
                id={"mailing_pin"}
                name="mailing_pin"
                maxInputLength="6"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"City"}
                id={"mailing_city"}
                name="mailing_city"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"State"}
                id={"mailing_state"}
                name="mailing_state"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>

          <InputForm
            type={"text"}
            placeholder={"Address"}
            id={"mailing_address"}
            name="mailing_address"
            valueState={pageData}
            onChange={pageDataFunc}
            errorState={errors}
          />

          {/* Support Contact Details */}
          <div
            className="mt-5"
            style={{ borderTop: "1.5px solid rgba(217, 217, 217, 1)" }}
          >
            <div
              className="py-1 px-0 mb-3 ps-3"
              style={{
                backgroundColor: "rgba(217, 217, 217, 1)",
                borderRadius: "0px 27.5px 27.5px 0px",
                fontSize: "18px",
                fontWeight: "700",
                fontFamily: "Montserrat",
                color: "rgba(173, 131, 82, 1)",
                width: "270px",
                marginLeft: "-48px",
                marginTop: "-2px",
              }}
            >
              Support Contact Details
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Contact Person"}
                id={"kyc_contact_person"}
                name="kyc_contact_person"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"email"}
                placeholder={"Contact Email"}
                id={"kyc_contact_email"}
                name="kyc_contact_email"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"Contact No"}
                id={"kyc_contact_no"}
                name="kyc_contact_no"
                maxInputLength="10"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"number"}
                placeholder={"PIN"}
                id={"kyc_mailing_pin"}
                name="kyc_mailing_pin"
                maxInputLength="6"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"City"}
                id={"kyc_mailing_city"}
                name="kyc_mailing_city"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"State"}
                id={"kyc_mailing_state"}
                name="kyc_mailing_state"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>

          <InputForm
            type={"text"}
            placeholder={"Address"}
            id={"kyc_mailing_address"}
            name="kyc_mailing_address"
            valueState={pageData}
            onChange={pageDataFunc}
            errorState={errors}
          />

          {/* Miscellaneous  */}
          <div
            className="mt-5"
            style={{ borderTop: "1.5px solid rgba(217, 217, 217, 1)" }}
          >
            <div
              className="py-1 px-0 ps-3"
              style={{
                backgroundColor: "rgba(217, 217, 217, 1)",
                borderRadius: "0px 27.5px 27.5px 0px",
                fontSize: "18px",
                fontWeight: "700",
                fontFamily: "Montserrat",
                color: "rgba(173, 131, 82, 1)",
                width: "270px",
                marginLeft: "-48px",
                marginTop: "-2px",
              }}
            >
              Miscellaneous
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between px-4">
            <div className="d-flex align-items-center p-0">
              <input
                type="checkbox"
                id="allow_create_business_scheme"
                name="allow_create_business_scheme"
                checked={pageData.allow_create_business_scheme === "YES"}
                onChange={pageDataFunc}
                style={{ backgroundColor: "white", marginTop: "32px" }}
              />
              <label
                htmlFor="allow_create_business_scheme"
                style={{ color: "#122158", marginTop: "32px" }}
                className="mb-0"
              >
                Allow to create own Business Scheme
              </label>
            </div>
            <div className="d-flex align-items-center p-0">
              <input
                type={"checkbox"}
                id={"allow_making_commission_structure"}
                name="allow_making_commission_structure"
                checked={pageData.allow_making_commission_structure === "YES"}
                valueState={pageData}
                style={{ backgroundColor: "white", marginTop: "32px" }}
                onChange={(e) => pageDataFunc(e)}
              />
              <label
                htmlFor="allow_making_commission_structure"
                style={{ color: "#122158", marginTop: "32px" }}
                className="mb-0"
              >
                Allow to create own Commission Structure
              </label>
            </div>
          </div>

          {/* File Uploads */}
          <div
            className="py-1 px-0 ps-3 mt-5"
            style={{
              backgroundColor: "rgba(217, 217, 217, 1)",
              borderRadius: "0px 27.5px 27.5px 0px",
              fontSize: "18px",
              fontWeight: "700",
              fontFamily: "Montserrat",
              color: "rgba(173, 131, 82, 1)",
              width: "270px",
              marginLeft: "-48px",
              marginTop: "-2px",
            }}
          >
            Attachments
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <input
                type="file"
                name="gstn_certificate"
                id="gstn_certificate"
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, "gstn_certificate")}
              />
              <label
                className="p-2 w-75 custom-upload-btn"
                style={{
                  border: "1px solid rgba(105, 102, 102, 1)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                  cursor: "pointer",
                }}
                htmlFor="gstn_certificate"
              >
                {selectedFiles["gstn_certificate"] &&
                selectedFiles["gstn_certificate"].length > 0 ? (
                  selectedFiles["gstn_certificate"]
                    .map((file) => file.name)
                    .join(", ")
                ) : (
                  <span>Upload GSTN Certificate</span>
                )}
              </label>
            </div>
            <div className="col-6">
              <input
                type="file"
                id="cinn_certificate"
                name="cinn_certificate"
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, "cinn_certificate")}
              />
              <label
                className="p-2 w-75 custom-upload-btn"
                style={{
                  border: "1px solid rgba(105, 102, 102, 1)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                  cursor: "pointer",
                }}
                htmlFor="cinn_certificate"
              >
                {selectedFiles["cinn_certificate"] &&
                selectedFiles["cinn_certificate"].length > 0 ? (
                  selectedFiles["cinn_certificate"]
                    .map((file) => file.name)
                    .join(", ")
                ) : (
                  <span>Upload CIN Certificate</span>
                )}
              </label>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <input
                type="file"
                id="pan_docs"
                name="pan_docs"
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, "pan_docs")}
              />
              <label
                className="p-2 w-75 custom-upload-btn"
                style={{
                  border: "1px solid rgba(105, 102, 102, 1)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                  cursor: "pointer",
                }}
                htmlFor="pan_docs"
              >
                {selectedFiles["pan_docs"] &&
                selectedFiles["pan_docs"].length > 0 ? (
                  selectedFiles["pan_docs"].map((file) => file.name).join(", ")
                ) : (
                  <span>Upload PAN Certificate</span>
                )}
              </label>
            </div>
            <div className="col-6">
              <input
                type="file"
                id="tan_docs"
                name="tan_docs"
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, "tan_docs")}
              />
              <label
                className="p-2 w-75 custom-upload-btn"
                style={{
                  border: "1px solid rgba(105, 102, 102, 1)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                  cursor: "pointer",
                }}
                htmlFor="tan_docs"
              >
                {selectedFiles["tan_docs"] &&
                selectedFiles["tan_docs"].length > 0 ? (
                  selectedFiles["tan_docs"].map((file) => file.name).join(", ")
                ) : (
                  <span>Upload TAN Certificate</span>
                )}
              </label>
            </div>
            <div className="col-6 mt-4">
              <input
                type="file"
                id="company_logo"
                name="company_logo"
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, "company_logo")}
              />
              <label
                className="p-2 w-75 custom-upload-btn"
                style={{
                  border: "1px solid rgba(105, 102, 102, 1)",
                  borderRadius: "10px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "rgba(105, 102, 102, 1)",
                  cursor: "pointer",
                }}
                htmlFor="company_logo"
              >
                {selectedFiles["company_logo"] &&
                selectedFiles["company_logo"].length > 0 ? (
                  selectedFiles["company_logo"]
                    .map((file) => file.name)
                    .join(", ")
                ) : (
                  <span>Upload Company Logo</span>
                )}
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className="mt-5 d-flex justify-content-between text-end">
            <Button onClick={changePageFunc} children={"Back"} type="submit" />
            <Button
              type="submit"
              disabled={loading}
              style={{
                opacity: loading ? 0.6 : 1,
                pointerEvents: loading ? "none" : "auto",
              }}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewBusinessBrand;

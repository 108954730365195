import React, { useEffect, useState } from "react";
import { validateUser } from "../urls";
import API from "../components/api2";
import Swal from "sweetalert2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { updateT } from "../redux/slice/userSlice";
import ResetNav from "../components/ResetNav";
import Input from "../components/Input";
import axios from "axios";

const ValidateUser = ({ duid, latitude, longitude }) => {
  const [username, setUsername] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const [ip, setIp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((res) => {
        setIp(res.data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    setIsVerifying(true);

    const formData = new FormData();
    formData.append("username", username);
    formData.append("source", "WEB");
    formData.append("duid", duid);
    formData.append("ip_address", ip);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);

    API({
      ...validateUser,
      body: formData,
      isFormData: true,
      onSuccess: (res) => {
        setIsVerifying(false);
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        });
        if (res.data.data.t) {
          dispatch(
            updateT({
              t: res.data.data.t,
            })
          );
        }
        changePageFunc();
      },
      onError: (error) => {
        setIsVerifying(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "verifyForgotMail",
      })
    );
    navigate("/", {
      state: {
        username,
      },
    });
  };

  const handleInputChange = (e) => {
    setUsername(e.target.value);
    setButtonDisabled(e.target.value === "");
  };

  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <section className="bg-white min-vh-100">
      <div className="px-4">
        <ResetNav />
        <form onSubmit={onSubmit} className="col-5 mx-auto mt-3 pt-5">
          <h2
            style={{
              fontWeight: "900",
              fontFamily: "Montserrat",
              fontSize: "24px",
              letterSpacing: "3px",
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            Forgot <br /> Password?
          </h2>
          <div>
            <Input
              type="text"
              name="username"
              id="username"
              autoComplete="off"
              placeholder="Enter your username"
              value={username}
              onChange={handleInputChange}
              className="p-2 w-100"
              showIcon={true}
              showLabel={false}
            />
          </div>
          <div className="text-center mt-3">
            <button
              type="submit"
              className="border-0 text-white py-2 px-4 mt-4 col-6"
              style={{
                backgroundColor: "#194280",
                borderRadius: "10px",
                opacity: buttonDisabled || isVerifying ? 0.5 : 1,
              }}
              disabled={buttonDisabled || isVerifying}
            >
              {isVerifying ? "Verifying..." : "Verify"}{" "}
            </button>
            <p
              onClick={handleCancel}
              style={{
                fontWeight: "400",
                fontSize: "17px",
                color: "rgba(26, 25, 24, 1)",
                letterSpacing: "1px",
                cursor: "pointer",
              }}
              className="mb-0 mt-2"
            >
              Cancel
            </p>
          </div>
        </form>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  duid: state.user.duid,
});

export default connect(mapStateToProps)(ValidateUser);

import React, { useEffect, useState } from "react";
import API from "../components/api2";
import { addMerchant, getMerchant } from "../urls";
import Button from "../components/Button";
import InputForm from "../components/InputForm";
import Select from "react-select";
import Payment from "../components/Payment";
import Swal from "sweetalert2";
import loaderGif from "../gif/buffer.gif";

const NewMerchantForm = ({ contact_no, contact_email }) => {
  const [pageData, setPageData] = useState({
    contact_no: contact_no,
    contact_email: contact_email || "",
    contact_email: contact_email || "",
    gst_number: "",
  });
  const [selectedFileName, setSelectedFileName] = useState({});
  const [step, setStep] = useState(1);
  const [showPayment, setShowPayment] = useState(false);
  const [appId, setAppId] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(
    initializeSelectedFiles([
      "gst_doc_url",
      "photo_doc_url",
      "pan_doc_url",
      "shop_photo_url",
      "aadhar_doc",
    ])
  );
  const [loader, setLoader] = useState(false);
  const [sameAsPermanent, setSameAsPermanent] = useState(false);

  const handleCheckboxChange = () => {
    setSameAsPermanent(!sameAsPermanent);
    if (!sameAsPermanent) {
      setPageData({
        ...pageData,
        kyc_mailing_address: pageData.residential_mailing_address,
        kyc_mailing_city: pageData.residential_mailing_city,
        kyc_mailing_state: pageData.residential_mailing_state,
        kyc_mailing_pin: pageData.residential_mailing_pin,
      });
    } else {
      setPageData({
        ...pageData,
        kyc_mailing_address: "",
        kyc_mailing_city: "",
        kyc_mailing_state: "",
        kyc_mailing_pin: "",
      });
    }
  };

  const [genderOptions] = useState([
    { value: "MALE", label: "MALE" },
    { value: "FEMALE", label: "FEMALE" },
    { value: "TRANSGENDER", label: "TRANSGENDER" },
  ]);
  const [eduOptions] = useState([
    { value: "10th", label: "10th" },
    { value: "12th", label: "12th" },
    { value: "graduate", label: "Graduate" },
    { value: "post_graduate", label: "Post Graduate" },
    { value: "phd", label: "PH.D" },
  ]);
  const [stateOptions] = useState([
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
    {
      value: "Andaman and Nicobar Islands",
      label: "Andaman and Nicobar Islands",
    },
    { value: "Chandigarh", label: "Chandigarh" },
    {
      value: "Dadra and Nagar Haveli and Daman and Diu",
      label: "Dadra and Nagar Haveli and Daman and Diu",
    },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Delhi", label: "Delhi" },
    { value: "Puducherry", label: "Puducherry" },
  ]);

  const changePageFunc = () => {
    window.location.reload();
  };

  function pageDataFunc(event) {
    if (event.target) {
      const { id, type } = event.target;
      let newValue;

      if (type === "checkbox") {
        newValue = event.target.checked;
      } else if (type === "file") {
        const file = event.target.files[0];
        const fileName = file ? file.name : "";
        const inputId = event.target.id;

        setSelectedFileName((prev) => ({
          ...prev,
          [inputId]: fileName,
        }));

        newValue = file;
      } else {
        newValue = event.target.value;
      }

      const updatedPageData = {
        ...pageData,
        [id]: newValue,
      };
      setPageData(updatedPageData);
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    setLoader(true);
    const formData = new FormData();

    Object.entries(pageData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    Object.entries(selectedFiles).forEach(([key, files]) => {
      if (files.length > 0) {
        formData.append(key, files[0]);
      }
    });
    API({
      ...addMerchant,
      body: formData,
      isFormData: true,
      onSuccess: (res) => {
        setLoader(false);
        setShowPayment(true);
        const fetchedAppId = res.data.data.application_number;
        setAppId(fetchedAppId);
      },

      onError: (error) => {
        setLoader(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }

  const handleFileChange = (event, type) => {
    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [type]: Array.from(event.target.files),
    }));
  };
  function initializeSelectedFiles(fields) {
    const initialState = {};
    fields.forEach((field) => {
      initialState[field] = [];
    });
    return initialState;
  }

  // date
  const todayDate = new Date();
  const eighteenYearsAgo = new Date(
    todayDate.getFullYear() - 18,
    todayDate.getMonth(),
    todayDate.getDate()
  )
    .toISOString()
    .split("T")[0];

  return (
    <>
      {loader ? (
        <img
          className="position-absolute"
          style={{
            height: "70px",
            width: "70px",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src={loaderGif}
          alt="Loading..."
        />
      ) : (
        <form onSubmit={onSubmit} className="text-start mt-4 ">
          {step === 1 && (
            <>
              {/* Information */}
              <div
                className="py-1 ps-3"
                style={{
                  backgroundColor: "rgba(217, 217, 217, 1)",
                  borderRadius: "0px 27.5px 27.5px 0px",
                  fontSize: "18px",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                  color: "rgba(173, 131, 82, 1)",
                  width: "270px",
                  marginLeft: "-48px  ",
                  marginTop: "-2px",
                }}
              >
                KYC Details
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <InputForm
                    type={"text"}
                    placeholder={"PAN"}
                    id={"pan_number"}
                    name="pan_number"
                    valueState={pageData}
                    onChange={pageDataFunc}
                    maxInputLength="10"
                    showAsterisk={true}
                    required
                  />
                </div>
                <div className="col-6">
                  <InputForm
                    type={"number"}
                    placeholder={"AADHAR"}
                    id={"aadhar_number"}
                    name="aadhar_number"
                    valueState={pageData}
                    onChange={pageDataFunc}
                    maxInputLength="12"
                    showAsterisk={true}
                    required
                  />
                </div>
                <div className="col-6">
                  <InputForm
                    type={"text"}
                    placeholder={"GSTIN"}
                    id={"gst_number"}
                    name="gst_number"
                    valueState={pageData}
                    onChange={pageDataFunc}
                    maxInputLength="15"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <Button onClick={changePageFunc} children={"Back"} />
                <Button
                  style={{
                    opacity:
                      !pageData.pan_number || !pageData.aadhar_number
                        ? "0.5"
                        : "1",
                    cursor:
                      !pageData.pan_number || !pageData.aadhar_number
                        ? "not-allowed"
                        : "pointer",
                  }}
                  disabled={!pageData.pan_number || !pageData.aadhar_number}
                  onClick={() => setStep(2)}
                >
                  Next
                </Button>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div>
                {/* Personal Details  */}
                <div>
                  <div
                    className="py-1 px-0 ps-3"
                    style={{
                      backgroundColor: "rgba(217, 217, 217, 1)",
                      borderRadius: "0px 27.5px 27.5px 0px",
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                      color: "rgba(173, 131, 82, 1)",
                      width: "270px",
                      marginLeft: "-48px",
                      marginTop: "-2px",
                    }}
                  >
                    Personal Details
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"First Name"}
                      id={"first_name"}
                      name="first_name"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      showAsterisk={true}
                      required
                    />
                  </div>
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"Middle Name"}
                      id={"middle_name"}
                      name="middle_name"
                      valueState={pageData}
                      onChange={pageDataFunc}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"Last Name"}
                      id={"last_name"}
                      name="last_name"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      showAsterisk={true}
                      required
                    />
                  </div>
                  <div className="col-6">
                    <label
                      style={{
                        marginTop: "8px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="gender"
                      className="form-label"
                    >
                      Gender
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      options={genderOptions}
                      placeholder="Select Gender"
                      id={"gender"}
                      name={"gender"}
                      valueState={pageData["gender"]}
                      onChange={(selectedOption) =>
                        setPageData({
                          ...pageData,
                          gender: selectedOption.value,
                        })
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "rgba(255, 231, 159,0.4)",
                          borderRadius: "10px",
                          border: "none",
                          boxShadow: "none",
                          "&:focus": {
                            outline: "none",
                          },
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <p
                      className="mb-0"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                        marginTop: "16px",
                      }}
                    >
                      Date of Birth
                      <span style={{ color: "red" }}> *</span>
                    </p>
                    <input
                      type="date"
                      id="age"
                      name="age"
                      className="p-2 w-100 mt-1"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      max={eighteenYearsAgo}
                      style={{
                        backgroundColor: "rgba(255, 231, 159, 0.4)",
                        borderRadius: "10px",
                        fontFamily: "Montserrat",
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label
                      style={{
                        marginTop: "8px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="education"
                      className="form-label"
                    >
                      Education
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      options={eduOptions}
                      placeholder="Select Education"
                      id={"education"}
                      name={"education"}
                      valueState={pageData["education"]}
                      onChange={(selectedOption) =>
                        setPageData({
                          ...pageData,
                          education: selectedOption.value,
                        })
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "rgba(255, 231, 159,0.4)",
                          borderRadius: "10px",
                          border: "none",
                          boxShadow: "none",
                          "&:focus": {
                            outline: "none",
                          },
                        }),
                      }}
                    />
                  </div>
                </div>

                {/* Mailing Address */}
                <div className="mt-5">
                  <div
                    className="py-1 px-0 mb-3 ps-3"
                    style={{
                      backgroundColor: "rgba(217, 217, 217, 1)",
                      borderRadius: "0px 27.5px 27.5px 0px",
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                      color: "rgba(173, 131, 82, 1)",
                      width: "270px",
                      marginLeft: "-48px",
                      marginTop: "-2px",
                    }}
                  >
                    Mailing Address
                  </div>
                </div>
                <InputForm
                  type={"text"}
                  placeholder={"Address"}
                  id={"residential_mailing_address"}
                  name="residential_mailing_address"
                  valueState={pageData}
                  onChange={pageDataFunc}
                  required
                  showAsterisk={true}
                />

                <div className="row">
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"City"}
                      id={"residential_mailing_city"}
                      name="residential_mailing_city"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      required
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-6">
                    <label
                      style={{
                        marginTop: "8px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="residential_mailing_state"
                      className="form-label"
                    >
                      State
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      options={stateOptions}
                      placeholder="Select State"
                      id={"residential_mailing_state"}
                      name={"residential_mailing_state"}
                      value={{
                        value: pageData["residential_mailing_state"],
                        label: stateOptions.find(
                          (option) =>
                            option.value ===
                            pageData["residential_mailing_state"]
                        )?.label,
                      }}
                      // valueState={pageData["residential_mailing_state"]}
                      onChange={(selectedOption) =>
                        setPageData({
                          ...pageData,
                          residential_mailing_state: selectedOption.value,
                        })
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "rgba(255, 231, 159,0.4)",
                          borderRadius: "10px",
                          border: "none",
                          boxShadow: "none",
                          "&:focus": {
                            outline: "none",
                          },
                        }),
                      }}
                    />
                  </div>

                  <div className="col-6">
                    <InputForm
                      type={"number"}
                      placeholder={"PIN"}
                      id={"residential_mailing_pin"}
                      name="residential_mailing_pin"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      maxInputLength="6"
                      required
                      showAsterisk={true}
                    />
                  </div>
                </div>

                {/* Permanent Address */}
                <div className="mt-5 d-flex align-items-center justify-content-between">
                  <div
                    className="py-1 px-0 mb-3 ps-3"
                    style={{
                      backgroundColor: "rgba(217, 217, 217, 1)",
                      borderRadius: "0px 27.5px 27.5px 0px",
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                      color: "rgba(173, 131, 82, 1)",
                      width: "270px",
                      marginLeft: "-48px",
                      marginTop: "-2px",
                    }}
                  >
                    Permanent Address
                  </div>
                  <div style={{ marginTop: "-20px" }} className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="sameAsPermanentCheckbox"
                      checked={sameAsPermanent}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="sameAsPermanentCheckbox"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Same as Permanent Address
                    </label>
                  </div>
                </div>
                <InputForm
                  type={"text"}
                  placeholder={"Address"}
                  id={"kyc_mailing_address"}
                  name="kyc_mailing_address"
                  valueState={pageData}
                  onChange={pageDataFunc}
                  required
                  showAsterisk={true}
                />
                <div className="row">
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"City"}
                      id={"kyc_mailing_city"}
                      name="kyc_mailing_city"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      required
                      showAsterisk={true}
                    />
                  </div>

                  <div className="col-6">
                    <label
                      style={{
                        marginTop: "8px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="kyc_mailing_state"
                      className="form-label"
                    >
                      State
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      options={stateOptions}
                      placeholder="Select State"
                      id={"kyc_mailing_state"}
                      name={"kyc_mailing_state"}
                      value={{
                        value: pageData["kyc_mailing_state"],
                        label: stateOptions.find(
                          (option) =>
                            option.value === pageData["kyc_mailing_state"]
                        )?.label,
                      }}
                      // valueState={pageData["kyc_mailing_state"]}
                      onChange={(selectedOption) =>
                        setPageData({
                          ...pageData,
                          kyc_mailing_state: selectedOption.value,
                        })
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "rgba(255, 231, 159,0.4)",
                          borderRadius: "10px",
                          border: "none",
                          boxShadow: "none",
                          "&:focus": {
                            outline: "none",
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"PIN"}
                      id={"kyc_mailing_pin"}
                      name="kyc_mailing_pin"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      maxInputLength="6"
                      required
                      showAsterisk={true}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <Button onClick={() => setStep(1)} children={"Back"} />
                <Button
                  style={{
                    opacity:
                      !pageData.first_name ||
                      !pageData.last_name ||
                      !pageData.gender ||
                      !pageData.education ||
                      !pageData.residential_mailing_address ||
                      !pageData.residential_mailing_city ||
                      !pageData.residential_mailing_state ||
                      !pageData.residential_mailing_pin ||
                      !pageData.kyc_mailing_address ||
                      !pageData.kyc_mailing_city ||
                      !pageData.kyc_mailing_state ||
                      !pageData.kyc_mailing_pin ||
                      !pageData.age
                        ? "0.5"
                        : "1",
                    cursor:
                      !pageData.first_name ||
                      !pageData.last_name ||
                      !pageData.gender ||
                      !pageData.education ||
                      !pageData.residential_mailing_address ||
                      !pageData.residential_mailing_city ||
                      !pageData.residential_mailing_state ||
                      !pageData.residential_mailing_pin ||
                      !pageData.kyc_mailing_address ||
                      !pageData.kyc_mailing_city ||
                      !pageData.kyc_mailing_state ||
                      !pageData.kyc_mailing_pin ||
                      !pageData.age
                        ? "not-allowed"
                        : "pointer",
                  }}
                  disabled={
                    !pageData.first_name ||
                    !pageData.last_name ||
                    !pageData.gender ||
                    !pageData.education ||
                    !pageData.residential_mailing_address ||
                    !pageData.residential_mailing_city ||
                    !pageData.residential_mailing_state ||
                    !pageData.residential_mailing_pin ||
                    !pageData.kyc_mailing_address ||
                    !pageData.kyc_mailing_city ||
                    !pageData.kyc_mailing_state ||
                    !pageData.kyc_mailing_pin ||
                    !pageData.age
                  }
                  onClick={() => setStep(3)}
                >
                  Next
                </Button>
              </div>
            </>
          )}

          {step === 3 && (
            <>
              <div>
                {/* Business Details */}
                <div className="mt-1">
                  <div
                    className="py-1 px-0 mb-3 ps-3"
                    style={{
                      backgroundColor: "rgba(217, 217, 217, 1)",
                      borderRadius: "0px 27.5px 27.5px 0px",
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                      color: "rgba(173, 131, 82, 1)",
                      width: "270px",
                      marginLeft: "-48px",
                      marginTop: "-2px",
                    }}
                  >
                    Business Details
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"Business Name"}
                      id={"business_name"}
                      name="business_name"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      required
                    />
                  </div>
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"Business Area"}
                      id={"business_area"}
                      name="business_area"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"Shop Name"}
                      id={"shop_area"}
                      name="shop_area"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      required
                    />
                  </div>
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"Legal Name"}
                      id={"legal_name"}
                      name="legal_name"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      required
                    />
                  </div>
                </div>
                {/* Business Communication */}
                <div className="mt-5">
                  <div
                    className="py-1 px-0 mb-3 ps-3"
                    style={{
                      backgroundColor: "rgba(217, 217, 217, 1)",
                      borderRadius: "0px 27.5px 27.5px 0px",
                      fontSize: "18px",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                      color: "rgba(173, 131, 82, 1)",
                      width: "270px",
                      marginLeft: "-48px",
                      marginTop: "-2px",
                    }}
                  >
                    Business Communication
                  </div>
                </div>
                <InputForm
                  type={"text"}
                  placeholder={"Address"}
                  id={"business_mailing_address"}
                  name="business_mailing_address"
                  valueState={pageData}
                  onChange={pageDataFunc}
                  required
                  showAsterisk={true}
                />
                <div className="row">
                  <div className="col-6">
                    <InputForm
                      type={"text"}
                      placeholder={"City"}
                      id={"business_mailing_city"}
                      name="business_mailing_city"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      required
                      showAsterisk={true}
                    />
                  </div>
                  <div className="col-6">
                    <label
                      style={{
                        marginTop: "8px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="business_mailing_state"
                      className="form-label"
                    >
                      State
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      options={stateOptions}
                      placeholder="Select State"
                      id={"business_mailing_state"}
                      name={"business_mailing_state"}
                      valueState={pageData["business_mailing_state"]}
                      onChange={(selectedOption) =>
                        setPageData({
                          ...pageData,
                          business_mailing_state: selectedOption.value,
                        })
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "rgba(255, 231, 159,0.4)",
                          borderRadius: "10px",
                          border: "none",
                          boxShadow: "none",
                          "&:focus": {
                            outline: "none",
                          },
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputForm
                      type={"number"}
                      placeholder={"PIN"}
                      id={"business_mailing_pin"}
                      name="business_mailing_pin"
                      valueState={pageData}
                      onChange={pageDataFunc}
                      maxInputLength="6"
                      required
                      showAsterisk={true}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <Button onClick={() => setStep(2)} children={"Back"} />
                <Button
                  style={{
                    opacity:
                      !pageData.business_mailing_address ||
                      !pageData.business_mailing_city ||
                      !pageData.business_mailing_state ||
                      !pageData.business_mailing_pin
                        ? "0.5"
                        : "1",
                    cursor:
                      !pageData.business_mailing_address ||
                      !pageData.business_mailing_city ||
                      !pageData.business_mailing_state ||
                      !pageData.business_mailing_pin
                        ? "not-allowed"
                        : "pointer",
                  }}
                  disabled={
                    !pageData.business_mailing_address ||
                    !pageData.business_mailing_city ||
                    !pageData.business_mailing_state ||
                    !pageData.business_mailing_pin
                  }
                  onClick={() => setStep(4)}
                >
                  Next
                </Button>
              </div>
            </>
          )}

          {step === 4 && (
            <>
              {/* Attachments */}
              <div className="mt-2">
                <div
                  className="py-1 px-0 mb-3 ps-3"
                  style={{
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "0px 27.5px 27.5px 0px",
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                    color: "rgba(173, 131, 82, 1)",
                    width: "270px",
                    marginLeft: "-48px",
                    marginTop: "-2px",
                  }}
                >
                  Attachments
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <label
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="gst_doc_url"
                      className="form-label"
                    >
                      GSTN Certificate
                    </label>
                    <div className="d-flex align-items-center position-relative">
                      <input
                        type="file"
                        placeholder={"Upload GSTN Certificate"}
                        name="gst_doc_url"
                        id="gst_doc_url"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, "gst_doc_url")}
                      />
                      <label
                        className="p-2 w-75 custom-upload-btn"
                        style={{
                          border: "1.5px solid rgba(105, 102, 102, 1)",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "rgba(105, 102, 102, 1)",
                          cursor: "pointer",
                        }}
                        htmlFor="gst_doc_url"
                      >
                        {selectedFiles["gst_doc_url"] &&
                        selectedFiles["gst_doc_url"].length > 0 ? (
                          selectedFiles["gst_doc_url"]
                            .map((file) => file.name)
                            .join(", ")
                        ) : (
                          <span>Upload GSTN Certificate</span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <label
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="photo_doc_url"
                      className="form-label"
                    >
                      Photo
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="d-flex align-items-center position-relative">
                      <input
                        type="file"
                        name="photo_doc_url"
                        id="photo_doc_url"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, "photo_doc_url")}
                      />
                      <label
                        className="p-2 w-75 custom-upload-btn"
                        style={{
                          border: "1.5px solid rgba(105, 102, 102, 1)",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "rgba(105, 102, 102, 1)",
                          cursor: "pointer",
                        }}
                        htmlFor="photo_doc_url"
                      >
                        {selectedFiles["photo_doc_url"] &&
                        selectedFiles["photo_doc_url"].length > 0 ? (
                          selectedFiles["photo_doc_url"]
                            .map((file) => file.name)
                            .join(", ")
                        ) : (
                          <span>Upload Merchant Photo</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <label
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="pan_doc_url"
                      className="form-label"
                    >
                      PAN Document
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="d-flex align-items-center position-relative">
                      <input
                        type="file"
                        name="pan_doc_url"
                        id="pan_doc_url"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, "pan_doc_url")}
                      />
                      <label
                        className="p-2 w-75 custom-upload-btn"
                        style={{
                          border: "1.5px solid rgba(105, 102, 102, 1)",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "rgba(105, 102, 102, 1)",
                          cursor: "pointer",
                        }}
                        htmlFor="pan_doc_url"
                      >
                        {selectedFiles["pan_doc_url"] &&
                        selectedFiles["pan_doc_url"].length > 0 ? (
                          selectedFiles["pan_doc_url"]
                            .map((file) => file.name)
                            .join(", ")
                        ) : (
                          <span>Upload PAN Certificate</span>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <label
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="shop_photo_url"
                      className="form-label"
                    >
                      Shop Photo
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="d-flex align-items-center position-relative">
                      <input
                        type="file"
                        name="shop_photo_url"
                        id="shop_photo_url"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, "shop_photo_url")}
                      />
                      <label
                        className="p-2 w-75 custom-upload-btn"
                        style={{
                          border: "1.5px solid rgba(105, 102, 102, 1)",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "rgba(105, 102, 102, 1)",
                          cursor: "pointer",
                        }}
                        htmlFor="shop_photo_url"
                      >
                        {selectedFiles["shop_photo_url"] &&
                        selectedFiles["shop_photo_url"].length > 0 ? (
                          selectedFiles["shop_photo_url"]
                            .map((file) => file.name)
                            .join(", ")
                        ) : (
                          <span>Upload Shop Photo</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <label
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                      }}
                      htmlFor="aadhar_doc"
                      className="form-label"
                    >
                      Aadhar Document
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="d-flex align-items-center position-relative">
                      <input
                        type="file"
                        name="aadhar_doc"
                        id="aadhar_doc"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e, "aadhar_doc")}
                      />
                      <label
                        className="p-2 w-75 custom-upload-btn"
                        style={{
                          border: "1.5px solid rgba(105, 102, 102, 1)",
                          borderRadius: "10px",
                          fontFamily: "Montserrat",
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "rgba(105, 102, 102, 1)",
                          cursor: "pointer",
                        }}
                        htmlFor="aadhar_doc"
                      >
                        {selectedFiles["aadhar_doc"] &&
                        selectedFiles["aadhar_doc"].length > 0 ? (
                          selectedFiles["aadhar_doc"]
                            .map((file) => file.name)
                            .join(", ")
                        ) : (
                          <span>Upload Aadhar</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <Button onClick={() => setStep(3)} children={"Back"} />
                <Button children={"Submit"} />
              </div>
            </>
          )}
        </form>
      )}
      {showPayment && <Payment applicationType="MERCHANT" appId={appId} />}
    </>
  );
};

export default NewMerchantForm;

import React, { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill-table-ui/dist/index.css";
import QuillTableUI from "quill-table-ui";
import Swal from "sweetalert2";

Quill.register(
  {
    "modules/tableUI": QuillTableUI,
  },
  true
);

function TextEditor({
  initialContent = "",
  placeholder = "Write your description here...",
  maxCharacters = 5000,
  isFullScreen = false,
  onContentChange,
}) {
  const quillRef = useRef(null);
  const [currentCharacters, setCurrentCharacters] = useState(0);
  const [isContentSet, setIsContentSet] = useState(false);

  const handleFileUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

        if (!allowedTypes.includes(file.type)) {
          Swal.fire({
            icon: "error",
            title: "Invalid File Type",
            text: "Please upload a valid image type (JPEG, PNG, GIF).",
          });
          return;
        }

        if (file.size > 5 * 1024 * 1024) {
          Swal.fire({
            icon: "error",
            title: "File Size Exceeded",
            text: "The file exceeds the maximum size limit of 5MB.",
          });
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const quill = quillRef.current.__quill;
          const range = quill.getSelection(); // Get the current cursor position
          quill.insertEmbed(range.index, "image", e.target.result); // Insert image at the cursor position
          quill.setSelection(range.index + 1); // Move the cursor after the image
        };

        reader.readAsDataURL(file); // Convert the image file to a base64 URL
      }
    };
  };

  useEffect(() => {
    if (quillRef.current && !quillRef.current.__quill) {
      // handleFileUpload();
      const quill = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline"],
              [{ color: [] }, { background: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link", "image", "video", "table", "code-block"],
              [{ align: [] }],
              [{ reset: "Reset" }],
            ],
            handlers: {
              image: handleFileUpload,
              file: handleFileUpload,
              reset: () => {
                quill.setText("");
                quill.setSelection(0);
                setCurrentCharacters(0);
                if (onContentChange) {
                  onContentChange("");
                }
              },
            },
          },
          table: true,
          tableUI: true,
        },
        placeholder,
        clipboard: {
          matchVisual: false,
        },
      });

      quillRef.current.__quill = quill;

      quill.on("text-change", () => {
        const strippedText = quill.root.innerText.trim();
        const characters = strippedText.length;

        if (characters > maxCharacters) {
          quill.deleteText(maxCharacters, characters - maxCharacters);
          Swal.fire({
            icon: "warning",
            title: "Character Limit Exceeded",
            text: `You have reached the maximum character limit (${maxCharacters} characters).`,
          });
        }

        setCurrentCharacters(characters);

        if (onContentChange) {
          onContentChange(quill.root.innerHTML);
        }
      });
    }
  }, [maxCharacters, onContentChange, placeholder]);

  useEffect(() => {
    if (
      quillRef.current &&
      quillRef.current.__quill &&
      initialContent &&
      !isContentSet
    ) {
      const quill = quillRef.current.__quill;
      quill.clipboard.dangerouslyPasteHTML(initialContent);
      setIsContentSet(true);

      // Move the cursor to the end of the editor content
      quill.setSelection(initialContent.length);
    }
  }, [initialContent, isContentSet]);

  return (
    <>
      <div
        ref={quillRef}
        style={{
          backgroundColor: "#FFF6D8",
          borderRadius: "14px",
          minHeight: isFullScreen ? "257px" : "150px",
        }}
      ></div>
      <div style={{ fontSize: "14px", color: "red" }}>
        Character count: {currentCharacters}/{maxCharacters}
      </div>
    </>
  );
}

export default TextEditor;

import React, { useState } from "react";
import API from "../components/api2";
import { addMenus } from "../urls";
import Button from "../components/Button";
import Header from "../components/Header";
import { circles } from "../images";
import InputForm from "../components/InputForm";
import Swal from "sweetalert2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const NewMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [menuName, setMenuName] = useState("");
  const [loading, setLoading] = useState(false);

  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    API({
      ...addMenus,
      body: { menu_name: menuName },
      onSuccess: (res) => {
        setLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          changePageFunc();
        });
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "menuList",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />

      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-5 py-4 me-2 position-relative">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <form onSubmit={onSubmit} className="text-start">
          <h2
            className="pb-3 mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
            }}
          >
            Add Menu
          </h2>
          <div className="mt-4 col-6">
            <InputForm
              type={"text"}
              id={"menu_name"}
              name="menu_name"
              placeholder={"Menu Name"}
              value={menuName}
              onChange={(event) => setMenuName(event.target.value)}
            />
          </div>
          <div className="mt-4 d-flex justify-content-between col-6">
            <Button onClick={changePageFunc} children={"Back"} />
            <Button
              type="submit"
              disabled={loading}
              style={{
                opacity: loading ? 0.6 : 1,
                pointerEvents: loading ? "none" : "auto",
              }}
            >
              {loading ? "Loading..." : "Add"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewMenu;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Swal from "sweetalert2";
import API from "../components/api2";
import { backArrow, circles } from "../images";
import { myNetworkWallet } from "../urls";
import { changePage } from "../redux/slice/dashboardSlice";
import { connect, useDispatch } from "react-redux";
import loaderGif from "../gif/buffer.gif";

const MyNetworkInfo = ({ roles }) => {
  const location = useLocation();
  const service = location.state?.service || null;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasRole = (role) => roles.includes(role);

  const [message, setMessage] = useState("");
  const [rechargeAmount, setRechargeAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTransaction = (type) => {
    const amount = type === "withdraw" ? withdrawAmount : rechargeAmount;

    if (Number(amount) <= 0) {
      setMessage("Enter a valid amount.");
      return;
    }
    if (type === "withdraw" && Number(amount) > service.main_closing_balance) {
      setMessage("Insufficient balance for withdrawal.");
      return;
    }

    Swal.fire({
      title: `Are you sure you want to ${type} ₹${amount}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Enter your 6-digit UPIN",
          input: "password",
          inputAttributes: {
            maxlength: 6,
            minlength: 6,
            autocapitalize: "off",
            autocorrect: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Submit",
        }).then((upinResult) => {
          if (upinResult.isConfirmed && upinResult.value) {
            const upin = upinResult.value;

            setLoading(true);

            API({
              ...myNetworkWallet,
              body: {
                transaction_amount: amount,
                merchant_id: service.merchant_id,
                transaction_type: type === "withdraw" ? "withdraw" : "recharge",
                upin,
              },
              onSuccess: (res) => {
                setLoading(false);
                Swal.fire({
                  title: `${res.data.message}`,
                });
                setMessage(`Transaction successful: ${type} ₹${amount}`);
                setRechargeAmount("");
                setWithdrawAmount("");
              },
              onError: (error) => {
                setLoading(false);
                setMessage("Transaction failed. Please try again.");
                Swal.fire({
                  icon: "error",
                  title: `${error.response.data.message}`,
                });
              },
            });
          } else {
            setMessage("Transaction cancelled.");
          }
        });
      }
    });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "myNetwork",
      })
    );
    navigate("/");
  };

  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />

        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center  pb-4"
        >
          <img
            className="ps-2"
            style={{ cursor: "pointer", pointerEvents: "all" }}
            src={backArrow}
            alt="back"
            onClick={changePageFunc}
          />
          <h2
            className="mb-0 ps-3"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            My Network Information
          </h2>
        </div>
        {loading ? (
          <img
            src={loaderGif}
            alt="Loading..."
            style={{
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%)",
            }}
          />
        ) : (
          <div className="mt-4 pb-3">
            <h3
              className="px-4"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "14px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Merchant Info
            </h3>

            <div className="row mt-4 px-5">
              <div className="col-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Merchant ID
                </p>
                <input
                  type="text"
                  value={service?.merchant_id}
                  className="p-2 w-100 mt-1"
                  style={{
                    fontFamily: "Montserrat",
                  }}
                  readOnly
                />
              </div>
              <div className="col-4">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    color: "rgba(173, 131, 82, 1)",
                  }}
                >
                  Wallet Balance
                </p>
                <input
                  type="text"
                  className="p-2 w-100 mt-1"
                  value={`₹ ${service?.main_closing_balance}`}
                  style={{
                    fontFamily: "Montserrat",
                  }}
                  readOnly
                />
              </div>
            </div>

            <div className="row mt-5 px-5">
              {hasRole("Network Recharge") && (
                <div className="col-4">
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "green",
                    }}
                  >
                    Recharge
                  </p>
                  <input
                    type="number"
                    className="p-2 w-100 mt-1"
                    placeholder="Enter amount"
                    value={rechargeAmount}
                    onChange={(e) => setRechargeAmount(e.target.value)}
                    style={{
                      backgroundColor: "rgba(200, 255, 200, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />

                  <button
                    onClick={() => handleTransaction("recharge")}
                    className="btn btn-success mt-3"
                    disabled={loading}
                    style={{
                      opacity: loading ? 0.6 : 1,
                    }}
                  >
                    Recharge
                  </button>
                </div>
              )}
              {hasRole("Network Withdrawal") && (
                <div className="col-4">
                  <p
                    style={{
                      fontWeight: "600",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      color: "red",
                    }}
                  >
                    Withdrawal
                  </p>
                  <input
                    type="number"
                    className="p-2 w-100 mt-1"
                    placeholder="Enter amount"
                    value={withdrawAmount}
                    onChange={(e) => setWithdrawAmount(e.target.value)}
                    style={{
                      backgroundColor: "rgba(255, 200, 200, 0.4)",
                      borderRadius: "10px",
                      fontFamily: "Montserrat",
                    }}
                  />
                  <button
                    onClick={() => handleTransaction("withdraw")}
                    className="btn btn-danger mt-3"
                    disabled={loading}
                    style={{
                      opacity: loading ? 0.6 : 1,
                    }}
                  >
                    Withdraw
                  </button>
                </div>
              )}
            </div>

            {message && (
              <p
                className="mt-4 px-5"
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "rgba(173, 131, 82, 1)",
                }}
              >
                {message}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  roles: state.user.roles,
});

export default connect(mapStateToProps)(MyNetworkInfo);

import React, { useEffect, useState } from "react";
import { logo } from "../images";
import { changePage } from "../redux/slice/dashboardSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getForgetMail, forgetOtp } from "../urls";
import API from "../components/api2";
import Swal from "sweetalert2";
import ResetNav from "../components/ResetNav";

const VerifyForgotPassMail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mail, setMail] = useState();
  const [verifiedMail, setVerifiedMail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const location = useLocation();
  const { username } = location.state || {};

  const fetchMail = () => {
    API({
      ...getForgetMail,
      onSuccess: (res) => {
        setMail(res.data.data.mail);
      },
      onError: (error) => {
        console.log("error");
      },
    });
  };

  useEffect(() => {
    fetchMail();
  }, []);

  const handleVerifyMail = () => {
    setIsVerifying(true);
    API({
      ...forgetOtp,
      body: {
        is_email_verified: verifiedMail,
      },
      onSuccess: (res) => {
        setIsVerifying(false);
        Swal.fire({
          icon: "success",
          title: `${res.data.message}`,
        });
        changePageFunc(res.data.data.request_id);
      },
      onError: (error) => {
        setIsVerifying(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const changePageFunc = (reqId) => {
    console.log(reqId);
    dispatch(
      changePage({
        page: "otpVerifyForget",
        reqId: reqId,
        username: username,
      })
    );
    navigate("/", {
      state: {
        typedEmail: verifiedMail,
        reqId,
        username,
      },
    });
  };

  const handleInputChange = (e) => {
    setVerifiedMail(e.target.value);
    setIsButtonDisabled(e.target.value === "");
  };

  const handleCancel = () => {
    dispatch(
      changePage({
        page: "login",
      })
    );
    navigate("/login");
  };

  return (
    <div className="px-4">
      <ResetNav />
      <section>
        <div className="col-5 mx-auto mt-4">
          <h2
            style={{
              fontWeight: "500",
              fontSize: "20px",
              textAlign: "center",
              letterSpacing: "2px",
              color: "rgba(79, 87, 91, 1)",
            }}
          >
            Verify your email address
          </h2>
          <hr />
          <p
            style={{
              fontWeight: "400",
              fontSize: "16px",
              letterSpacing: "1px",
              color: "rgba(142, 147, 158, 1)",
            }}
            className="mb-0 mt-4 text-center px-3"
          >
            Please confirm that you want to use this account email address. Once
            it’s done you will get an OTP.
          </p>
          <p
            style={{
              fontWeight: "600",
              fontSize: "16px",
              letterSpacing: "1px",
            }}
            className="mb-0 mt-4 text-center px-3"
          >
            {mail}
          </p>
          <div className="mx-3" style={{ borderBottom: "1.5px solid black" }}>
            <input
              type="text"
              name="is_email_verified"
              id="is_email_verified"
              placeholder="Re-Type mail address"
              value={verifiedMail}
              autoComplete="off"
              onChange={handleInputChange}
              className="p-2 mt-4 w-100"
            />
          </div>
          <div className="text-center mt-4">
            <button
              type="submit"
              className="border-0 text-white py-2 px-4 mt-4 col-6"
              style={{
                backgroundColor: "#194280",
                borderRadius: "10px",
                opacity: isButtonDisabled || isVerifying ? 0.5 : 1,
              }}
              disabled={isButtonDisabled || isVerifying}
              onClick={handleVerifyMail}
            >
              {isVerifying ? "Verifying..." : "Verify Email Address"}
            </button>
            <p
              onClick={handleCancel}
              style={{
                fontWeight: "400",
                fontSize: "17px",
                color: "rgba(26, 25, 24, 1)",
                letterSpacing: "1px",
                cursor: "pointer",
              }}
              className="mb-0 mt-2"
            >
              Cancel
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VerifyForgotPassMail;

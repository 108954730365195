import React, { useEffect, useState } from "react";
import { add, circles, dashed, dashed2, removeStep, stepAdd } from "../images";
import InputForm from "../components/InputForm";
import Select from "react-select";
import API from "../components/api2";
import { FaTrash } from "react-icons/fa";
import {
  helpDeskCategory,
  getAssignEmpHelpdesk,
  getAllUserGroupsDropdown,
  helpDeskPost,
  checkWorkflowRules,
  getAdditionalFields,
  workflowFilters,
} from "../urls";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";
import TextEditor from "../components/TextEditor";
import loaderGif from "../gif/buffer.gif";

const AddWorkflow = () => {
  const [pageValues, setPageValues] = useState({
    type: null,
    category: null,
    subcategory: null,
    subject: "",
    template: "",
    additional_fields: [],
  });
  const [template, setTemplate] = useState(null);
  const handleChangesValues = (e) => {
    const { name, value } = e.target;
    setPageValues({ ...pageValues, [name]: value });
  };

  const [stepData, setStepData] = useState([
    {
      step_name: "",
      tat: "",
      tat_day: "",
      tat_hour: "",
      tat_minute: "",
      escalations: [
        { escalation1: null, escalation1_tat: "" },
        { escalation2: null, escalation2_tat: "" },
        { escalation3: null, escalation3_tat: "" },
      ],
    },
  ]);

  // ---------------------------------------------------------------------------//

  const [pageData, setPageData] = useState({
    type: null,
    category: null,
    subcategory: null,
    day: "",
    hour: "",
    minute: "",
    additional_fields: [],
  }); //=================================================|| DONT USE THIS STATE ||=========================================//

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [empOptions, setEmpOptions] = useState([]);
  const [userGroupOptions, setUserGroupsOptions] = useState([]);
  const [addFields, setAddFields] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [removedEmployees, setRemovedEmployees] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [selectFields, setSelectFields] = useState([{ id: 0, value: null }]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editorContent, setEditorContent] = useState("");

  const navigate = useNavigate();
  const [tabs, setTabs] = useState([{ id: 1, title: "Step 1" }]);
  const [workflowMessage, setWorkflowMessage] = useState(null);
  const [tabData, setTabData] = useState({
    0: {
      pageData: {
        escalation1: null,
        escalation1_tat: "",
        escalation2: null,
        escalation2_tat: "",
        escalation3: null,
      },
      selectedEmployees: [],
    },
  });

  const [stepIdMapping, setStepIdMapping] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // dropdowns for type, category, sub-category
  const handleTicketTypeChange = (selectedOption) => {
    setPageValues({
      ...pageValues,
      type: selectedOption.value,
      category: null,
      subcategory: null,
    });
    setCategoryOptions([]);
    setSubcategoryOptions([]);
    fetchCategories(selectedOption.value);
  };

  const handleCategoryChange = (selectedOption) => {
    setPageValues({
      ...pageValues,
      category: selectedOption.value,
      subcategory: null,
    });
    setSubcategoryOptions([]);
    fetchSubcategories(pageValues.type, selectedOption.value);
  };

  const handleSubcategoryChange = (selectedOption) => {
    setPageValues({
      ...pageValues,
      subcategory: selectedOption.value,
    });
  };

  const fetchCategories = (ticketType) => {
    API({
      ...helpDeskCategory,
      params: { type: ticketType },
      onSuccess: (res) => {
        setCategoryOptions(
          res.data.data.category_data.map((category) => ({
            value: category.category,
            label: category.category,
          }))
        );
      },
      onError: (error) => {
        console.error("Error fetching categories:", error);
      },
    });
  };

  const fetchSubcategories = (type, category) => {
    API({
      ...helpDeskCategory,
      params: { type: type, category: category },
      onSuccess: (res) => {
        setSubcategoryOptions(
          res.data.data.subcategory_data.map((subcategory) => ({
            value: subcategory.subcategory,
            label: subcategory.subcategory,
          }))
        );
      },
      onError: (error) => {
        console.error("Error fetching subcategories:", error);
      },
    });
  };

  useEffect(() => {
    API({
      ...workflowFilters,
      onSuccess: (res) => {
        setTypeOptions(
          res.data.data.types.map((t) => ({
            value: t,
            label: t,
          }))
        );
      },
      onError: (error) => {
        console.error("Error getting type:", error);
      },
    });
  }, []);

  // check workflow rules
  const workflowRules = () => {
    API({
      ...checkWorkflowRules,
      body: {
        type: pageData.type,
        category: pageData.category,
        subcategory: pageData.subcategory,
      },
      onSuccess: (res) => {
        if (
          res.data.message === "Helpdesk Workflow Already exist On This Rule."
        ) {
          setWorkflowMessage({ text: res.data.message, color: "red" });
          // setIsButtonDisabled(true);
        } else if (
          res.data.message === "Use This Rule For Creating Helpdesk Workflow."
        ) {
          setWorkflowMessage({ text: res.data.message, color: "green" });
          // setIsButtonDisabled(false);
        }
      },
      onError: (error) => {
        console.error("Error checking workflow rules:", error);
      },
    });
  };

  useEffect(() => {
    if (
      pageData.type !== null &&
      pageData.category !== null &&
      pageData.subcategory !== null
    ) {
      workflowRules();
    }
  }, [pageData.type && pageData.category && pageData.subcategory]);
  // ************************************************

  // assign employess
  useEffect(() => {
    API({
      ...getAllUserGroupsDropdown,
      onSuccess: (res) => {
        const groups = res.data.data.usergroups.map((u) => ({
          value: u.id,
          label: `${u.id}-${u.name}`,
        }));
        setUserGroupsOptions(groups);
      },
      onError: (error) => {
        console.error("Error: ", error);
      },
    });
  }, []);

  useEffect(() => {
    API({
      ...getAssignEmpHelpdesk,
      onSuccess: (res) => {
        const emp = res.data.data.map((u) => ({
          value: u.employee_id,
          label: `${u.employee_id}-${u.employee_name}`,
        }));
        setEmpOptions(emp);
      },
      onError: (error) => {
        console.error("Error employee: ", error);
      },
    });
  }, []);

  // additional fields
  useEffect(() => {
    API({
      ...getAdditionalFields,
      params: { dropdown: "1" },
      onSuccess: (res) => {
        const fields = res.data.data.custom_fields.map((a) => ({
          value: a.id,
          label: a.field_label,
        }));
        setAddFields(fields);
      },
      onError: (error) => {
        console.error("Error: ", error);
      },
    });
  }, []);

  const handleAddField = () => {
    const newField = { id: selectFields.length, value: null };
    const updatedFields = [...selectFields, newField];
    setSelectFields(updatedFields);
    validateButtonState(updatedFields);
  };

  const handleFieldChange = (selectedOption, index) => {
    const updatedFields = [...selectFields];
    updatedFields[index] = {
      ...updatedFields[index],
      value: selectedOption ? selectedOption.value : null,
    };
    setSelectFields(updatedFields);
    console.log("here");
    validateButtonState(updatedFields);

    const selectedValues = updatedFields
      .map((field) => field.value)
      .filter((value) => value !== null);
    setPageValues((prevState) => ({
      ...prevState,
      additional_fields: selectedValues,
    }));
  };

  const validateButtonState = (fields) => {
    let shouldDisableButton = false;
    let message = "";

    const hasValue10 = fields.some((field) => field.value === 10);
    const hasValue9 = fields.some((field) => field.value === 9);
    const hasValue11Or12 = fields.some(
      (field) => field.value === 11 || field.value === 12
    );

    if (hasValue10) {
      if (!hasValue9) {
        message = "Please add field and select Branch from options";
        shouldDisableButton = true;
      }
    } else if (hasValue11Or12) {
      if (!hasValue10 || !hasValue9) {
        message = "Please add field and select Center ID from options";
        shouldDisableButton = true;
      }
    }

    setErrorMessage(message);
    setIsButtonDisabled(shouldDisableButton);
  };

  const getAvailableOptions = (index) => {
    const selectedValues = selectFields
      .map((field) => field.value)
      .filter((value) => value !== null);
    return addFields.filter((option) => !selectedValues.includes(option.value));
  };

  const handleAddEmployee = () => {
    setSelectedEmployees([...selectedEmployees, ""]);
  };

  // ************************************************

  // add workflow
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const workflowSteps = tabs.map((tab, index) => {
      const tat_day = tabData[index]?.pageData.tat_day || "00";
      const tat_hour = tabData[index]?.pageData.tat_hour || "00";
      const tat_minute = tabData[index]?.pageData.tat_minute || "00";
      const tat = `${tat_day}:${tat_hour}:${tat_minute}`;

      const escalation1_day = tabData[index]?.pageData.escalation1_day || "00";
      const escalation1_hour =
        tabData[index]?.pageData.escalation1_hour || "00";
      const escalation1_minute =
        tabData[index]?.pageData.escalation1_minute || "00";
      const escalation1_tat = `${escalation1_day}:${escalation1_hour}:${escalation1_minute}`;

      const escalation2_day = tabData[index]?.pageData.escalation2_day || "00";
      const escalation2_hour =
        tabData[index]?.pageData.escalation2_hour || "00";
      const escalation2_minute =
        tabData[index]?.pageData.escalation2_minute || "00";
      const escalation2_tat = `${escalation2_day}:${escalation2_hour}:${escalation2_minute}`;

      return {
        step_id: index + 1,
        step_name: tabData[index]?.pageData.step_name || `Step ${index + 1}`,
        assigned_to:
          tabData[index]?.selectedEmployees.map((employee) => employee.value) ||
          [],
        tat: tat,
        escalation1: tabData[index]?.pageData.escalation1 || null,
        escalation1_tat: escalation1_tat,
        escalation2: tabData[index]?.pageData.escalation2 || null,
        escalation2_tat: escalation2_tat,
        escalation3: tabData[index]?.pageData.escalation3 || null,
      };
    });

    const additionalFieldIds = selectFields
      .filter((field) => !field.isDeleted)
      .map((field) => field.value);

    const submitData = {
      ...{ ...pageValues, template: template },
      steps: workflowSteps,
      additional_fields: additionalFieldIds,
    };

    API({
      ...helpDeskPost,
      body: submitData,
      onSuccess: (res) => {
        setIsLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          window.location.reload();
        });
      },

      onError: (error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  // steps *****************************

  // handle tab click
  const handleTabClick = (index) => {
    if (index !== activeTab) {
      setTabData((prevData) => ({
        ...prevData,
        [activeTab]: {
          pageData: { ...pageData },
          selectedEmployees: [...selectedEmployees],
        },
      }));

      const defaultPageData = {
        tat: "",
        escalation1: null,
        escalation1_tat: "",
        escalation2: null,
        escalation2_tat: "",
        escalation3: null,
      };

      const currentTabData = tabData[index] || {
        pageData: defaultPageData,
        selectedEmployees: [],
      };

      setPageData(currentTabData.pageData);
      setSelectedEmployees(currentTabData.selectedEmployees);
      setActiveTab(index);
    }
  };

  // add steps
  const handleAddStep = () => {
    setTabs((prevTabs) => [
      ...prevTabs,
      { id: prevTabs.length + 1, title: `Step ${prevTabs.length + 1}` },
    ]);
  };

  // remove steps
  const handleRemoveStep = (indexToRemove) => {
    setTabs((prevTabs) => {
      const newTabs = prevTabs.filter((_, index) => index !== indexToRemove);
      return newTabs.map((tab, idx) => ({
        ...tab,
        id: idx + 1,
        title: `Step ${idx + 1}`,
      }));
    });

    setTabData((prevData) => {
      const newData = { ...prevData };
      delete newData[indexToRemove];
      const updatedData = Object.keys(newData).reduce((acc, key) => {
        const newIndex = parseInt(key, 10);
        if (newIndex > indexToRemove) {
          acc[newIndex - 1] = newData[key];
        } else if (newIndex < indexToRemove) {
          acc[newIndex] = newData[key];
        }
        return acc;
      }, {});
      return updatedData;
    });

    setActiveTab((prevActiveTab) => {
      if (prevActiveTab > indexToRemove) {
        return prevActiveTab - 1;
      } else if (prevActiveTab === indexToRemove) {
        return Math.max(0, prevActiveTab - 1);
      } else {
        return prevActiveTab;
      }
    });

    setStepIdMapping((prevMapping) => {
      const newMapping = { ...prevMapping };
      const removedId = indexToRemove + 1;
      const newId = Math.max(0, removedId - 1);
      newMapping[removedId] = newId;
      return newMapping;
    });

    const newIndex = Math.max(0, activeTab - 1);
    const currentTabData = tabData[newIndex] || {
      pageData: {},
      selectedEmployees: [],
    };
    setPageData(currentTabData.pageData);
    setSelectedEmployees(currentTabData.selectedEmployees);
  };

  const handleRemoveEmployee = (index) => {
    setRemovedEmployees((prev) => [...prev, selectedEmployees[index]]);
    const updatedEmployees = selectedEmployees.filter((_, i) => i !== index);
    setSelectedEmployees(updatedEmployees);

    setTabData((prevTabData) => ({
      ...prevTabData,
      [activeTab]: {
        ...prevTabData[activeTab],
        selectedEmployees: updatedEmployees,
      },
    }));
  };

  const handleDeleteField = (index) => {
    const updatedSelectFields = selectFields.map((field, idx) =>
      idx === index ? { ...field, isDeleted: true } : field
    );
    setSelectFields(updatedSelectFields);
  };

  // *************************************

  // handle tat
  const days = Array.from({ length: 31 }, (_, i) => i);
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = Array.from({ length: 60 }, (_, i) => i);

  const renderContent = () => {
    const currentTabData = tabData[activeTab] || {
      pageData: {
        escalation1: null,
        escalation2: null,
        escalation3: null,
      },
      selectedEmployees: [],
    };

    const handleTatChange = (field, value, tatType) => {
      if (tatType === "tat") {
        handlePageDataChange(`tat_${field}`, value);

        const tat = `${pageData.tat_day || "00"}:${pageData.tat_hour || "00"}:${
          pageData.tat_minute || "00"
        }`;

        handlePageDataChange("tat", tat);
      } else if (tatType === "escalation1_tat") {
        handlePageDataChange(`escalation1_${field}`, value);

        const escalationTat = `${pageData.escalation1_day || "00"}:${
          pageData.escalation1_hour || "00"
        }:${pageData.escalation1_minute || "00"}`;

        handlePageDataChange("escalation1_tat", escalationTat);
      } else if (tatType === "escalation2_tat") {
        handlePageDataChange(`escalation2_${field}`, value);

        const escalation2Tat = `${pageData.escalation2_day || "00"}:${
          pageData.escalation2_hour || "00"
        }:${pageData.escalation2_minute || "00"}`;

        handlePageDataChange("escalation2_tat", escalation2Tat);
      }
    };

    const handlePageDataChange = (field, value) => {
      setPageData((prevPageData) => ({
        ...prevPageData,
        [field]: value,
      }));

      setTabData((prevTabData) => ({
        ...prevTabData,
        [activeTab]: {
          ...prevTabData[activeTab],
          pageData: {
            ...prevTabData[activeTab]?.pageData,
            [field]: value,
          },
          [field]: value,
        },
      }));
    };

    return (
      <>
        {/* step name  */}
        <div className="mx-4 px-4 mt-4 col-4">
          <InputForm
            type={"text"}
            placeholder={"Step Name"}
            id={"step_name"}
            name="step_name"
            value={pageData?.step_name}
            onChange={(event) =>
              handlePageDataChange("step_name", event.target.value)
            }
          />
        </div>

        {/* Assign To */}
        <div
          className="mt-5 px-5 py-4 mx-5 position-relative dash_line"
          style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Assign To
            </h3>
            <div className="col-7">
              <div
                className="col-6"
                style={{
                  border: "1.5px solid rgba(105, 102, 102, 1)",
                  borderRadius: "10px",
                }}
                onClick={handleAddEmployee}
              >
                <div
                  className="d-flex align-items-center py-1 px-2"
                  style={{ cursor: "pointer" }}
                >
                  <img src={add} alt="add-employee" />
                  <p
                    className="mb-0 ms-2"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "rgba(105, 102, 102, 1)",
                    }}
                  >
                    {" "}
                    Add New User Group
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {selectedEmployees.length === 0 && (
              <div className="col-4">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`assigned_to`}
                  className="form-label"
                >
                  User Groups
                </label>
                <Select
                  placeholder="Select User Group"
                  id={`assigned_to`}
                  name={`assigned_to`}
                  options={userGroupOptions}
                  value={selectedEmployees[0]}
                  onChange={(selectedOption) => {
                    setSelectedEmployees([selectedOption]);

                    setTabData((prevTabData) => ({
                      ...prevTabData,
                      [activeTab]: {
                        ...prevTabData[activeTab],
                        selectedEmployees: [selectedOption],
                      },
                    }));
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      width: "400px",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
              </div>
            )}
            {selectedEmployees.map((employee, index) => (
              <div className="col-6 position-relative" key={index}>
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`assigned_to_${index}`}
                  className="form-label"
                >
                  Select User Group
                </label>
                <Select
                  placeholder="Select User Group"
                  id={`assigned_to_${index}`}
                  name={`assigned_to_${index}`}
                  options={userGroupOptions.filter(
                    (option) =>
                      !selectedEmployees.some(
                        (selectedEmployee) =>
                          selectedEmployee.value === option.value
                      )
                  )}
                  value={employee}
                  onChange={(selectedOption) => {
                    const updatedEmployees = [...selectedEmployees];
                    updatedEmployees[index] = selectedOption;
                    setSelectedEmployees(updatedEmployees);

                    setTabData((prevTabData) => ({
                      ...prevTabData,
                      [activeTab]: {
                        ...prevTabData[activeTab],
                        selectedEmployees: updatedEmployees,
                      },
                    }));
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      width: "300px",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
                <MdDelete
                  onClick={() => handleRemoveEmployee(index)}
                  style={{
                    color: "grey",
                    fontSize: "20px",
                    marginLeft: "300px",
                    cursor: "pointer",
                    position: "absolute",
                    top: "65%",
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        {/* TAT */}
        <div
          className="mt-5 px-5 py-4 mx-5 position-relative dash_line2"
          style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
        >
          <h3
            className="mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              color: "rgba(105, 102, 102, 1)",
            }}
          >
            If ticket status not closed in
          </h3>
          <div className="row mt-4">
            <div className="col-11">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="day"
                className="form-label mx-2"
              >
                Day
              </label>
              <select
                name="day"
                value={pageData.tat_day || ""}
                onChange={(e) => handleTatChange("day", e.target.value, "tat")}
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "150px",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                <option value="">Day</option>
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>

              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="hour"
                className="form-label ms-4 me-2"
              >
                Hour
              </label>
              <select
                name="hour"
                value={pageData.tat_hour || ""}
                onChange={(e) => handleTatChange("hour", e.target.value, "tat")}
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "150px",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                <option value="">Hour</option>
                {hours.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </select>

              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="minute"
                className="form-label ms-4 me-2"
              >
                Minute
              </label>
              <select
                name="minute"
                value={pageData.tat_minute || ""}
                onChange={(e) =>
                  handleTatChange("minute", e.target.value, "tat")
                }
                style={{
                  backgroundColor: "rgba(255, 231, 159,0.4)",
                  borderRadius: "10px",
                  marginTop: "8px",
                  color: "black",
                  border: "none",
                  boxShadow: "none",
                  width: "150px",
                  fontFamily: "Montserrat",
                  outline: "none",
                  padding: "12px 8px",
                }}
              >
                <option value="">Minute</option>
                {minutes.map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Escalation 1 */}
        <div className="position-relative">
          <img
            style={{
              position: "absolute",
              right: "5%",
              top: "-8.5%",
              width: "420px",
            }}
            src={dashed}
            alt="dashed"
          />
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: "500",
              right: "8%",
              top: "53%",
              transform: "translateY(-50%)",
            }}
            className="mb-0 position-absolute"
          >
            Escalation 1
          </p>
          <div
            className="col-10 px-5 py-4 mx-5 position-relative dash_line"
            style={{
              border: "1.5px dashed rgba(105, 102, 102, 1)",
              marginTop: "80px",
            }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Assign To
            </h3>
            <div className="row mt-4">
              <div className="col-6">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`escalation1`}
                  className="form-label"
                >
                  Select Employee
                </label>
                <Select
                  placeholder="Select Employee ID"
                  id={`escalation1`}
                  name={`escalation1`}
                  options={empOptions}
                  value={
                    currentTabData &&
                    currentTabData.pageData &&
                    currentTabData.pageData.escalation1
                      ? {
                          value: currentTabData.pageData.escalation1,
                          label: currentTabData.pageData.escalation1,
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handlePageDataChange("escalation1", selectedOption.value)
                  }
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
              </div>
            </div>
          </div>
          <div
            className="col-10 mt-5 px-5 py-4 mx-5 position-relative dash_line2"
            style={{
              border: "1.5px dashed rgba(105, 102, 102, 1)",
            }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              If ticket status not closed in
            </h3>
            <div className="row mt-4">
              <div className="col-12">
                <label
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="day"
                  className="form-label mx-2"
                >
                  Day
                </label>
                <select
                  name="day"
                  value={pageData.escalation1_day || ""}
                  onChange={(e) =>
                    handleTatChange("day", e.target.value, "escalation1_tat")
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    marginTop: "8px",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                    width: "150px",
                    fontFamily: "Montserrat",
                    outline: "none",
                    padding: "12px 8px",
                  }}
                >
                  <option value="">Day</option>
                  {days.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>

                <label
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="hour"
                  className="form-label ms-4 me-2"
                >
                  Hour
                </label>
                <select
                  name="hour"
                  value={pageData.escalation1_hour || ""}
                  onChange={(e) =>
                    handleTatChange("hour", e.target.value, "escalation1_tat")
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    marginTop: "8px",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                    width: "150px",
                    fontFamily: "Montserrat",
                    outline: "none",
                    padding: "12px 8px",
                  }}
                >
                  <option value="">Hour</option>
                  {hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>

                <label
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="minute"
                  className="form-label ms-4 me-2"
                >
                  Minute
                </label>
                <select
                  name="minute"
                  value={pageData.escalation1_minute || ""}
                  onChange={(e) =>
                    handleTatChange("minute", e.target.value, "escalation1_tat")
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    marginTop: "8px",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                    width: "150px",
                    fontFamily: "Montserrat",
                    outline: "none",
                    padding: "12px 8px",
                  }}
                >
                  <option value="">Minute</option>
                  {minutes.map((minute) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Escalation 2 */}
        <div className="position-relative">
          <img
            style={{
              position: "absolute",
              right: "5%",
              top: "-8%",
              width: "420px",
            }}
            src={dashed}
            alt="dashed"
          />
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: "500",
              right: "8%",
              top: "53%",
              transform: "translateY(-50%)",
            }}
            className="mb-0 position-absolute"
          >
            Escalation 2
          </p>
          <div
            className="col-10 px-5 py-4 mx-5 position-relative dash_line"
            style={{
              border: "1.5px dashed rgba(105, 102, 102, 1)",
              marginTop: "80px",
            }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Assign To
            </h3>
            <div className="row mt-4">
              <div className="col-6">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`escalation2`}
                  className="form-label"
                >
                  Select Employee
                </label>

                <Select
                  placeholder="Select Employee ID"
                  id={`escalation2`}
                  name={`escalation2`}
                  options={empOptions}
                  value={
                    currentTabData &&
                    currentTabData.pageData &&
                    currentTabData.pageData.escalation2
                      ? {
                          value: currentTabData.pageData.escalation2,
                          label: currentTabData.pageData.escalation2,
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    handlePageDataChange("escalation2", selectedOption.value);
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
              </div>
            </div>
          </div>
          <div
            className="mt-5 col-10 px-5 py-4 mx-5 position-relative dash_line2"
            style={{ border: "1.5px dashed rgba(105, 102, 102, 1)" }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              If ticket status not closed in
            </h3>
            <div className="row mt-4">
              <div className="col-12">
                <label
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="day"
                  className="form-label mx-2"
                >
                  Day
                </label>
                <select
                  name="day"
                  value={pageData.escalation2_day || ""}
                  onChange={(e) =>
                    handleTatChange("day", e.target.value, "escalation2_tat")
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    marginTop: "8px",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                    width: "150px",
                    fontFamily: "Montserrat",
                    outline: "none",
                    padding: "12px 8px",
                  }}
                >
                  <option value="">Day</option>
                  {days.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>

                <label
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="hour"
                  className="form-label ms-4 me-2"
                >
                  Hour
                </label>
                <select
                  name="hour"
                  value={pageData.escalation2_hour || ""}
                  onChange={(e) =>
                    handleTatChange("hour", e.target.value, "escalation2_tat")
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    marginTop: "8px",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                    width: "150px",
                    fontFamily: "Montserrat",
                    outline: "none",
                    padding: "12px 8px",
                  }}
                >
                  <option value="">Hour</option>
                  {hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>

                <label
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor="minute"
                  className="form-label ms-4 me-2"
                >
                  Minute
                </label>
                <select
                  name="minute"
                  value={pageData.escalation2_minute || ""}
                  onChange={(e) =>
                    handleTatChange("minute", e.target.value, "escalation2_tat")
                  }
                  style={{
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    borderRadius: "10px",
                    marginTop: "8px",
                    color: "black",
                    border: "none",
                    boxShadow: "none",
                    width: "150px",
                    fontFamily: "Montserrat",
                    outline: "none",
                    padding: "12px 8px",
                  }}
                >
                  <option value="">Minute</option>
                  {minutes.map((minute) => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Escalation 3 */}
        <div className="position-relative">
          <img
            style={{
              position: "absolute",
              right: "5%",
              top: "-10%",
              width: "420px",
            }}
            src={dashed2}
            alt="dashed"
          />
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: "500",
              right: "8%",
              top: "100%",
            }}
            className="mb-0 position-absolute"
          >
            Escalation 3
          </p>
          <div
            className="col-10 px-5 py-4 mx-5 position-relative"
            style={{
              border: "1.5px dashed rgba(105, 102, 102, 1)",
              marginTop: "80px",
            }}
          >
            <h3
              className="mb-0"
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                color: "rgba(105, 102, 102, 1)",
              }}
            >
              Assign To
            </h3>
            <div className="row mt-4">
              <div className="col-6">
                <label
                  style={{
                    marginTop: "8px",
                    fontSize: "15px",
                    fontWeight: "500",
                    color: "rgba(105, 102, 102, 1)",
                    fontFamily: "Montserrat",
                  }}
                  htmlFor={`escalation3`}
                  className="form-label"
                >
                  Select Employee
                </label>
                <Select
                  placeholder="Select Employee ID"
                  id={`escalation3`}
                  name={`escalation3`}
                  options={empOptions}
                  value={
                    currentTabData &&
                    currentTabData.pageData &&
                    currentTabData.pageData.escalation3
                      ? {
                          value: currentTabData.pageData.escalation3,
                          label: currentTabData.pageData.escalation3,
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    handlePageDataChange("escalation3", selectedOption.value);
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(255, 231, 159,0.4)",
                      borderRadius: "10px",
                      border: "none",
                      boxShadow: "none",
                      "&:focus": {
                        outline: "none",
                      },
                    }),
                  }}
                  menuPlacement="top"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="my-2 flex-grow-1 bg-white w-100 mx-auto  py-4 me-2 position-relative overflow-y-auto h-100">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add Workflow
        </h2>

        {/* workflow details */}
        <div
          className="py-2 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "240px",
            marginTop: "-1px",
          }}
        >
          Workflow Details
        </div>
        <div className="px-5 mt-3">
          <div className="col-6">
            <InputForm
              type={"text"}
              placeholder={"Name"}
              id={"workflow_name"}
              name="workflow_name"
              value={pageValues.workflow_name || ""}
              onChange={handleChangesValues}
            />
          </div>
          <label
            className="mb-0 mt-4"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontFamily: "Montserrat",
            }}
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            className="w-100 p-2 mt-2"
            style={{
              outline: "none",
              backgroundColor: "#FFF6D8",
              resize: "none",
              border: "none",
              borderRadius: "10px",
              fontFamily: "Montserrat",
              fontSize: "14px",
            }}
            spellCheck={false}
            name="description"
            id="description"
            placeholder="Write workflow description here"
            value={pageValues.description}
            onChange={handleChangesValues}
            rows="7"
          ></textarea>
        </div>

        {/* rules */}
        <div
          className="py-2 ps-3 mt-4"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "240px",
            marginTop: "-1px",
          }}
        >
          Rules
        </div>

        {/* rules part 1  */}
        <div className="m-4 px-5 position-relative">
          <div className="row">
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="type"
                className="form-label ms-2"
              >
                Type
              </label>
              <Select
                id="type"
                name="type"
                placeholder="Select Type"
                value={
                  pageValues.type
                    ? {
                        value: pageValues.type,
                        label: pageValues.type,
                      }
                    : null
                }
                onChange={handleTicketTypeChange}
                options={typeOptions}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "rgba(255, 231, 159, 0.4)",
                    padding: "2px 6px",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "none",
                    "&:focus": {
                      outline: "none",
                    },
                  }),
                }}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="category"
                className="form-label ms-2"
              >
                Category
              </label>
              <Select
                id="category"
                name="category"
                placeholder="Select Category"
                value={
                  pageValues.category
                    ? {
                        value: pageValues.category,
                        label: pageValues.category,
                      }
                    : null
                }
                onChange={handleCategoryChange}
                options={categoryOptions}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    padding: "2px 6px",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "none",
                    "&:focus": {
                      outline: "none",
                    },
                  }),
                }}
                isSearchable={false}
              />
            </div>
            <div className="col-4">
              <label
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="subcategory"
                className="form-label ms-2"
              >
                Sub Category
              </label>
              <Select
                id="subcategory"
                name="subcategory"
                placeholder="Select Sub Category"
                value={
                  pageValues.subcategory
                    ? {
                        value: pageValues.subcategory,
                        label: pageValues.subcategory,
                      }
                    : null
                }
                onChange={handleSubcategoryChange}
                options={subcategoryOptions}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "rgba(255, 231, 159,0.4)",
                    padding: "2px 6px",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "none",
                    "&:focus": {
                      outline: "none",
                    },
                  }),
                }}
                isSearchable={false}
              />
            </div>
          </div>
          {workflowMessage && (
            <div
              style={{
                marginTop: "10px",
                marginLeft: "50px",
                color: workflowMessage.color,
                position: "absolute",
                left: "0%",
              }}
            >
              {workflowMessage.text}
            </div>
          )}
          <div className="mt-4 pt-3">
            <div className="d-flex align-items-center">
              <p
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
              >
                Additional Field/'s
              </p>
              <img
                className="ms-3"
                style={{
                  height: "25px",
                  cursor: "pointer",
                  pointerEvents: "all",
                  marginTop: "-9px",
                }}
                src={stepAdd}
                alt="add-step"
                onClick={handleAddField}
              />
              {errorMessage && (
                <p
                  style={{
                    color: "red",
                    fontSize: "14px",
                    fontWeight: "500",
                    marginTop: "4px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    fontFamily: "Montserrat",
                    position: "absolute",
                  }}
                >
                  {errorMessage}
                </p>
              )}
            </div>
            <div className="row d-flex align-items-center">
              {selectFields
                .filter((field) => !field.isDeleted)
                .map((field, index) => (
                  <div className="col-4 ms-3 position-relative" key={field.id}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(105, 102, 102, 1)",
                        fontFamily: "Montserrat",
                        marginTop: index >= 2 ? "18px" : "0",
                      }}
                      htmlFor={`additional_fields_${field.id}`}
                      className="form-label ms-2"
                    >
                      Field Type {index + 1}
                    </label>

                    <Select
                      id={`additional_fields_${field.id}`}
                      name={`additional_fields_${field.id}`}
                      placeholder="Select Field Type"
                      value={
                        field.value
                          ? {
                              value: field.value,
                              label: addFields.find(
                                (option) => option.value === field.value
                              )?.label,
                            }
                          : null
                      }
                      options={getAvailableOptions(index)}
                      onChange={(selectedOption) =>
                        handleFieldChange(selectedOption, index)
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: "rgba(255, 231, 159, 0.4)",
                          padding: "2px 6px",
                          borderRadius: "10px",
                          border: "none",
                          boxShadow: "none",
                          "&:focus": {
                            outline: "none",
                          },
                        }),
                      }}
                    />

                    <FaTrash
                      onClick={() => handleDeleteField(field.id)}
                      style={{
                        position: "absolute",
                        top: index > 1 ? "68%" : "58%",
                        right: "-12px",
                        cursor: "pointer",
                        color: "grey",
                        fontSize: "16px",
                      }}
                      title="Delete Field"
                    />
                  </div>
                ))}
            </div>
          </div>

          {/* ================================ || SUBJECT TICKET || =========================== */}
          <div className="mt-4 pt-3">
            <div className="d-flex align-items-center">
              <p
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
              >
                Template for Ticket’s Subject & Description:
              </p>
            </div>

            <div className="mt-3">
              <div className="col-6">
                <InputForm
                  type={"text"}
                  placeholder={"Subject Ticket"}
                  id={"subject"}
                  name="subject"
                  value={pageValues.subject || ""}
                  onChange={handleChangesValues}
                />
              </div>
              <label
                className="mb-0 mt-4"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="description"
              >
                Description
              </label>
              <TextEditor
                initialContent={template}
                placeholder="Write Your Description Here..."
                maxCharacters={5000}
                isFullScreen={false}
                onContentChange={(val) => setTemplate(val)}
              />
            </div>
          </div>
        </div>

        {/*======================================== workflow steps ==============================  */}
        <div className="pt-4">
          <h2
            className="mx-4 px-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              color: "rgba(105, 102, 102, 1)",
              fontSize: "16px",
            }}
          >
            Workflow Steps:
          </h2>
          <div className="mx-4 mt-3 px-4">
            {tabs.map((tab, index) => (
              <button
                style={{
                  border: "1.2px solid rgba(105, 102, 102, 1)",
                  borderTop: "1.2px solid rgba(105, 102, 102, 1)",
                  borderLeft: "1.2px solid rgba(105, 102, 102, 1)",
                  borderRight: "1.2px solid rgba(105, 102, 102, 1)",
                  width: "120px",
                  borderBottom:
                    index === activeTab
                      ? "none"
                      : "1.2px solid rgba(105, 102, 102, 1)",
                  borderRadius: index === activeTab ? "5px 5px 0 0" : "5px",
                  fontFamily: "Montserrat",
                  color: "rgba(105, 102, 102, 1)",
                  padding: index === activeTab ? "10px" : "6px 10px",
                  backgroundColor: "#ffff",
                }}
                key={index}
                className="me-2 position-relative"
                onClick={() => handleTabClick(index)}
              >
                {tab.title}

                {index === activeTab && index > 0 && (
                  <img
                    style={{
                      marginTop: "-5px",
                      cursor: "pointer",
                      pointerEvents: "all",
                    }}
                    src={removeStep}
                    className="ms-2"
                    alt="step-remove"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveStep(index);
                    }}
                  />
                )}

                {index === activeTab && (
                  <div className="workflow_selected_tab" />
                )}
              </button>
            ))}

            <img
              style={{
                height: "36px",
                cursor: "pointer",
                pointerEvents: "all",
                marginTop: "-5px",
              }}
              src={stepAdd}
              alt="add-step"
              onClick={() => handleAddStep()}
            />
          </div>
          <div>{renderContent()}</div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-5 mx-5 pt-5">
          <button
            className="py-1 px-4"
            style={{
              border: "1px solid rgba(22, 64, 127, 1)",
              color: "white",
              borderRadius: "10px",
              color: "rgba(22, 64, 127, 1)",
              fontSize: "18px",
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="py-1 px-4"
            style={{
              backgroundColor:
                isLoading || isButtonDisabled
                  ? "white"
                  : "rgba(22, 64, 127, 1)",
              color: "white",
              borderRadius: "10px",
              border: "1px solid rgba(22, 64, 127, 1)",
              fontSize: "18px",
              opacity: isButtonDisabled || isLoading ? 0.5 : 1,
              cursor: isButtonDisabled || isLoading ? "not-allowed" : "pointer",
            }}
            onClick={handleSubmit}
            disabled={isButtonDisabled || isLoading}
          >
            {isLoading ? (
              <img
                src={loaderGif}
                alt="Loading..."
                style={{ width: "30px", height: "30px" }}
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddWorkflow;

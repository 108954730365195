import React, { useState, useRef, useEffect } from "react";
import { circles, greenTick, liDot, upinDesc, upinIcon } from "../../images";
import API from "../../components/api2";
import { changeUserPin, userPin } from "../../urls";
import tick from "../../gif/tick.gif";
import { changePage, setBackPage } from "../../redux/slice/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import Swal from "sweetalert2";
import Header from "../../components/Header";

const ChangeUpin = ({ backPage }) => {
  const [upin, setUpin] = useState(["", "", "", "", "", ""]);
  const [confirmUpin, setConfirmUpin] = useState(["", "", "", "", "", ""]);
  const [oldUpin, setOldUpin] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const upinRefs = useRef([]);
  const confirmUpinRefs = useRef([]);
  const oldUpinRefs = useRef([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageData, setPageData] = useState({
    new_user_pin: "",
    confirm_user_pin: "",
    isSuccess: false,
    pinHelpText: [
      {
        text: "The PIN must be a 6-digit number.",
        matched: false,
      },
      {
        text: "The PIN cannot contain sequential digits (e.g. 123456).",
        matched: false,
      },
      {
        text: "The PIN cannot contain repeated digits (e.g 111111,112233).",
        matched: false,
      },
      {
        text: "The PIN cannot be the same as the user's date of birth.",
        matched: false,
      },
    ],
  });

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Change UPIN");

  useEffect(() => {
    const isValid =
      upin.join("") === confirmUpin.join("") && validatePin() === null;

    setSubmitDisabled(!isValid);
  }, [upin, confirmUpin]);

  useEffect(() => {
    validatePin();
  }, [upin]);

  const validatePin = () => {
    const newPin = pageData.new_user_pin;

    if (newPin === "") {
      const emptyCriteria = pageData.pinHelpText.map((item) => ({
        ...item,
        matched: false,
      }));

      setPageData((prev) => ({
        ...prev,
        pinHelpText: emptyCriteria,
      }));

      return "PIN cannot be empty";
    }

    let pinHelpText = [
      {
        text: "The PIN must be a 6-digit number.",
        matched: /^\d{6}$/.test(newPin),
      },
      {
        text: "The PIN cannot contain sequential digits.",
        matched:
          !/(\d)\1{2}|(?:012|123|234|345|456|567|678|789|890|901|987|876|765|654|543|432|321|210)\d{3}/.test(
            newPin
          ),
      },

      {
        text: "The PIN cannot contain repeated digits (e.g 111111,112233).",
        matched: !/(.)\1{2}/.test(newPin),
      },
      {
        text: "The PIN cannot be the same as the user's date of birth.",
        matched: newPin !== "010190",
      },
    ];

    setPageData((prev) => ({ ...prev, pinHelpText }));

    return pinHelpText.every((item) => item.matched)
      ? null
      : "PIN does not match the criteria";
  };

  const handleOldUpinChange = (index, value) => {
    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    const newOldUpin = [...oldUpin];
    newOldUpin[index] = value;
    setOldUpin(newOldUpin);

    if (value !== "" && index < oldUpin.length - 1) {
      oldUpinRefs.current[index + 1].focus();
    } else if (value === "" && index > 0) {
      oldUpinRefs.current[index - 1].focus();
    }
  };

  const handleUpinChange = (index, value) => {
    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    const newUpin = [...upin];
    newUpin[index] = value;
    setUpin(newUpin);
    const newPin = newUpin.join("");
    setPageData((prev) => ({ ...prev, new_user_pin: newPin }));

    if (newPin === "") {
      setPageData((prev) => ({
        ...prev,
        pinHelpText: prev.pinHelpText.map((item) => ({
          ...item,
          matched: false,
        })),
      }));
    } else {
      validatePin();
    }

    if (value !== "" && index < upin.length - 1) {
      upinRefs.current[index + 1].focus();
    } else if (value === "" && index > 0) {
      upinRefs.current[index - 1].focus();
    }
  };

  const handleConfirmUpinChange = (index, value) => {
    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    const newConfirmUpin = [...confirmUpin];
    newConfirmUpin[index] = value;
    setConfirmUpin(newConfirmUpin);

    if (value !== "" && index < confirmUpin.length - 1) {
      confirmUpinRefs.current[index + 1].focus();
    }
  };

  const handleUpinKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && upin[index] === "") {
      const newUpin = [...upin];
      newUpin[index - 1] = "";
      setUpin(newUpin);

      const newPinValue = newUpin.join("");
      if (newPinValue === "") {
        setPageData((prev) => ({
          ...prev,
          pinHelpText: prev.pinHelpText.map((item, i) => ({
            ...item,
            matched: false,
          })),
        }));
      }

      upinRefs.current[index - 1].focus();
    }
  };

  const handleConfirmUpinKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && confirmUpin[index] === "") {
      const newConfirmUpin = [...confirmUpin];
      newConfirmUpin[index - 1] = "";
      setConfirmUpin(newConfirmUpin);
      setPageData((prev) => ({
        ...prev,
        pinHelpText: prev.pinHelpText.map((item, i) => ({
          ...item,
          matched: i === 0 ? false : item.matched,
        })),
      }));
      confirmUpinRefs.current[index - 1].focus();
    }
  };

  const handleOldUpinKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && oldUpin[index] === "") {
      const newOldUpin = [...oldUpin];
      newOldUpin[index - 1] = "";
      setOldUpin(newOldUpin);

      oldUpinRefs.current[index - 1].focus();
    }
  };

  const handleUpinPaste = (index, event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").slice(0, 1);
    handleUpinChange(index, pasteData);
  };

  const handleConfirmUpinPaste = (index, event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").slice(0, 1);
    handleConfirmUpinChange(index, pasteData);
  };

  const handleOldUpinPaste = (index, event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").slice(0, 1);
    handleOldUpinChange(index, pasteData);
  };

  const handleSubmit = () => {
    const oldUpinValue = oldUpin.join("");
    const newUpinValue = upin.join("");
    const confirmUpinValue = confirmUpin.join("");
    setLoading(true);
    API({
      ...(backPage !== "resetUpin" ? changeUserPin : userPin),
      body: {
        ...(backPage !== "resetUpin" && { old_user_pin: oldUpinValue }),
        new_user_pin: newUpinValue,
        confirm_user_pin: confirmUpinValue,
      },
      onSuccess: (res) => {
        setLoading(false);
        setButtonText("Successful");

        setTimeout(() => {
          changePageFunc("home");
          dispatch(
            setBackPage({
              backPage: "",
            })
          );
        }, 2000);

        setPageData((prevPageData) => ({
          ...prevPageData,
          isSuccess: true,
        }));
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response?.data?.message || "Something went wrong!",
        });
      },
    });
  };
  const changePageFunc = (pageName) => {
    dispatch(
      changePage({
        page: pageName,
      })
    );

    navigate("/");
  };
  return (
    <>
      <Header />
      <div className="bg-worker py-4 overflow-y-scroll my-2 flex-grow-1 position-relative container-fluid">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <div
          style={{ borderBottom: "1.5px solid rgba(217, 217, 217, 1)" }}
          className="d-flex align-items-center justify-content-between pb-4"
        >
          <h2
            className="mb-0 ps-4"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
          >
            Change UPIN
          </h2>
        </div>
        <div className="row d-flex align-items-start">
          <div className="col-6">
            <div className="mt-4 text-center position-relative">
              <img
                className="position-absolute"
                style={{ left: "10%", top: "13%" }}
                src={upinIcon}
                alt="upin-icon"
              />
              {backPage !== "resetUpin" && (
                <>
                  {/* old upin  */}
                  <label
                    style={{
                      color: "rgba(105, 102, 102, 1)",
                      marginLeft: "-230px",
                      fontSize: "15px",
                    }}
                    className="d-block mb-1"
                    htmlFor="old_user_pin"
                  >
                    Old UPIN
                  </label>
                  {oldUpin.map((value, index) => (
                    <input
                      key={index}
                      ref={(el) => (oldUpinRefs.current[index] = el)}
                      id="old_user_pin"
                      name="old_user_pin"
                      type="number"
                      maxLength={1}
                      value={value}
                      onChange={(e) =>
                        handleOldUpinChange(index, e.target.value)
                      }
                      onKeyDown={(e) => handleOldUpinKeyDown(index, e)}
                      onPaste={(e) => handleOldUpinPaste(index, e)}
                      style={{
                        width: "40px",
                        height: "30px",
                        backgroundColor: "#D9D9D9",
                        marginRight: "8px",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                    />
                  ))}
                </>
              )}

              {/* new upin  */}
              <label
                style={{
                  color: "rgba(105, 102, 102, 1)",
                  marginLeft: "-220px",
                  fontSize: "15px",
                }}
                className="d-block mt-3 mb-1"
                htmlFor="new_user_pin"
              >
                New UPIN
              </label>
              {upin.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (upinRefs.current[index] = el)}
                  id="new_user_pin"
                  name="new_user_pin"
                  type="number"
                  maxLength={1}
                  value={value}
                  onChange={(e) => handleUpinChange(index, e.target.value)}
                  onKeyDown={(e) => handleUpinKeyDown(index, e)}
                  onPaste={(e) => handleUpinPaste(index, e)}
                  style={{
                    width: "40px",
                    height: "30px",
                    backgroundColor: "#D9D9D9",
                    marginRight: "8px",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                />
              ))}

              {/* confirm upin  */}
              <label
                style={{
                  color: "rgba(105, 102, 102, 1)",
                  marginLeft: "-200px",
                  fontSize: "15px",
                }}
                className="d-block mt-3 mb-1"
                htmlFor="confirm_user_pin"
              >
                Confirm UPIN
              </label>
              {confirmUpin.map((value, index) => (
                <input
                  key={index}
                  ref={(el) => (confirmUpinRefs.current[index] = el)}
                  type="number"
                  name="confirm_user_pin"
                  id="confirm_user_pin"
                  maxLength={1}
                  value={value}
                  onChange={(e) =>
                    handleConfirmUpinChange(index, e.target.value)
                  }
                  onKeyDown={(e) => handleConfirmUpinKeyDown(index, e)}
                  onPaste={(e) => handleConfirmUpinPaste(index, e)}
                  style={{
                    width: "40px",
                    height: "30px",
                    backgroundColor: "#D9D9D9",
                    marginRight: "8px",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                />
              ))}
              <div
                style={{ fontSize: "14px", color: "rgba(25, 66, 128, 1)" }}
                className="d-flex align-items-center justify-content-end col-7 mx-auto mt-2"
              >
                <p
                  onClick={() => changePageFunc("resetUpin")}
                  style={{ cursor: "pointer" }}
                  className="mb-0"
                >
                  Reset UPIN
                </p>
              </div>
            </div>
            <div className="text-center position-relative">
              <button
                className="border-0 text-white py-2 px-4 mt-3 col-8"
                style={{
                  backgroundColor: "#194280",
                  borderRadius: "10px",
                  opacity: submitDisabled || loading ? 0.5 : 1,
                  cursor: submitDisabled || loading ? "not-allowed" : "pointer",
                }}
                onClick={handleSubmit}
                disabled={submitDisabled || loading}
              >
                {loading ? "Loading..." : buttonText}
              </button>
              {pageData.isSuccess && (
                <img
                  style={{ maxWidth: "60px", right: "30%", top: "12px" }}
                  className="position-absolute"
                  src={tick}
                  alt="tick"
                />
              )}
            </div>
            <div
              className="mt-4 p-1 mx-auto"
              style={{
                backgroundColor: "rgba(217, 217, 217, 1)",
                borderRadius: "10px",
              }}
            >
              <div className="d-flex align-items-center">
                <img
                  src={upinDesc}
                  alt="description"
                  style={{ height: "25px" }}
                />
                <p
                  className="mb-0 ms-2"
                  style={{
                    fontSize: "12px",
                    color: "rgba(25, 66, 128, 1)",
                  }}
                >
                  This UPIN will be required at the time of authentication of
                  the wallet transactions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="py-2 px-3 mb-0 mt-5"
              style={{
                border: "1px solid #B0B8CF",
                borderRadius: "10px",
              }}
            >
              {pageData.pinHelpText.map((item, index) => (
                <div key={index} className="d-flex align-items-center mt-2">
                  <img
                    src={item.matched ? greenTick : liDot}
                    alt={item.matched ? "tick" : "dot"}
                    style={{ marginRight: "10px" }}
                  />
                  <span
                    style={{
                      color: "rgba(18, 33, 88, 1)",
                      fontSize: "15px",
                    }}
                  >
                    {item.text}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  backPage: state.dashboard.backPage,
});

export default connect(mapStateToProps)(ChangeUpin);

import React, { useEffect, useState } from "react";
import API from "../components/api2";
import InputForm from "../components/InputForm";
import { addService, serviceCode } from "../urls";
import Button from "../components/Button";
import { circles } from "../images";
import Header from "../components/Header";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";

const NewStandardService = () => {
  const [pageData, setPageData] = useState({ service_status: "ACTIVE" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [serviceCodeDropdown, setServiceCodeDropdown] = useState([]);
  const [loading, setLoading] = useState(false);

  function pageDataFunc(event) {
    const { id, value } = event.target;
    setPageData((prev) => ({ ...prev, [id]: value }));
  }

  // validation
  const validationRules = {
    service_code: "service code",
    service_name: "service name",
    service_descp: "service description",
  };
  const validateForm = () => {
    const newErrors = {};

    Object.entries(validationRules).forEach(([key, label]) => {
      if (!pageData[key]) {
        newErrors[key] = `${label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // on submit
  function onSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      API({
        ...addService,
        body: pageData,
        onSuccess: (res) => {
          setLoading(false);
          Swal.fire({
            title: `${res.data.message}`,
          }).then(() => {
            changePageFunc();
          });
        },

        onError: (error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: `${error.response.data.message}`,
          });
        },
      });
    }
  }

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allStandardServices",
      })
    );

    navigate("/");
  };

  useEffect(() => {
    API({
      ...serviceCode,
      onSuccess: (res) => {
        const service_code = res.data.data.map((s) => ({
          value: s.service_code,
          label: s.service_code,
        }));
        setServiceCodeDropdown(service_code);
      },
    });
  }, []);

  return (
    <>
      <Header />

      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto  py-4 me-2 position-relative overflow-y-auto">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <h2
          className="pb-3 mb-0 px-5"
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "24px",
            borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
          }}
        >
          Add Standard Service
        </h2>
        <div
          className="py-1 px-0 ms-0 ps-3"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "0px 27.5px 27.5px 0px",
            fontSize: "18px",
            fontWeight: "700",
            fontFamily: "Montserrat",
            color: "rgba(173, 131, 82, 1)",
            width: "270px",
            marginLeft: "-48px",
          }}
        >
          Standard Service Info
        </div>
        <div className="px-5 container-fluid">
          <div className="row mt-3">
            <div className="col-6">
              <label
                style={{
                  marginTop: "8px",
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "rgba(105, 102, 102, 1)",
                  fontFamily: "Montserrat",
                }}
                htmlFor="service_code"
                className="form-label"
              >
                Service Code
              </label>
              <Select
                id={"service_code"}
                name={"service_code"}
                valueState={pageData["service_code"]}
                onChange={(selectedOption) =>
                  setPageData({
                    ...pageData,
                    service_code: selectedOption.label,
                    service_code: selectedOption.value,
                  })
                }
                placeholder={"Service Code"}
                options={serviceCodeDropdown}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: errors["service_code"]
                      ? "none"
                      : "rgba(255, 231, 159, 0.4)",
                    borderRadius: "10px",
                    border: errors["service_code"]
                      ? "1px solid #DC4C64"
                      : "none",
                  }),
                }}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Service Name"}
                id={"service_name"}
                name="service_name"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Service Status"}
                id={"service_status"}
                name="service_status"
                value={pageData.service_status}
                onChange={pageDataFunc}
                disabled={true}
              />
            </div>
            <div className="col-6">
              <InputForm
                type={"text"}
                placeholder={"Service Description"}
                id={"service_descp"}
                name="service_descp"
                valueState={pageData}
                onChange={pageDataFunc}
                errorState={errors}
              />
            </div>
          </div>
        </div>
        <div className="d-flex px-5 justify-content-between align-items-center mt-5">
          <Button onClick={changePageFunc} children={"Back"} />
          <Button
            style={{
              opacity: loading ? 0.6 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewStandardService;

import React, { useEffect, useState } from "react";
import { merchBankDetails } from "../../urls";
import API from "../../components/api2";
import { changePage } from "../../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { circles } from "../../images";
import PaginationTable from "../../components/PaginationTable";
import Filter2 from "../../components/Filter2";

const AllAccounts = () => {
  const [filteredTable, setFilteredTable] = useState([]);
  const [pagination, setPagination] = useState({ offset: 0, limit: 11 });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBankList({ initial: true });
  }, []);

  const fetchBankList = ({
    filterValues = {},
    initial = false,
    append = false,
    resolve = false,
    reject = false,
  } = {}) => {
    if (!initial) {
      setLoading(true);
    }
    const filteredObject = {};

    for (const key in filterValues) {
      if (filterValues[key] !== null && filterValues[key] !== "") {
        filteredObject[key] = filterValues[key];
      }
    }
    API({
      ...merchBankDetails,
      params: initial
        ? { limit: 1 }
        : {
            ...filteredObject,
            ...pagination,
            ...(resolve
              ? {
                  include_excel: true,
                  offset: 0,
                  limit: pagination?.offset + pagination?.limit,
                }
              : {}),
          },
      onSuccess: (res) => {
        setLoading(false);
        if (initial) {
          console.log("test");
        } else {
          if (resolve) {
            resolve(res?.data?.data);
          } else {
            if (append) {
              setFilteredTable((prev) => [...prev, ...res?.data?.data]);
            } else {
              setFilteredTable(res?.data?.data);
            }
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error("Error fetching accounts: ", error);
        if (reject) {
          reject(error);
        }
      },
    });
  };

  const changePageFunc = ({ targetPage }) => {
    dispatch(
      changePage({
        page: targetPage,
      })
    );
    navigate("/", {});
  };

  useEffect(() => {
    if (pagination?.offset !== 0) {
      fetchBankList({ append: true });
    }
  }, [pagination]);

  // Define table columns
  const columns = [
    {
      Header: "Verify",
      accessor: "verify",
    },
    {
      Header: "IFSC",
      accessor: "ben_ifsc",
    },
    {
      Header: "Account Number",
      accessor: "ben_account_number",
    },
    {
      Header: "Beneficiary Name",
      accessor: "ben_name",
    },
    {
      Header: "Bank Name",
      accessor: "ben_bank_name",
    },
    {
      Header: "Bank Code",
      accessor: "ben_bank_code",
    },
    {
      Header: "Branch Code",
      accessor: "ben_bank_branch_code",
    },
    {
      Header: "Branch Issuer Code",
      accessor: "issue_brnch_code",
    },
    {
      Header: "Merchant ID",
      accessor: "merchnt_id",
    },
    {
      Header: "Address",
      accessor: "ben_address",
    },
    {
      Header: "Email",
      accessor: "ben_email",
    },
    {
      Header: "Mobile",
      accessor: "ben_mobile",
    },
    {
      Header: "Created By",
      accessor: "created_by",
    },
    {
      Header: "Created On",
      accessor: "created_on",
    },
  ];

  return (
    <>
      <Header />
      <div
        style={{ borderRadius: "10px", height: "calc(100% - 100px)" }}
        className="bg-white mt-2 pt-4 pb-2 d-flex flex-column position-relative"
      >
        <img
          className="position-absolute top-0 end-0"
          src={circles}
          alt="circle"
        />
        <div className="d-flex align-items-center justify-content-between px-3">
          <h2
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
            }}
            className="mb-0"
          >
            All Merchant Accounts
          </h2>
        </div>
        <Filter2
          getDataFunc={fetchBankList}
          loading={loading}
          include_excel="false"
          filters={[]}
        >
          <div
            style={{
              border: "1.3px solid black",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              changePageFunc({ targetPage: "addAccount" });
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "10px",
              }}
              className="mb-0 p-2"
            >
              {"ADD ACCOUNT"}
            </p>
          </div>
        </Filter2>
        <PaginationTable
          columns={columns}
          data={filteredTable}
          setPagination={setPagination}
          skipPageReset={true}
        />
      </div>
    </>
  );
};

export default AllAccounts;

import React, { useState } from "react";
import API from "../components/api2";
import { newCustomFields } from "../urls";
import Button from "../components/Button";
import Header from "../components/Header";
import { circles } from "../images";
import InputForm from "../components/InputForm";
import Swal from "sweetalert2";
import { changePage } from "../redux/slice/dashboardSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddCustomField = () => {
  const [fieldName, setFieldName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "allCustomFields",
      })
    );
    navigate("/");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    API({
      ...newCustomFields,
      body: { field_label: fieldName },
      onSuccess: (res) => {
        setIsLoading(false);
        Swal.fire({
          title: `${res.data.message}`,
        }).then(() => {
          changePageFunc();
        });
      },
      onError: (error) => {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  return (
    <>
      <Header />
      <div className="bg-worker my-2 flex-grow-1 w-100 mx-auto px-5 py-4 me-2 position-relative">
        <img
          style={{ position: "absolute", right: "0", top: "0" }}
          src={circles}
          alt="circles"
        />
        <form onSubmit={onSubmit} className="text-start">
          <h2
            className="pb-3 mb-0"
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "24px",
              borderBottom: "1.5px solid rgba(217, 217, 217, 1)",
            }}
          >
            Add Custom Field
          </h2>
          <div className="mt-4 col-6">
            <InputForm
              type={"text"}
              id={"field_label"}
              name="field_label"
              placeholder={"Field Label"}
              value={fieldName}
              onChange={(event) => setFieldName(event.target.value)}
            />
          </div>
          <div className="mt-4 d-flex justify-content-between col-6">
            <Button onClick={changePageFunc} children={"Back"} />

            <Button
              type="submit"
              disabled={!fieldName || isLoading}
              style={{
                opacity: !fieldName || isLoading ? 0.5 : 1,
                cursor: !fieldName || isLoading ? "not-allowed" : "pointer",
              }}
            >
              {isLoading ? "Adding..." : "Add"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCustomField;
